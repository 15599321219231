import "./propiedadesSimilares_style.css";

const listaPropiedades_similares = [
  {
    id: 1,
    imagenUrl:
      "https://assets.easybroker.com/property_images/3159277/50553261/EB-LC9277.jpeg?version=1659463059",
    logoAnunciante:
      "https://cdn.freelogovectors.net/wp-content/uploads/2023/04/remaxlogo-freelogovectors.net_.png",
    precio: "1,345.00",
    ubicacion: "Av. Vega del Llano 215, Avandaro, Valle de Bravo",
    medida: "250",
    habitaciones: "2",
    baños: "1",
  },
  {
    id: 2,
    imagenUrl:
      "https://assets.easybroker.com/property_images/3159277/50553261/EB-LC9277.jpeg?version=1659463059",
    logoAnunciante:
      "https://cdn.freelogovectors.net/wp-content/uploads/2023/04/remaxlogo-freelogovectors.net_.png",

    precio: "1,345.00",
    ubicacion: "Av. Vega del Llano 215, Avandaro, Valle de Bravo",
    medida: "250",
    habitaciones: "2",
    baños: "1",
  },
  {
    id: 3,
    imagenUrl:
      "https://assets.easybroker.com/property_images/3159277/50553261/EB-LC9277.jpeg?version=1659463059",
    logoAnunciante:
      "https://cdn.freelogovectors.net/wp-content/uploads/2023/04/remaxlogo-freelogovectors.net_.png",

    precio: "1,345.00",
    ubicacion: "Av. Vega del Llano 215, Avandaro, Valle de Bravo",
    medida: "250",
    habitaciones: "2",
    baños: "1",
  },
  {
    id: 4,
    imagenUrl:
      "https://assets.easybroker.com/property_images/3159277/50553261/EB-LC9277.jpeg?version=1659463059",
    logoAnunciante:
      "https://cdn.freelogovectors.net/wp-content/uploads/2023/04/remaxlogo-freelogovectors.net_.png",

    precio: "1,345.00",
    ubicacion: "Av. Vega del Llano 215, Avandaro, Valle de Bravo",
    medida: "250",
    habitaciones: "2",
    baños: "1",
  },
  {
    id: 5,
    imagenUrl:
      "https://assets.easybroker.com/property_images/3159277/50553261/EB-LC9277.jpeg?version=1659463059",
    logoAnunciante:
      "https://cdn.freelogovectors.net/wp-content/uploads/2023/04/remaxlogo-freelogovectors.net_.png",

    precio: "1,345.00",
    ubicacion: "Av. Vega del Llano 215, Avandaro, Valle de Bravo",
    medida: "250",
    habitaciones: "2",
    baños: "1",
  },
];

function ListadoPropiedades_similares() {
  return (
    <div className="contenedor_propiedadesSimilares">
      <h1>
        PROPIEDADES <a>SIMILARES</a>
      </h1>
      <div className="tarjetas_propiedadesSimilares">
        {listaPropiedades_similares.map((propiedad) => (
          <div className="propiedadSimilar">
            <img
              className="imagen_PropiedaSimilar"
              src={propiedad.imagenUrl}
            ></img>
            <div className="datos_propiedadSimilar">
              <div className="info_propiedadSimilar">
                <h3>MXN ${propiedad.precio}</h3>
                <h4>{propiedad.ubicacion}</h4>
                <a>
                  <p>{propiedad.medida}m</p>
                  <p>{propiedad.habitaciones} Habitaciones</p>
                  <p>{propiedad.baños} Baño(s)</p>
                </a>
              </div>
              <div className="contenedorLogo">
                <img
                  className="logo_propiedadSimilar"
                  src={propiedad.logoAnunciante}
                  alt=""
                ></img>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListadoPropiedades_similares;
