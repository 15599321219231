const BusinessList = ({ businesses }) => {
  return (
    <div className="business-list">
      {businesses.map((business, index) => (
        <div key={index} className="business-card">
          <img src={business.logo} alt={business.name} />
          <div className="business-info">
            <h3>{business.name}</h3>
            <h5>{business.address}</h5>
            <h4>Tel.: {business.phone}</h4>
            {business.pagina && (
              <button className="boton_verNegocio" onClick={() => window.location.href = business.pagina}>+</button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BusinessList;
