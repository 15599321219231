import "../Eventos/eventosTop_style.css";

const EventosTop = () => {
  const eventosListado = [
    {
      Titulo: "KTM Trackside Support",
      Fecha_dia: "3",
      Fecha_mes: "Ago",
      Direccion: "Valle de Bravo",
      Precio: "1250",
      detallesURL: "/eventos-proximos-cerca-de-ti/evento-000",
      Imagen: "https://scontent.fmex26-1.fna.fbcdn.net/v/t39.30808-6/451790767_511837367861185_2536881303997496929_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=75d36f&_nc_ohc=EpOOmAOxswYQ7kNvgGO_L0i&_nc_ht=scontent.fmex26-1.fna&oh=00_AYDbhs2e47J5VTyM32QWJw-Y3-65mF5AB9G0YKkZf2K3OA&oe=66ADCECA",
    },
    {
        Titulo: "Cantando a Luis Miguel",
        Fecha_dia: "31",
        Fecha_mes: "Jul",
        Direccion: "Casa Musas, 107 Felipe Villarello, Toluca",
        Precio: "109",
        detallesURL: "https://www.facebook.com/",
        Imagen: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F803204179%2F1213147661613%2F1%2Foriginal.20240707-221258?w=940&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C1200%2C600&s=459317a8ecc20d87bc7d475186b62de5", // Replace with actual image URL
      },
      {
        Titulo: "Noche Vivaldi",
        Fecha_dia: "10",
        Fecha_mes: "Ago",
        Direccion: "Casa Musas,107 Felipe Villarello, Toluca",
        Precio: "149",
        detallesURL: "https://www.facebook.com/",
        Imagen: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F809147409%2F1213147661613%2F1%2Foriginal.20240717-020201?w=940&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C1200%2C600&s=384aca50044857539885235370b44cbe",
      },
      {
        Titulo: "The Bill Cunliffe Experience",
        Fecha_dia: "27",
        Fecha_mes: "Oct",
        Direccion: "Sala Felipe Villanueva,Avenida J. M. Morelos Y P., Toluca",
        Precio: "0",
        detallesURL: "https://www.facebook.com/",
        Imagen: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F814617389%2F334083336063%2F1%2Foriginal.20240725-182627?w=940&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C6912%2C3456&s=1187448a0e1da3934d42280ba147be0c", // Replace with actual image URL
      },
      {
          Titulo: "SUSSIE 4 | Secret Garden",
          Fecha_dia: "17",
          Fecha_mes: "Ago",
          Direccion: "Av Nuevo León 107, Ciudad de México",
          Precio: "410",
          detallesURL: "https://www.facebook.com/",
          Imagen: "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F809616269%2F898702435743%2F1%2Foriginal.20240717-174705?w=940&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C3500%2C1750&s=c280f4ff6b0ee816af6060df6d5cf3cf", // Replace with actual image URL
        },
        {
          Titulo: "CONCIERTO FILARMONICO",
          Fecha_dia: "13",
          Fecha_mes: "Dic",
          Direccion: "Av. Vega del Llano 215, Avandaro,Valle de Bravo",
          Precio: "300",
          detallesURL: "https://www.facebook.com/",
          Imagen: "https://media.istockphoto.com/id/1461816749/es/foto/una-multitud-de-personas-con-los-brazos-levantados-durante-un-concierto-de-m%C3%BAsica-con-un.jpg?s=612x612&w=0&k=20&c=8zIllYwMgFarR_O1wUbTbcmqKG5bI0dMI_b1InyVKW4=", // Replace with actual image URL
        },
    
  ];

  const handleDetallesClick = (url) => {
    window.location.href = url;
  };


  return (
    <div className="contenedor_eventosTop">
      {eventosListado.map((evento) => (
        <div className="box_evento">
          <img
            className="imagen_evento"
            src={evento.Imagen} // Display the image
            alt={evento.Titulo}
          />
          <div className="informacion_evento" key={evento.Titulo}>
            <div className="fecha_evento">
              <div className="numero_fecha">{evento.Fecha_dia}</div>
              <div className="mes_fecha">{evento.Fecha_mes}</div>
            </div>
            <div className="titulosDireccion_evento">
              <div className="cont_titulo_eventoBox"><h2 className="titulo_eventoBox">{evento.Titulo}</h2></div>
              <div className="dirección_eventoBox">{evento.Direccion}</div>
            </div>
            <div className="btnyPrecio_evento">
              <div className="precio_evento">${evento.Precio} MXN</div>
              <button
                className="boton_info_eventoBox"
                onClick={() => handleDetallesClick(evento.detallesURL)}
                >
                +
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventosTop;
