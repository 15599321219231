import "./laPena_style.css";
import CompartirGuia from "../inicio_guia/compartir_guia";
import BotonesMenu_guia from "../inicio_guia/botones_guia";

function CarmelMaranatha() {
  return (
    <div className="inicio_guia guia_laPena">
      <div className="contenedor_logoGuia">
        <img
          className="logoGuia logoGuiaSeccion"
          src="../Images/logo_guiaTuristica.png"
          alt="Guia turistica logo"
        />
      </div>
      <div className="informacionPena">
        <h2 className="tituloGuiaSeccion">Casa De Oración Carmel Maranatha</h2>
        <h2 className="subtituloBienvenidosGuia">
          Un Oasis de Paz y Espiritualidad
        </h2>
        <div className="informacionPena_secc1">
          <div className="informacionPena_secc1texto">
            <p>
              Ubicada en las tranquilas colinas de Valle de Bravo, la Casa de
              Oración Carmel Maranatha es un refugio espiritual que ofrece un
              espacio para la meditación, la oración y el encuentro con uno
              mismo. Este centro religioso, gestionado por la orden de los
              Carmelitas Descalzos, es un destino ideal para quienes buscan un
              respiro del ajetreo cotidiano y desean reconectar con su fe y
              espiritualidad.
            </p>
            <h2>Historia y Significado</h2>
            <p>
              La Casa de Oración Carmel Maranatha fue fundada con la misión de
              proporcionar un entorno propicio para la oración y la reflexión.
              Inspirada en la espiritualidad carmelita, esta casa de retiro
              invita a los visitantes a sumergirse en un ambiente de serenidad y
              devoción. La arquitectura del lugar, sencilla y armoniosa,
              complementa la belleza natural de su entorno, creando una
              atmósfera de paz y recogimiento.
            </p>
          </div>

          <iframe width="680" height="326" src="https://www.youtube.com/embed/rB0kTSR7OxA" title="Casa de oración Carmel Maranatha" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div className="informacionPena_secc2">
          <img
            src="../Images/lapeña.png"
            className="galeria1_detallesTuristicos"
            alt="galeria detalles turisticos"
          />
          <div>
            <h2>Atracciones y Actividades</h2>
            <ul>
              <li>
                <p>
                  <b>Capilla de Nuestra Señora del Carmen:</b>El corazón de la
                  Casa de Oración es su hermosa capilla, un lugar sagrado donde
                  los visitantes pueden participar en misas, meditaciones y
                  momentos de silencio contemplativo.
                </p>
              </li>
              <li>
                <p>
                  <b>Jardines y Senderos:</b>Los jardines bien cuidados y los
                  senderos que serpentean por la propiedad ofrecen lugares
                  ideales para la reflexión personal, la lectura espiritual y la
                  contemplación de la naturaleza.
                </p>
              </li>
              <li>
                <p>
                  <b>Programas de Retiro:</b>La Casa de Oración ofrece diversos
                  programas de retiro que incluyen ejercicios espirituales,
                  talleres de meditación y conferencias sobre la espiritualidad
                  carmelita. Estos programas están diseñados para fortalecer la
                  vida interior y fomentar una mayor conexión con Dios.
                </p>
              </li>
            </ul>
            <h2>Alojamiento y Comodidades</h2>
            <p>
              La Casa de Oración Carmel Maranatha ofrece alojamiento sencillo y
              cómodo para quienes participan en sus programas de retiro. Las
              habitaciones están diseñadas para proporcionar un ambiente de
              tranquilidad y descanso, permitiendo a los huéspedes sumergirse
              completamente en su experiencia espiritual.
            </p>
          </div>
        </div>
        <div className="informacionPena_secc3">
          <h2>Consejos para tu Visita</h2>
          <p>
            La Casa de Oración Carmel Maranatha es un lugar de paz y
            espiritualidad que ofrece una oportunidad única para desconectar y
            reconectar con lo esencial. Planifica tu visita con antelación,
            sigue las recomendaciones del lugar y aprovecha al máximo este
            espacio de recogimiento y meditación. ¡Te esperan con los brazos
            abiertos!
          </p>
        </div>
      </div>
      <div className="contenedor_galeriaInfinita">
        <a>
          <h2>Galería</h2>
        </a>
        <div className="galeriaInfinita">
          <img
            className="img_galeriaInfinita"
            src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/11/60/c5/b5/photo3jpg.jpg?w=1200&h=1200&s=1"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2020/10/Carmel-Maranatha-Valle-de-Bravo-Estado-de-Mexico-Paisaje.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7Zar5ebJlXta_lCJHKPMs4bIqpKfKj6d1pw&s"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://i.pinimg.com/originals/b2/26/cd/b226cd0c2933b96b005e7f49d2e1227f.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://lifeontheroam.com/wp-content/uploads/2023/05/Carmel-Maranatha-Prayer-Centrel-Valle-de-Bravo-Mexico-3-min.webp"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.elsoldetoluca.com.mx/incoming/2wgkx7-valle-de-bravo-maranata.jpg/ALTERNATES/LANDSCAPE_1140/valle%20de%20bravo%20maranata.JPG"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://mexicoxdescubrir.com/wp-content/uploads/2023/03/Casa-de-Oracion-Valle-de-Bravo-estado-Mexico-1024x576.jpg.webp"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://lh5.googleusercontent.com/-o50MD5VVCy4/TXn1HkuBJjI/AAAAAAAA7oo/dHoRmovqdt0/s1600/4065839762_7f87293cd3_b.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/MARANATHA_4.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://i.ytimg.com/vi/qnkczG8CA4Q/maxresdefault.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="indicaciones_turistas">
        <div className="flexbox_Necesarios">
          <div>
            <h3>PRENDAS Y EQUIPO NECESARIO</h3>
            <ul>
              <li>Ropa Cómoda</li>
              <li>Calzado Adecuado</li>
              <li>Suetér o chaqueta ligera</li>
              <li>Botella de Agua</li>
              <li>Artículos de higiene personal</li>
            </ul>
          </div>
          <div>
            <h3>PRECAUCIONES</h3>
            <ul>
              <li>Silencio y respeto</li>
              <li>Seguimiento de las instrucciones</li>
              <li>Cuidado personal</li>
              <li>Protección solar</li>
              <li>Reserva previa</li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <BotonesMenu_guia />
      <div className="mapa_turista">
        <h2 className="comollegar">¿Cómo llegar?</h2>
        <p>Ve la dirección en google maps</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.297442510548!2d-100.12849232479088!3d19.225864482009847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd647eca36d51d%3A0xd2bd58881cb94b94!2sCARMEL%20MARANATH%C3%81!5e0!3m2!1ses!2smx!4v1722899959067!5m2!1ses!2smx"
          width="850"
          height="250"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa de Valle de Bravo"
          className="mapaEvento"
        ></iframe>
      </div>
      <div className="espacioPublicitario1_guia">
        <h2>Espacio publicitario</h2>
      </div>
      <img
        className="logo_guiaTur_inferior"
        src="../Images/logo_guiaTuristica.png"
        alt="Logo inferior"
      />
      <img
        className="imagenInferior"
        src="../Images/FOTO_INFERIORCASCADA.png"
        alt="Imagen inferior"
      />
    </div>
  );
}

export default CarmelMaranatha;
