import '../RevistaDigital/loMas_visto_style.css';

const MasVisto = () => {

    return (
      <div className="contenedor_masVisto">     
          <div className="articulo_masVisto"> 
            <img src="https://bcw-media.s3.ap-northeast-1.amazonaws.com/text_to_image_topbanner_mb_1_f66b5f345b.jpg" alt="Titulo 1" />
            <div class="overlay"></div>
            <div className="titulo_mv">TITULO</div>
          </div>
          <div className="articulo_masVisto">
            <img src="https://bcw-media.s3.ap-northeast-1.amazonaws.com/text_to_image_topbanner_mb_1_f66b5f345b.jpg" alt="Titulo 2" />
            <div class="overlay"></div>
            <div className="titulo_mv">TITULO</div>
          </div>
          <div className="articulo_masVisto">
            <img src="https://bcw-media.s3.ap-northeast-1.amazonaws.com/text_to_image_topbanner_mb_1_f66b5f345b.jpg" alt="Titulo 3" />
            <div class="overlay"></div>
            <div className="titulo_mv">TITULO</div>
          </div>
          <div className="articulo_masVisto">
            <img src="https://bcw-media.s3.ap-northeast-1.amazonaws.com/text_to_image_topbanner_mb_1_f66b5f345b.jpg" alt="Titulo 4" />
            <div class="overlay"></div>
            <div className="titulo_mv">TITULO</div>
          </div>
          <div className="articulo_masVisto">
            <img src="https://bcw-media.s3.ap-northeast-1.amazonaws.com/text_to_image_topbanner_mb_1_f66b5f345b.jpg" alt="Titulo 5" />
            <div class="overlay"></div>
            <div className="titulo_mv">TITULO</div>
          </div>
          <div className="articulo_masVisto">
            <img src="https://bcw-media.s3.ap-northeast-1.amazonaws.com/text_to_image_topbanner_mb_1_f66b5f345b.jpg" alt="Titulo 6" />
            <div class="overlay"></div>
            <div className="titulo_mv">TITULO</div>
          </div>
          <div className="articulo_masVisto espacioPubliciario2_revista">
            ESPACIO PUBLICITARIO 2
          </div>
      </div>
    );
  };
  
  export default MasVisto;