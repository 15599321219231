import "../Articulos/articulo_revista.css";
import CompartirArticulo from "./componentes_articulos/boton_compartirArticulo";
import ArticulosRelacionados from "./componentes_articulos/articulos_similares";

function Articulo006() {
  return (
    <div className="contenedor_Articulo">
      <div className="encabezadoArticulo">
        <a>
          <h1 className="titulo_Articulo">
            Los mejores lugares para descansar cerca de CDMX
          </h1>
          <CompartirArticulo />
        </a>
        <hr />
      </div>
      <div className="portada_articulo">
        <div className="contenedor_imgPortada">
          <img
            className="portadaImg"
            src="https://mediaim.expedia.com/destination/2/44e4acd68f2e8173aae76ec32ced9642.jpg"
            alt="Ciclistas"
          ></img>
        </div>
        <div className="categoria_portadaArticulo">LIFESTYLE</div>
        <div className="fecha_portadaArticulo">Agosto 2024</div>
      </div>
      <div className="contenedor_contenidoArticulo">
        <div className="seccion1_articulo">
          <a className="parrafo_secc1">
            <p>
              <b>V</b>alle de Bravo es una población encantadora con casas
              coloniales de estuco blanco, balcones de hierro forjado y techos
              de teja roja. Situado en la montaña y bordeando un hermoso lago,
              este pueblo está rodeado de una exuberante vegetación de pinos.
              <br />
              Valle de Bravo, a solo 60 minutos de Toluca y dos horas de la
              Ciudad de México en auto, se ha convertido en un destino de recreo
              de primer orden gracias a sus numerosos atractivos y sitios de
              interés en los alrededores.
            </p>
            <p>
              Originalmente llamado San Francisco del Valle de Temascaltepec,
              este asentamiento agrícola se transformó en 1946 con la creación
              del proyecto hidroeléctrico Miguel Alemán, que inundó las tierras
              del valle y dio lugar al lago actual. En este lago, se pueden
              practicar diversos deportes acuáticos como ski, veleo, canotaje,
              remo, motonáutica y pesca deportiva. Además, se llevan a cabo
              competencias de regatas y lanchas rápidas, y también es posible
              navegar tranquilamente en lanchas de motor y colectivas.
            </p>
            <p>
              El pueblo ofrece una variedad de atractivos: calles empinadas,
              portales en la plaza con puestos de antojitos, un quiosco de
              madera y teja, un mercado de artesanías y la parroquia de San
              Francisco, con un campanario del siglo XVI.
            </p>
          </a>
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/85361162.jpg?k=db03163efefad8952bfe4585ed7a9d4689562e5a42a2dd73ae30ddac2042fb09&o=&hp=1"
              alt="ciclista sonriendo"
            ></img>
          </div>
        </div>
        <div className="seccion2_articulo">
          <h2>Avándaro</h2>
          <p>
            Es una localidad vecina incorporada a Valle, cuenta con
            fraccionamientos, un hotel de gran categoría, restaurantes, un club
            hípico y un club de golf. Aquí se pueden practicar actividades como
            montañismo, escalada en roca, ciclismo, motociclismo de campo, vuelo
            libre y vuelo ligero. Avándaro también es conocido por haber sido
            escenario de un importante evento de rock en 1971.
          </p>
          <p>
            Valle de Bravo y Avándaro no solo son destinos vacacionales, sino
            que también han sido elegidos por personas de clase media alta y
            alta para tener su casa de descanso. Desarrollos como El Santuario,
            Izar, Llano Largo y Rancho ofrecen productos residenciales y lotes
            para construir, rodeados de paisajes paradisíacos.
          </p>
          <div className="Add1_articulo">
            <h2>ESPACIO PUBLICITARIO</h2>
          </div>
          <h2>Clubes de primera</h2>
          <h3>El Santuario</h3>
          <p>
            Un desarrollo náutico-residencial que ofrece armonía con la
            naturaleza y facilidades para disfrutar de deportes acuáticos.
            Ubicado a solo siete kilometros del pueblo, es ideal para disfrutar
            de Valle de Bravo en cualquier momento.
          </p>
          <h3>Club Náutico Valle de Bravo</h3>
          <p>
            Otro club importante en la región es el Club Náutico Valle de Bravo,
            que en una extensión de 17,000 m2 cuenta con una Casa Club y
            jardines, área de usos múltiples, alberca, bar, restaurante en la
            terraza con vista al lago, canchas de tenis y tiendas donde venden
            artículos náuticos. El club tiene cinco plataformas para botes de
            diversos tipos y un tractor de alto poder para el arrastre de las
            lanchas. Para el acceso al lago el Club cuenta con dos rampas y tres
            embarcaderos para lanchas de hasta 6 metros por 2.5 metros de largo
            y ancho, respectivamente.
          </p>
        </div>
        <div className="secc3_articulo">
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://www.1golf.eu/images/golfclubs/campestre-izar_056881_1280-1280-70-0-0-0.jpg?v=10c2665b"
              alt="ciclista sonriendo"
            ></img>
          </div>
          <a className="parrafo_secc3">
            <h3>Ízar</h3>
            <p>
              Este desarrollo de 550 hectáreas ofrece un campo de golf de 18
              hoyos, un club de vela, un club hípico y lotes residenciales,
              extendiéndose desde Velo de Novia hasta las orillas del lago.
            </p>
            <h3>Turismo</h3>
            <p>
              Entre los puntos de interés más conocidos de Valle de Bravo se
              encuentran la presa de Valle de Bravo, el lago, la Laguna Negra,
              el Embarcadero Municipal, la Cascada Velo de Novia, el Mirador de
              Cruz de Misión, La Peña y muchos más.
            </p>
            <h3>Ecoturismo y aventura</h3>
            <p>
              Para los amantes de la aventura, se puede realizar una caminata
              hasta la cima de La Peña, desde donde se contempla una vista
              espectacular del lago. También es posible practicar vuelo en
              parapente y aviones ultraligeros, disfrutando de los
              impresionantes paisajes desde las alturas. Para los escaladores,
              el Peñón del Diablo ofrece un reto con sus escarpadas murallas de
              más de 600 metros.
            </p>
            <p>
              Valle de Bravo es un destino que combina naturaleza, aventura y
              cultura, ofreciendo una experiencia única para sus visitantes.
            </p>
          </a>
        </div>
      </div>
      <ArticulosRelacionados />
      <div className="Add3_articulo">
        <h2>ESPACIO PUBLICITARIO 3</h2>
      </div>
    </div>
  );
}
export default Articulo006;
