import './politicasdePrivacidad_style.css'

function PoliticasdePrivacidad (){
    return(
        <body className="pagina_politicasPrivacidad">
<div class="texto_PoliticasPrivacidad">
    <h1>Políticas de Privacidad</h1>

    <p><strong>Última actualización:</strong> Sábado 10 de Agosto del 2024</p>

    <h2>Introducción</h2>
    <p>En <strong>Vida & Encanto</strong>, respetamos tu privacidad y nos comprometemos a proteger la información personal que compartes con nosotros. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos tu información personal cuando visitas nuestro sitio web y utilizas nuestros servicios.</p>

    <h2>1. Información que Recopilamos</h2>

    <h3>1.1. Información que nos Proporcionas Directamente</h3>
    <ul>
        <li><strong>Datos de Contacto:</strong> Cuando te registras en nuestro boletín, realizas una compra, o te comunicas con nosotros, podemos solicitar tu nombre, dirección de correo electrónico, número de teléfono, y otra información de contacto.</li>
        <li><strong>Contenido del Usuario:</strong> Cualquier comentario, opinión, u otra información que nos envíes a través de nuestro sitio web.</li>
    </ul>

    <h3>1.2. Información que Recopilamos Automáticamente</h3>
    <ul>
        <li><strong>Datos de Navegación:</strong> Recopilamos información sobre tu dispositivo, navegador, dirección IP, y las páginas que visitas en nuestro sitio web.</li>
        <li><strong>Cookies:</strong> Utilizamos cookies y tecnologías similares para mejorar tu experiencia en nuestro sitio web. Puedes controlar el uso de cookies a través de la configuración de tu navegador.</li>
    </ul>

    <h2>2. Cómo Utilizamos Tu Información</h2>
    <p>Utilizamos la información que recopilamos para los siguientes fines:</p>
    <ul>
        <li><strong>Proveer y Mejorar nuestros Servicios:</strong> Para personalizar tu experiencia, mejorar la funcionalidad de nuestro sitio web, y desarrollar nuevos productos y servicios.</li>
        <li><strong>Comunicación:</strong> Para enviarte actualizaciones, boletines informativos, y promociones relacionadas con nuestros productos y servicios. Puedes optar por no recibir estas comunicaciones en cualquier momento.</li>
        <li><strong>Seguridad y Cumplimiento Legal:</strong> Para proteger nuestros derechos, prevenir el fraude, y cumplir con las obligaciones legales.</li>
    </ul>

    <h2>3. Compartición de Información</h2>
    <p>No compartimos tu información personal con terceros, excepto en las siguientes circunstancias:</p>
    <ul>
        <li><strong>Proveedores de Servicios:</strong> Podemos compartir tu información con proveedores de servicios que nos ayudan a operar nuestro sitio web, como servicios de alojamiento, análisis de datos, y procesamiento de pagos.</li>
        <li><strong>Cumplimiento Legal:</strong> Podemos divulgar tu información cuando sea necesario para cumplir con una obligación legal o para proteger nuestros derechos y seguridad.</li>
    </ul>

    <h2>4. Seguridad de los Datos</h2>
    <p>Nos comprometemos a proteger tu información personal mediante la implementación de medidas de seguridad técnicas y organizativas adecuadas. Sin embargo, ningún método de transmisión por Internet o almacenamiento electrónico es completamente seguro, por lo que no podemos garantizar la seguridad absoluta.</p>

    <h2>5. Tus Derechos</h2>
    <p>Tienes derecho a:</p>
    <ul>
        <li><strong>Acceder a tu Información:</strong> Solicitar una copia de la información personal que tenemos sobre ti.</li>
        <li><strong>Rectificación:</strong> Corregir cualquier error en tu información personal.</li>
        <li><strong>Eliminación:</strong> Solicitar que eliminemos tu información personal, sujeto a ciertas excepciones.</li>
        <li><strong>Oposición:</strong> Oponerte al procesamiento de tu información personal para ciertos fines.</li>
    </ul>

    <h2>6. Enlaces a Terceros</h2>
    <p>Nuestro sitio web puede contener enlaces a sitios web de terceros. No somos responsables de las prácticas de privacidad de estos sitios web. Te recomendamos revisar las políticas de privacidad de cada sitio web que visitas.</p>

    <h2>7. Cambios a esta Política</h2>
    <p>Podemos actualizar esta Política de Privacidad de vez en cuando. Cualquier cambio será publicado en esta página con la fecha de la última actualización. Te recomendamos revisar esta Política periódicamente para estar informado sobre cómo protegemos tu información.</p>

    <h2>8. Contacto</h2>
    <p>Si tienes alguna pregunta o inquietud sobre esta Política de Privacidad o sobre cómo manejamos tu información personal, puedes contactarnos en:</p>
    <ul>
        <li><strong>Correo electrónico:</strong> <a href="mailto:contacto@revistavidayencanto.com">contacto@revistavidayencanto.com</a></li>
        <li><strong>Teléfono:</strong> +52 722 250 9849</li>
        <li><strong>Dirección:</strong> Privada del Lago, Valle de Bravo, Edo. de Méx.</li>
    </ul>
</div>
        </body>
    )
}
export default PoliticasdePrivacidad