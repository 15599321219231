import "./laPena_style.css";
import CompartirGuia from "../inicio_guia/compartir_guia";
import BotonesMenu_guia from "../inicio_guia/botones_guia";

function SantaMaria() {
  return (
    <div className="inicio_guia guia_laPena">
      <div className="contenedor_logoGuia">
        <img
          className="logoGuia logoGuiaSeccion"
          src="../Images/logo_guiaTuristica.png"
          alt="Guia turistica logo"
        />
      </div>
      <div className="informacionPena">
        <h2 className="tituloGuiaSeccion">Santa María</h2>
        <h2 className="subtituloBienvenidosGuia">
          El Embarcadero Municipal de Valle de Bravo
        </h2>
        <div className="informacionPena_secc1">
          <div className="informacionPena_secc1texto">
            <p>
              Santa María, conocida también como la zona del Embarcadero
              Municipal de Valle de Bravo, es un punto central de actividad y un
              destino popular para quienes desean explorar el pintoresco Lago
              Avándaro. Este encantador área ofrece una combinación perfecta de
              belleza natural, actividades recreativas y una vibrante vida
              comunitaria. Es el lugar ideal para disfrutar de un día en el agua
              o simplemente relajarse junto a las orillas del lago.
            </p>
            <h2>Historia y Significado</h2>
            <p>
              El Embarcadero Municipal de Santa María ha sido, durante décadas,
              un punto de partida para aventuras acuáticas y un centro de
              encuentro para locales y turistas. Este embarcadero no solo
              facilita el acceso al Lago Avándaro, sino que también ha jugado un
              papel crucial en el desarrollo turístico de Valle de Bravo,
              convirtiéndose en un símbolo de la hospitalidad y la belleza de la
              región.
            </p>
          </div>

          <iframe
            width="680"
            height="326"
            src="https://www.youtube.com/embed/V1qQnUSiUR8"
            title="conociendo  el embarcadero de valle de bravo 👍👍👍😱😱😱"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="informacionPena_secc2">
          <img
            src="../Images/lapeña.png"
            className="galeria1_detallesTuristicos"
            alt="galeria detalles turisticos"
          />
          <div>
            <h2>Atracciones y Actividades</h2>
            <ul>
              <li>
                <p>
                  <b>Paseos en Lancha:</b>Desde el embarcadero, puedes
                  embarcarte en un paseo en lancha por el Lago Avándaro. Estas
                  excursiones ofrecen vistas panorámicas del paisaje
                  circundante, incluyendo montañas, bosques y la encantadora
                  ciudad de Valle de Bravo.
                </p>
              </li>
              <li>
                <p>
                  <b>Deportes Acuáticos:</b>Santa María es el lugar perfecto
                  para los entusiastas de los deportes acuáticos. Aquí puedes
                  alquilar equipos para vela, esquí acuático, paddleboard y
                  kayak. Es una excelente manera de disfrutar del lago y
                  experimentar la adrenalina de estas actividades.
                </p>
              </li>
              <li>
                <p>
                  <b>Mercado y Tiendas Locales:</b>Cerca del embarcadero,
                  encontrarás mercados y tiendas que venden artesanías,
                  recuerdos y productos locales. Es un buen lugar para comprar
                  recuerdos y disfrutar de la gastronomía regional.
                </p>
              </li>
            </ul>
            <h2>¿Cómo Llegar?</h2>
            <p>
              Santa María se encuentra en la zona del Embarcadero Municipal de
              Valle de Bravo, accesible fácilmente desde el centro de la ciudad.
              Si vienes desde la Ciudad de México, el viaje en automóvil dura
              aproximadamente 2 horas. También puedes llegar en autobús o taxi
              desde el centro de Valle de Bravo.
            </p>
          </div>
        </div>
        <div className="informacionPena_secc3">
          <h2>Consejos para tu Visita</h2>
          <p>
            Santa María es un destino vibrante y pintoresco que ofrece una
            amplia gama de actividades y experiencias para todos los visitantes.
            Planifica tu visita con antelación, lleva todo lo necesario para tu
            comodidad y seguridad, y prepárate para disfrutar de un día
            inolvidable en el Lago Avándaro. ¡Te esperamos para que descubras la
            belleza y la emoción de Santa María y su Embarcadero Municipal!
          </p>
        </div>
      </div>
      <div className="contenedor_galeriaInfinita">
        <a>
          <h2>Galería</h2>
        </a>
        <div className="galeriaInfinita">
          <img
            className="img_galeriaInfinita"
            src="https://live.staticflickr.com/7671/28483736015_094a8df488_b.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://popurrideviajes.mx/wp-content/uploads/2019/07/valle-de-bravo-5.png"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://pbs.twimg.com/media/DOSDnVUVwAENcwU.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2020/10/MG_3282-1-1-700x467.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.eternal-expat.com/wp-content/uploads/2019/10/IMG_8326.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://img.travesiasdigital.com/2022/04/00-header-valle-de-bravo.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.travelreport.mx/wp-content/uploads/2018/11/que-hacer-en-valle-de-bravo.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.victoriaide.com/wp-content/uploads/1-Valle-de-Bravo-aereo.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://elsouvenir.com/wp-content/uploads/2014/08/Valle-de-Bravo.Foto_.Pueblo-M%C3%A1gico-Valle-de-Bravo.11.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2020/10/valle-de-bravo-edomex-haz-una-caminata.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="indicaciones_turistas">
        <div className="flexbox_Necesarios">
          <div>
            <h3>PRENDAS Y EQUIPO NECESARIO</h3>
            <ul>
              <li>Ropa Cómoda</li>
              <li>Calzado Adecuado</li>
              <li>Gorra o Sombrero</li>
              <li>Protector solar y gafas de sol</li>
              <li>Traje de baño y toalla</li>
            </ul>
          </div>
          <div>
            <h3>PRECAUCIONES</h3>
            <ul>
              <li>Seguridad en el agua</li>
              <li>Verifica el clima antes</li>
              <li>Mantente hidratado</li>
              <li>Cuidado de pertenencias</li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <BotonesMenu_guia />
      <div className="mapa_turista">
        <h2 className="comollegar">¿Cómo llegar?</h2>
        <p>Ve la dirección en google maps</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.157761669751!2d-100.13107272479184!3d19.188310582039563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd647ef569624f%3A0x42c6aa99c9a40f2c!2sSta.%20Mar%C3%ADa%2C%20Sta%20Maria%20Ahuacatlan%2C%2051200%20Valle%20de%20Bravo%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1722909420412!5m2!1ses!2smx"
          width="850"
          height="250"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa de Valle de Bravo"
          className="mapaEvento"
        ></iframe>
      </div>
      <div className="espacioPublicitario1_guia">
        <h2>Espacio publicitario</h2>
      </div>
      <img
        className="logo_guiaTur_inferior"
        src="../Images/logo_guiaTuristica.png"
        alt="Logo inferior"
      />
      <img
        className="imagenInferior"
        src="../Images/FOTO_INFERIORCASCADA.png"
        alt="Imagen inferior"
      />
    </div>
  );
}

export default SantaMaria;
