import "../Inicio/novedades_style.css";
import * as React from "react";
import Button from "@mui/material/Button";

function Novedades() {
  return (
    <div className="contenedor_novedades">
      <div className="articuloprinc_contenedor">
        <div className="contenedorTitulo">
          <h3 className="Titulo">La historia del parapente en México</h3>
          <p className="desc_breve">Un viaje de altura </p>
        </div>
        <Button
          variant="contained"
          className="boton_redondo"
          style={{
            backgroundColor: "#17B8FF",
            color: "white",
            color: "white",
            borderRadius: "100px",
            width: "45px",
            height: "45px",
            minWidth: "0px",
          }}
          href="/revista-digital-julio2024/articulo001-Historia-del-parapente_en-Valle-de-Bravo"
        >
          +
        </Button>
      </div>
      <div className="articulosec_contenedor">
        <div className="articulo_sec1">
          <Button
            variant="contained"
            className="boton_redondo2"
            style={{
              backgroundColor: "#17B8FF",
              color: "white",
              color: "white",
              borderRadius: "100px",
              width: "30px",
              height: "30px",
              minWidth: "0px",
            }}
            href="/revista-digital-julio2024/articulo003-joya-escondida-en-el-estado-de-mexico"
          >
            +
          </Button>
          <div className="contenedorTitulo2">
            <h3 className="Titulo2">Joya Escondida</h3>
            <p className="desc_breve2">en el estado de México</p>
          </div>
        </div>
        <div className="articulo_sec2">
          <Button
            variant="contained"
            className="boton_redondo2"
            style={{
              backgroundColor: "#17B8FF",
              color: "white",
              borderRadius: "100px",
              width: "30px",
              height: "30px",
              minWidth: "0px",
            }}
            href="/revista-digital-julio2024/articulo007-historia-de-valle_de_bravo-sigloXX-sigloXXI"
          >
            +
          </Button>
          <div className="contenedorTitulo2">
            <h3 className="Titulo2">Siglo XIX y XX</h3>
            <p className="desc_breve2">historia de Valle de Bravo</p>
          </div>
        </div>
        <div className="articulo_sec3">
          <Button
            variant="contained"
            className="boton_redondo2"
            style={{
              backgroundColor: "#17B8FF",
              color: "white",
              borderRadius: "100px",
              width: "30px",
              height: "30px",
              minWidth: "0px",
            }}
            href="/revista-digital-julio2024/articulo006-los-mejores-lugares-para-descansar-cerca-de-cdmx"
          >
            +
          </Button>
          <div className="contenedorTitulo2">
            <h3 className="Titulo2">Lugares para descansar</h3>
            <p className="desc_breve2">Conoce los mejores</p>
          </div>
        </div>
        <div className="articulo_sec4">
          <Button
            variant="contained"
            className="boton_redondo2"
            style={{
              backgroundColor: "#17B8FF",
              color: "white",
              borderRadius: "100px",
              width: "30px",
              height: "30px",
              minWidth: "0px",
            }}
            href="/revista-digital-julio2024/articulo010-celebracion-dia-de-abogado"
          >
            +
          </Button>
          <div className="contenedorTitulo2">
            <h3 className="Titulo2">Día del Abogado</h3>
            <p className="desc_breve2">Un Homenaje a su dedicación</p>
          </div>
        </div>
        <div className="articulo_sec5">
          <Button
            variant="contained"
            className="boton_redondo2"
            style={{
              backgroundColor: "#17B8FF",
              color: "white",
              borderRadius: "100px",
              width: "30px",
              height: "30px",
              minWidth: "0px",
            }}
            href="/revista-digital-julio2024/articulo005-anuies-nuevo-hotel-valle-de-bravo"
          >
+
          </Button>
          <div className="contenedorTitulo2">
            <h3 className="Titulo2">Descubre ANUIES</h3>
            <p className="desc_breve2">Tu nuevo refugio</p>
          </div>
        </div>
        <div className="articulo_sec6">
          <Button
            variant="contained"
            className="boton_redondo2"
            style={{
              backgroundColor: "#17B8FF",
              color: "white",
              borderRadius: "100px",
              width: "30px",
              height: "30px",
              minWidth: "0px",
            }}
            href="/revista-digital-julio2024/articulo002-que-comprar-en-Valle-deBravo"
          >
            +
          </Button>
          <div className="contenedorTitulo2">
            <h3 className="Titulo2">Comercio y actividades</h3>
            <p className="desc_breve2">en Valle de Bravo</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Novedades;
