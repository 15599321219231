import "./pieDePAgina_style.css";
import { Link } from "react-router-dom";

function PiedePagina() {
  return (
    <footer className="piedePagina">
      <section className="contenedorElementos_footer">
        <div className="informacionAdicional">
          <img
            className="logoFooter"
            src="/Images/LOGO_REVISTA_NEGATIVO.png"
            alt="Vida & Encanto"
          ></img>
          <div className="contenedor_paginasExtra">
          <Link to="">ACERCA DE NOSOTROS</Link>
          <Link to="/politicas_de-privacidad">POLITICA DE PRIVACIDAD</Link>
          </div>
          
        </div>
        <div className="contenedorFormulario">
          <h2>Mantente al día</h2>
          <p>
            Recibe nuestras actualizaciones y ofertas de primera mano en tu
            correo electrónico.
          </p>
          <form className="formularioFooter">
            <div className="customTextField">
              <input
                type="text"
                id="nombre"
                name="nombre"
                placeholder="Nombre"
              />
            </div>
            <div className="customTextField">
              <input
                type="text"
                id="contacto"
                name="contacto"
                placeholder="Teléfono / Email"
              />
            </div>
            <button className="boton_formularioFooter">Enviar</button>
          </form>
        </div>
        <div className="redesSociales">
          <h3>Redes Sociales</h3>
          <a
            href="https://www.facebook.com/profile.php?id=61561956214049"
            target="blank"
          >
            <img
              className="facebook icono_rs"
              src="../Images/iconos/icons8-facebook-color-96.png"
              alt="facebook"
            />
          </a>
          <a
            href="https://www.instagram.com/revista_vidayencanto/"
            target="blank"
          >
            <img
              className="instagram icono_rs"
              src="../Images/iconos/icons8-instagram-color-96.png"
              alt="instagram"
            />
          </a>
          <a href="" target="blank">
            <img
              className="tiktok icono_rs"
              src="../Images/iconos/icons8-tik-tok-96.png"
              alt="tiktok"
            />
          </a>
        </div>
      </section>
      <div className="creditos">
        <p>2024 diseñada y desarrollada por Salvador Reyes</p>
      </div>
    </footer>
  );
}

export default PiedePagina;
