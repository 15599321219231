import React from "react";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";


const CompartirArticulo = () => {
  const shareUrl = window.location.href;
  const shareText = "¡Echa un vistazo a esta página!";

  const handleShare = (platform) => {
    let url = "";
    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`;
        break;
    }
    window.open(url, "_blank");
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<FacebookIcon />}
        onClick={() => handleShare("facebook")}
        style={{
            background: "none", // Replace with your preferred color
            color: "black", // Adjust text color if needed
          }}
      >
        Compartir en Facebook
      </Button>
    </div>
  );
};

export default CompartirArticulo;
