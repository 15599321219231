import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import "../Encabezado/encabezado_style.css";

function Encabezado() {
  const [scrolled, setScrolled] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuVisible(!menuVisible);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.turismo_desplegable')) {
      setMenuVisible(false);
    }
  };

  const handleNavToggle = () => {
    setNavOpen(!navOpen);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  return (
    <nav className={`Encabezado ${scrolled ? 'scroll' : ''}`}>
      <div className="seccion1_encabezado">
        <div className={`logo ${scrolled ? 'scroll' : ''}`}>
          <a href="/">
            <img
              className="logo_principal"
              src="/Images/LOGO_REVISTA.png"
              alt="Revista Estilo & Letras Valle de Bravo"
            />
          </a>
        </div>
        <button className="hamburger_menu" onClick={handleNavToggle}>
        <span class="material-symbols-outlined">
menu
</span>
        </button>
      </div>
      
      <hr className="linea_header" />
      
      <div className={`seccion2_encabezado ${navOpen ? 'open' : ''}`}>
        <a href="/" className={`link_principal ${location.pathname === '/' ? 'active' : ''}`}>INICIO</a>
        <a href="/revista-digital-julio2024" className={`link_principal ${location.pathname === '/revista-digital-julio2024' ? 'active' : ''}`}>REVISTA</a>
        <a href="/catalogo-de-propiedades_renta" className={`link_principal ${location.pathname === '/catalogo-de-propiedades_renta' ? 'active' : ''}`}>REAL ESTATE</a>
        <a href="/directorio-valle-de-bravo" className={`link_principal ${location.pathname === '/directorio-valle-de-bravo' ? 'active' : ''}`}>DIRECTORIO</a>
        <a href="/eventos-proximos-cerca-de-ti" className={`link_principal ${location.pathname === '/eventos-proximos-cerca-de-ti' ? 'active' : ''} OcultoTemporalmente`}>EVENTOS</a>
        <div className="turismo_desplegable">
          <button className="turismo_button" onClick={handleMenuToggle}>
            TURISMO
          </button>
          {menuVisible && (
            <div className="turismo_opciones">
              <a href="/guia_turistica_ValleDeBravo" className={`link_opcion ${location.pathname === '/guia_turistica_ValleDeBravo' ? 'active' : ''}`}>Guía turística</a>
              <a href="/restaurantes-recomendados" className={`link_opcion ${location.pathname === '/restaurantes-recomendados' ? 'active' : ''}`}>Restaurantes</a>
              <a href="/hospedaje-en-valle-de-bravo" className={`link_opcion ${location.pathname === '/hospedaje-en-valle-de-bravo' ? 'active' : ''}`}>Hospedaje</a>
            </div>
          )}
        </div>
      </div>

    </nav>
  );
}

export default Encabezado;
