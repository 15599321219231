import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import './eventos_style.css';

import EventosTop from "../Componentes/Eventos/eventos_top";
import EventosProximos from "../Componentes/Eventos/eventos_proximos";
import FormularioEventos from "../Componentes/Eventos/formulario_eventos";

function PaginaEventos(){
    return(
<div className="pagina_eventos">
<div className="encabezado_realEstate">
        <div className="tituloBusqueda_realEstate titulo_eventos">
          <h1 className="titulo_realEstate">EVENTOS</h1>
          <div className="contenedor_busquedaRE">
            <Autocomplete
              freeSolo
              id="buscar_realEstate"
              disableClearable
              options={busquedasSugeridas.map((opcion) => opcion.termino)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="OcultoTemporalmente"
                  label="Buscar"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <InputAdornment position="start">
                        <span class="material-symbols-outlined">search</span>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "gray", 
                      },
                      "&:hover fieldset": {
                        borderColor: "#2AA2FF", 
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#2AA2FF", 
                      },
                    },
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <hr className="contexto_eventos"/>
      <p className="contexto_eventos">Los mejores eventos de Valle de Bravo y sus alrededores</p>
      <h2 className="subtitulo_eventos">TOP EVENTOS</h2>
      <EventosTop/>
      <h2 className="subtitulo_eventosProximos">EVENTOS<a>PRÓXIMOS</a></h2>
      <EventosProximos/>
      <FormularioEventos/>
</div>
    );

    
}

const busquedasSugeridas = [{ termino: "Departamento en renta" }];
export default PaginaEventos;
