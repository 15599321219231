import "../Pages/realEstate_style.css";
import FiltrosPropiedades from "../Componentes/RealEstate/filtros_propiedades";
import ListadoPropiedades from "../Componentes/RealEstate/listado_propiedades";
function Catalogo_Hospedaje() {
  return (
    <div className="catalogo_propiedades">
      <div className="encabezado_realEstate">
        <div className="tituloBusqueda_realEstate">
          <div className="encabezado_hospedaje">
            <img className="logoguia_hospedaje" src="../Images/logo_guiaTuristica.png"></img>
          <h1 className="titulo_realEstate">Hospedaje</h1>

          </div>
        </div>
      </div>
      <hr />
      <p>Encuentra las mejores opciones de hospedaje para tu astancia en Valle de Bravo</p>
      <ListadoPropiedades/>
    </div>
  );
}

const busquedasSugeridas = [{ termino: "Departamento en renta" }];

export default Catalogo_Hospedaje;
