import "../Pages/inicio_style.css";
import { Link } from "react-router-dom";
import Novedades from "../Componentes/Inicio/novedades";
import Button from "@mui/material/Button";
import Diapos_principales from "../Componentes/Inicio/diapos_spots";
import Diapos_realEstate from "../Componentes/Inicio/muestrario_propiedad";
import Banner_membresia from "../Componentes/Inicio/banner_card";
import Banner_guiaturistica from "../Componentes/Inicio/banner_guiaturistica";
import Proximos_eventos from "../Componentes/Inicio/proximos_eventos";

function Inicio() {
  return (
    <div className="contenedorInicio">
      <div className="contenedor_fotoprincipal">
        <img
          className="foto_principalInicio"
          src="https://pueblosmagicos.mexicodesconocido.com.mx/wp-content/uploads/2023/09/Valle-de-Bravo-parapente-foto-Go-Desing.jpg"
        ></img>
      </div>
      <h1 className="titulo_novedades">NOVEDADES</h1>
      <Novedades />
  
<Button
        variant="contained"
        className="boton_verRevista"
        href="/revista-digital-julio2024"
        style={{
          backgroundColor: "#EF8822",
          color: "white",
          borderRadius: "8px",
          width: "250px",
          height: "40px",
          minWidth: "150px",
          marginBottom: "25px",
        }}
      >
        <img
          src="/Images/iconos/menu_book_24dp_FILL0_wght300_GRAD0_opsz24 (1).svg"
          className="icono_revista"
          alt="icono"
        ></img>
        Ver revista dígital
      </Button>
      
      <h1 className="titulo_Spots">SITIOS ICONICOS DE VALLE DE BRAVO</h1>
      <h2 className="subtitulo_Spots">Y sus alrededores</h2>
      <Diapos_principales />
      <Link
        to="/guia_turistica_ValleDeBravo"
        style={{ textDecoration: "none" }}
      >
        <Button
          variant="contained"
          className="boton_verGuia"
          style={{
            backgroundColor: "#EF8822",
            color: "white",
            borderRadius: "8px",
            width: "250px",
            height: "40px",
            minWidth: "150px",
            marginBottom: "25px",
          }}
        >
          <img
            src="/Images/iconos/map_24dp_FILL0_wght400_GRAD0_opsz24.svg"
            className="icono_revista"
            alt="icono"
          ></img>
          Ver guia turística
        </Button>
      </Link>

      <h1 className="titulo_novedades OcultoTemporalmente">HOSPEDAJE</h1>
      <div className="contenedor_diap_propiedades OcultoTemporalmente">
        <Diapos_realEstate />
        <Diapos_realEstate />
        <Diapos_realEstate />
        <Diapos_realEstate />
        <Diapos_realEstate />
      </div>
      <Banner_guiaturistica />
      <div className="OcultoTemporalmente">
      <h2 className="antetitulo_eventos">PRÓXIMOS</h2>
      <h1 className="titulo_novedades eventos_titulo">
        EVENTOS CERCA DE VALLE
      </h1>
      <Proximos_eventos/>
      <Link to="/ruta-deseada" style={{ textDecoration: "none" }} className="con_btn_verEventos">
        <Button
          variant="contained"
          className="boton_verEventos"
          style={{
            backgroundColor: "#EF8822",
            color: "white",
            borderRadius: "8px",
            width: "250px",
            height: "40px",
            minWidth: "150px",
            marginBottom: "25px",
            marginLeft: "42%",
          }}
        >
          <span class="material-symbols-outlined">celebration</span>
          Ver todos
        </Button>
      </Link>
      </div>
    
    </div>
  );
}

export default Inicio;
