import "./noticias_nuevas_style.css";
import { useNavigate } from "react-router-dom";

const NuevasNoticias = () => {
  const navigate = useNavigate();

  const handleDivClick = (url) => {
    navigate(url);
  };

  const noticias_secundarias = [
    {
      titulo: "Celebración del Día del Abogado en Valle de Bravo",
      fecha: "13 de Julio",
      categoria: "SOCIALES",
      imagen:
        "https://uneg.edu.mx/wp-content/uploads/2023/08/P9-min.jpg",
      ruta: "/revista-digital-julio2024/articulo010-celebracion-dia-de-abogado",  
    },
    {
      titulo: "Prisca Awiti: Orgullo Mexicano y Ganadora de Plata",
      fecha: "30 de Julio",
      categoria: "DEPORTES",
      imagen:
        "https://www.jornada.com.mx/ndjsimg/images/jornada/jornadaimg/prisca-awiti-pasa-a-la-final-de-judo-en-paris-2024-y-asegura-medalla-para-mexico-738/prisca-awiti-pasa-a-la-final-de-judo-en-paris-2024-y-asegura-medalla-para-mexico-738html-judojpg-3355html-511bd3e9-d03f-4e8b-8d49-5d02acfa597c.jpgljnimgndimage=fullsize",
      ruta: "/revista-digital-julio2024/articulo011-priscila-awiti-ganadora-de-plata"
    },
  ];

  return (
    <div className="nuevas_noticias">
      <div
        className="articulo_principal"
        onClick={() =>
          handleDivClick("/revista-digital-julio2024/articulo009-mexiquenses-en-olimpiadas-paris-2024")
        }
      >
        <img
          src="https://www.eleconomista.com.mx/__export/1720276200377/sites/eleconomista/img/2024/07/05/paris_2024_juegos_olimpicos_afp-min.png_554688468.png"
          alt="Toma de Protesta 2024"
        />
        <div className="contenido_noticiaPrincipal">
          <div className="categoria_noticiaPrincipal">DEPORTES</div>
          <div className="fecha_articuloPrincipal">13 Septiembre</div>
          <h2 className="titulo_articuloPrincipal">Mexiquenses en las PARIS 2024</h2>
          <p className="texto_articuloPrincipal">
            Los Juegos Olímpicos de París 2024 están a la vuelta de la esquina, y la emoción en el Estado de México es palpable. Los atletas mexiquenses se están preparando para dejar una huella imborrable en este prestigioso evento deportivo.
          </p>
        </div>
      </div>
      <div className="articulos_laterales">
        {noticias_secundarias.map((article, index) => (
          <div
            key={index}
            className="articuloLateral"
            onClick={() => handleDivClick(article.ruta)}
          >
            <img src={article.imagen} alt={article.titulo} />
            <div className="articuloLateral_contenido">
              <div className="categoria_articuloLateral">{article.categoria}</div>
              <div className="date_articuloLateral">{article.fecha}</div>
              <h3 className="titulo_articuloLateral">{article.titulo}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NuevasNoticias;

