import "../Articulos/articulo_revista.css";
import CompartirArticulo from "./componentes_articulos/boton_compartirArticulo";
import ArticulosRelacionados from "./componentes_articulos/articulos_similares";

function Articulo011() {
  return (
    <div className="contenedor_Articulo">
      <div className="encabezadoArticulo">
        <a>
          <h1 className="titulo_Articulo">
            Prisca Awiti: Plata en Judo en los Juegos Olímpicos
          </h1>
          <CompartirArticulo />
        </a>
        <hr />
      </div>
      <div className="portada_articulo">
        <div className="contenedor_imgPortada">
          <img
            className="portadaImg"
            src="https://www.jornada.com.mx/ndjsimg/images/jornada/jornadaimg/prisca-awiti-pasa-a-la-final-de-judo-en-paris-2024-y-asegura-medalla-para-mexico-738/prisca-awiti-pasa-a-la-final-de-judo-en-paris-2024-y-asegura-medalla-para-mexico-738html-judojpg-3355html-511bd3e9-d03f-4e8b-8d49-5d02acfa597c.jpgljnimgndimage=fullsize"
            alt="Ciclistas"
          ></img>
        </div>
        <div className="categoria_portadaArticulo">DEPORTES</div>
        <div className="fecha_portadaArticulo">Julio 2024</div>
      </div>
      <div className="contenedor_contenidoArticulo">
        <div className="seccion1_articulo">
          <a className="parrafo_secc1">
            <p>
              <b>E</b>n una muestra de determinación, talento y esfuerzo, Prisca
              Awiti ha puesto en alto el nombre de México al ganar la medalla de
              plata en judo en los Juegos Olímpicos. Esta hazaña histórica no
              solo es un motivo de orgullo nacional, sino también una
              inspiración para futuras generaciones de deportistas mexicanos.
            </p>
            <h2>Un Viaje de Dedicación y Esfuerzo</h2>
            <p>
              Prisca Awiti, nacida el 20 de febrero de 1996, es una judoca
              mexicana que ha demostrado una impresionante trayectoria en su
              deporte. Desde temprana edad, Prisca mostró una pasión innata por
              las artes marciales, comenzando su entrenamiento en judo a los 8
              años. Su dedicación y talento rápidamente la distinguieron en el
              ámbito nacional e internacional.
            </p>
            <p>
              Originaria de Croydon, Reino Unido, con raíces mexicanas por parte
              de su madre, Prisca ha representado a México con gran orgullo y
              determinación. A lo largo de los años, ha participado en múltiples
              competencias internacionales, acumulando una serie de logros que
              la llevaron a clasificarse para los Juegos Olímpicos.
            </p>
          </a>
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://res.cloudinary.com/duu3v9gfg/image/fetch/t_fit_1920/https://78884ca60822a34fb0e6-082b8fd5551e97bc65e327988b444396.ssl.cf3.rackcdn.com/up/2019/09/AT2J1376-1569352143-1569352144.jpg"
              alt="ciclista sonriendo"
            ></img>
          </div>
        </div>
        <div className="seccion2_articulo">
          <h2>El Camino a la Medalla de Plata</h2>
          <p>
            Llegar a los Juegos Olímpicos no fue tarea fácil. Prisca tuvo que
            superar numerosos desafíos y adversidades, tanto dentro como fuera
            del tatami. Su participación en torneos internacionales y su
            constante entrenamiento bajo la guía de entrenadores experimentados
            fueron clave para su preparación.
          </p>
          <p>
            En los Juegos Olímpicos, Prisca compitió en la categoría de -63 kg,
            enfrentándose a algunas de las mejores judocas del mundo. Cada
            combate fue una prueba de su habilidad, fuerza y resistencia. Con
            una combinación de técnica impecable y un espíritu inquebrantable,
            Prisca avanzó ronda tras ronda, impresionando tanto a los jueces
            como al público.
          </p>
          <div className="Add1_articulo">
            <h2>ESPACIO PUBLICITARIO</h2>
          </div>
          <h2>La Final y la Medalla de Plata</h2>
          <p>
            La final fue un enfrentamiento épico que mantuvo a todos al borde de
            sus asientos. Prisca se enfrentó a una oponente formidable,
            demostrando una vez más su habilidad y estrategia en el tatami.
            Aunque el oro estuvo cerca, Prisca se llevó la medalla de plata, un
            logro monumental que celebró con lágrimas de alegría y orgullo.
          </p>
          <p>
            "Esta medalla representa años de trabajo duro, sacrificio y el apoyo
            incondicional de mi familia y mi equipo", expresó Prisca después de
            la ceremonia de premiación. "Estoy orgullosa de representar a México
            y espero inspirar a otros a perseguir sus sueños, sin importar cuán
            grandes sean los desafíos".
          </p>
        </div>
        <div className="secc3_articulo">
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://images.reporteindigo.com/wp-content/uploads/2024/07/una-plata-epica-asi-fue-la-gesta-de-prisca-awiti-en-paris-.jpg"
              alt="ciclista sonriendo"
            ></img>
          </div>
          <a className="parrafo_secc3">
            <h2>Inspiración y Legado</h2>
            <p>
              El éxito de Prisca Awiti en los Juegos Olímpicos no solo es un
              hito personal, sino también una fuente de inspiración para muchos
              jóvenes atletas en México y alrededor del mundo. Su historia
              demuestra que con determinación, esfuerzo y pasión, es posible
              alcanzar la grandeza.
            </p>
            <p>
              Prisca planea continuar su carrera en judo, con la mirada puesta
              en futuras competiciones y en la promoción del deporte en México.
              Además, está comprometida con el desarrollo de programas de
              entrenamiento para jóvenes judocas, con el objetivo de fomentar el
              talento y la pasión por el judo en las nuevas generaciones.
            </p>
            <h2>Conclusión</h2>
            <p>
              Prisca Awiti, con su medalla de plata en los Juegos Olímpicos, ha
              escrito un nuevo capítulo en la historia del deporte mexicano. Su
              viaje, lleno de desafíos y triunfos, es un recordatorio de que los
              sueños se pueden alcanzar con esfuerzo y dedicación. México
              celebra su logro y espera con ansias ver qué más logrará esta
              increíble judoca en el futuro.
            </p>
          </a>
        </div>
      </div>
      <ArticulosRelacionados />
      <div className="Add3_articulo">
        <h2>ESPACIO PUBLICITARIO 3</h2>
      </div>
    </div>
  );
}
export default Articulo011;
