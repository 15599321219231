import "./laPena_style.css";
import CompartirGuia from "../inicio_guia/compartir_guia";
import BotonesMenu_guia from "../inicio_guia/botones_guia";

function ParroquiaSanFrancisco() {
  return (
    <div className="inicio_guia guia_laPena">
      <div className="contenedor_logoGuia">
        <img
          className="logoGuia logoGuiaSeccion"
          src="../Images/logo_guiaTuristica.png"
          alt="Guia turistica logo"
        />
      </div>
      <div className="informacionPena">
        <h2 className="tituloGuiaSeccion">
          Parroquia de San Francisco de Asís
        </h2>
        <h2 className="subtituloBienvenidosGuia">
          Un Icono Histórico y Espiritual en Valle de Bravo
        </h2>
        <div className="informacionPena_secc1">
          <div className="informacionPena_secc1texto">
            <p>
              Situada en el pintoresco Valle de Bravo, Estado de México, la
              Parroquia de San Francisco de Asís es una joya arquitectónica y un
              centro de fe que ha sido testigo de la historia y el desarrollo de
              la comunidad. Con su impresionante fachada y su rica herencia,
              esta parroquia se erige como un símbolo de la devoción y la
              espiritualidad que caracteriza a la región.
            </p>
            <h2>Ubicación y Acceso</h2>
            <p>
              Construida en el siglo XVII, la Parroquia de San Francisco de Asís
              es uno de los edificios más antiguos y emblemáticos de Valle de
              Bravo. Dedicada a San Francisco de Asís, el santo patrono de los
              animales y el medio ambiente, esta iglesia ha sido un lugar de
              culto y peregrinación durante siglos. Su arquitectura colonial,
              con elementos barrocos y detalles ornamentales, refleja la rica
              historia y la profunda fe de la comunidad local.
            </p>
          </div>

          <iframe width="680" height="326" src="https://www.youtube.com/embed/d240mRBOHu4" title="Parroquia San Francisco de Asís, Valle de Bravo | www.edemx.com" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div className="informacionPena_secc2">
          <img
            src="../Images/lapeña.png"
            className="galeria1_detallesTuristicos"
            alt="galeria detalles turisticos"
          />
          <div>
            <h2>Atracciones y Actividades</h2>
            <ul>
              <li><p><b>Arquitectura y Arte Sacro:</b>La parroquia es un ejemplo magnífico de la arquitectura colonial mexicana. Sus altas torres, hermosos vitrales y altares ricamente decorados son un deleite para los amantes del arte y la historia. No te pierdas la oportunidad de admirar las tallas de madera y las pinturas que adornan el interior de la iglesia.</p></li>
              <li><p><b>Misas y Celebraciones Religiosas:</b>La Parroquia de San Francisco de Asís ofrece misas diarias y ceremonias especiales en días festivos. Participar en una de estas celebraciones es una experiencia espiritual enriquecedora, que permite a los visitantes conectarse con la comunidad local y su fe.</p></li>
              <li><p><b>Eventos Comunitarios:</b>La parroquia es un centro de actividad comunitaria, con eventos que incluyen procesiones, festivales y actividades benéficas. Estos eventos son una excelente manera de experimentar la cultura local y la hospitalidad de los residentes de Valle de Bravo.</p></li>
            </ul>
            <h2>Cómo Llegar</h2>
            <p>
            La Parroquia de San Francisco de Asís se encuentra en el centro histórico de Valle de Bravo, fácilmente accesible a pie desde muchos puntos de interés locales. Si vienes desde la Ciudad de México, el viaje en automóvil dura aproximadamente 2 horas. También puedes llegar en autobús o taxi.
            </p>
          </div>
        </div>
        <div className="informacionPena_secc3">
          <h2>Consejos para tu Visita</h2>
          <p>
          La Parroquia de San Francisco de Asís es un destino imprescindible en Valle de Bravo, que ofrece una mezcla única de historia, cultura y espiritualidad. Planifica tu visita con antelación, lleva todo lo necesario para tu comodidad y seguridad, y prepárate para sumergirte en la rica herencia y la belleza de este lugar sagrado. ¡Te esperamos para que descubras la majestuosidad y la devoción de la Parroquia de San Francisco de Asís!
            </p>
        </div>
      </div>
      <div className="contenedor_galeriaInfinita">
        <a>
          <h2>Galería</h2>
        </a>
        <div className="galeriaInfinita">
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/parroquia%20de%20san%20francisco%20de%20asis%20%281%29.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://scontent.fmex5-1.fna.fbcdn.net/v/t1.6435-9/93481584_2643877245830850_1547134588243935232_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=7b2446&_nc_ohc=ARP-egztw7kQ7kNvgGEwFbX&_nc_ht=scontent.fmex5-1.fna&oh=00_AYBmGSl8zSDbHgKpqPgfRfnjdvMGvTwbBINFuoyjP6NnLg&oe=66D8F4D5"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.mexicodesconocido.com.mx/assets/images/destinos/valle_de_bravo/actividades/valle-de-bravo-edomex-parroquia-de-san-francisco-de-asis.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://scontent.fmex27-1.fna.fbcdn.net/v/t1.6435-9/106361068_3042423575794643_2421754916768439852_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=7b2446&_nc_ohc=JcRmSQR3C4YQ7kNvgFYUUo0&_nc_ht=scontent.fmex27-1.fna&oh=00_AYCFM3O867filLMeVcN1_y3bgiAJFuFvs6zWA4aUdByAiw&oe=66D90323"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2020/10/arton24521.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/12/fd/09/7b/merece-toda-le-pena-y.jpg?w=1200&h=1200&s=1"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.viajabonito.mx/wp-content/uploads/2021/09/que-hacer-en-valle-de-bravo-cp.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://s3-us-west-1.amazonaws.com/tipsparatuviaje/wp-content/uploads/2017/01/5.-%C2%BFCon-qu%C3%A9-cuenta-el-centro-hist%C3%B3rico.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.elsoldetoluca.com.mx/incoming/2rtyz6-san-pancho-de-asis-valle-de-bravo-20191004130207_img_7854-2.jpg/ALTERNATES/LANDSCAPE_768/San%20pancho%20de%20asis%20valle%20de%20bravo%2020191004130207_IMG_7854%20(2).JPG"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://parroquiavalledebravo.wordpress.com/wp-content/uploads/2015/11/12242760_737931402979985_1569887579_o.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="indicaciones_turistas">
        <div className="flexbox_Necesarios">
          <div>
            <h3>PRENDAS Y EQUIPO NECESARIO</h3>
            <ul>
              <li>Ropa Cómoda</li>
              <li>Calzado Adecuado</li>
              <li>Gorra o Sombrero</li>
              <li>Protector Solar</li>
              <li>Botella de Agua</li>
              <li>Mochila Ligera</li>
              <li>Cámara Fotográfica</li>
              <li>Snacks</li>
            </ul>
          </div>
          <div>
            <h3>PRECAUCIONES</h3>
            <ul>
              <li>Lleva Suficiente Agua</li>
              <li>Calzado Adecuado</li>
              <li>Ten Precaución en las Rocas</li>
              <li>Lleva una Linterna</li>
              <li>Respeta la Naturaleza</li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <BotonesMenu_guia />
      <div className="mapa_turista">
        <h2 className="comollegar">¿Cómo llegar?</h2>
        <p>Ve la dirección en google maps</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.024906359043!2d-100.13431712479166!3d19.194114482034955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd638731220963%3A0x363b4eeb14fbc814!2sParroquia%20de%20San%20Francisco%20de%20As%C3%ADs!5e0!3m2!1ses!2smx!4v1722907710005!5m2!1ses!2smx"
          width="850"
          height="250"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa de Valle de Bravo"
          className="mapaEvento"
        ></iframe>
      </div>
      <div className="espacioPublicitario1_guia">
        <h2>Espacio publicitario</h2>
      </div>
      <img
        className="logo_guiaTur_inferior"
        src="../Images/logo_guiaTuristica.png"
        alt="Logo inferior"
      />
      <img
        className="imagenInferior"
        src="../Images/FOTO_INFERIORCASCADA.png"
        alt="Imagen inferior"
      />
    </div>
  );
}

export default ParroquiaSanFrancisco;
