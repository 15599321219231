import "./laPena_style.css";
import CompartirGuia from "../inicio_guia/compartir_guia";
import BotonesMenu_guia from "../inicio_guia/botones_guia";

function CascadaelMolino() {
  return (
    <div className="inicio_guia guia_laPena">
      <div className="contenedor_logoGuia">
        <img
          className="logoGuia logoGuiaSeccion"
          src="../Images/logo_guiaTuristica.png"
          alt="Guia turistica logo"
        />
      </div>
      <div className="informacionPena">
        <h2 className="tituloGuiaSeccion">La Peña</h2>
        <h2 className="subtituloBienvenidosGuia">
          Mirador La Peña: Vistas Panorámicas de Valle de Bravo
        </h2>
        <div className="informacionPena_secc1">
          <div className="informacionPena_secc1texto">
            <p>
              El Mirador La Peña es uno de los puntos más impresionantes y
              visitados de Valle de Bravo, Estado de México. Ofreciendo vistas
              panorámicas inigualables del lago y del pueblo, este mirador se ha
              convertido en un destino obligado para los amantes de la
              naturaleza, la fotografía y los paisajes espectaculares.
            </p>
            <h2>Ubicación y Acceso</h2>
            <p>
              Situado a solo unos minutos del centro de Valle de Bravo, el
              Mirador La Peña es de fácil acceso tanto a pie como en coche. El
              camino hasta la cima es una aventura en sí misma, con senderos
              rodeados de vegetación exuberante que te preparan para las vistas
              deslumbrantes que te esperan.
            </p>
          </div>

          <iframe
            width="680"
            height="326"
            src="https://www.youtube.com/embed/5hTYY5cnTKc"
            title="La peña - Valle de bravo - mirador"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="informacionPena_secc2">
          <img
            src="../Images/lapeña.png"
            className="galeria1_detallesTuristicos"
            alt="galeria detalles turisticos"
          />
          <div>
            <h2>Historia del Mirador La Peña</h2>
            <p>
              El Mirador La Peña no solo es famoso por sus vistas, sino también
              por su significado histórico y cultural. Esta formación rocosa ha
              sido un lugar de importancia espiritual para las comunidades
              locales durante siglos. Hoy en día, sigue siendo un lugar de
              encuentro y de conexión con la naturaleza para visitantes de todas
              partes del mundo.
            </p>
            <h2>¿Qué Esperar en tu Visita?</h2>
            <p>
              Al llegar a la cima del Mirador La Peña, serás recibido por una
              vista panorámica que abarca todo el lago de Valle de Bravo, las
              montañas circundantes y el pintoresco pueblo. Es el lugar perfecto
              para relajarse, meditar o simplemente disfrutar de la
              majestuosidad del entorno.
            </p>
          </div>
        </div>
        <div className="informacionPena_secc3">
          <h2>Actividades Recomendadas</h2>
          <ul>
            <li><b>Fotografía:</b> Captura imágenes impresionantes del paisaje y del atardecer.</li>
            <li><b>Senderismo:</b> Disfruta de una caminata energizante mientras subes por el sendero hasta el mirador.</li>
            <li><b>Picnics:</b> Lleva una manta y un almuerzo ligero para disfrutar de un picnic con vistas incomparables.</li>
          </ul>
        </div>
      </div>
      <div className="contenedor_galeriaInfinita">
        <a>
          <h2>Galería</h2>
        </a>
        <div className="galeriaInfinita">
          <img
            className="img_galeriaInfinita"
            src="https://cdn0.bodas.com.mx/article-vendor-o/4516/3_2/960/jpg/dsc-6334_5_144516.jpeg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.escapadah.com/u/fotografias/m/2022/5/17/f768x1-4811_4938_142.jpeg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://i.pinimg.com/originals/79/76/b9/7976b94c04bad27ed13c4eb2e6ce2d34.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-06/LA%20PE%C3%91A_1.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/La%20pe%C3%B1a%201.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://realestatemarket.com.mx/images/2020/07-Julio/3007/valle_bravo_jorge_gamboa_7.jpeg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://assets.easybroker.com/property_images/3967659/65380253/EB-OF7659.jpeg?version=1692924304"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://i.pinimg.com/736x/ef/1d/a5/ef1da573687c48da1175a01a5c46d630.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://lifeontheroam.com/wp-content/uploads/2023/05/Mirador-La-Pena-Valle-de-Bravo-Mexico-3-min.webp"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://cdn1.yumping.com.mx/emp/fotos/4/8/4/0/1/tm_p-48401-img-20160228-170219855_14894263673538.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="indicaciones_turistas">
        <div className="flexbox_Necesarios">
          <div>
            <h3>PRENDAS Y EQUIPO NECESARIO</h3>
            <ul>
              <li>Ropa Cómoda</li>
              <li>Calzado Adecuado</li>
              <li>Gorra o Sombrero</li>
              <li>Protector Solar</li>
              <li>Botella de Agua</li>
              <li>Mochila Ligera</li>
              <li>Cámara Fotográfica</li>
              <li>Snacks</li>
            </ul>
          </div>
          <div>
            <h3>PRECAUCIONES</h3>
            <ul>
              <li>Lleva Suficiente Agua</li>
              <li>Calzado Adecuado</li>
              <li>Ten Precaución en las Rocas</li>
              <li>Lleva una Linterna</li>
              <li>Respeta la Naturaleza</li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <BotonesMenu_guia />
      <div className="mapa_turista">
        <h2 className="comollegar">¿Cómo llegar?</h2>
        <p>Ve la dirección en google maps</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15071.362025124825!2d-100.1535371488407!3d19.202167362454194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd638de969e05d%3A0x22cbabb23abeed81!2zTGEgUGXDsWEsIFZhbGxlIGRlIEJyYXZvLCBNw6l4Lg!5e0!3m2!1ses!2smx!4v1722784013338!5m2!1ses!2smx"
          width="850"
          height="250"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa de Valle de Bravo"
          className="mapaEvento"
        ></iframe>
      </div>
      <div className="espacioPublicitario1_guia">
        <h2>Espacio publicitario</h2>
      </div>
      <img
        className="logo_guiaTur_inferior"
        src="../Images/logo_guiaTuristica.png"
        alt="Logo inferior"
      />
      <img
        className="imagenInferior"
        src="../Images/FOTO_INFERIORCASCADA.png"
        alt="Imagen inferior"
      />
    </div>
  );
}

export default CascadaelMolino;
