import '../componentes_articulos/articulosSimilares_style.css';

const lista_Articulos = [
  {
    id: 1,
    imagen: 'https://cc-prod.scene7.com/is/image/CCProdAuthor/fine-art-photography_P1_900x420?$pjpeg$&jpegSize=200&wid=900',
    titulo: 'Articulo 1',
    categoria: 'Tecnologia',
    fecha: '2024-07-03',
    descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam at congue. '
  },
  {
    id: 2,
    imagen: 'https://cc-prod.scene7.com/is/image/CCProdAuthor/fine-art-photography_P1_900x420?$pjpeg$&jpegSize=200&wid=900',
    titulo: 'Articulo 2',
    categoria: 'Moda',
    fecha: '2024-07-02',
    descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam at congue. '
  },
  {
    id: 3,
    imagen: 'https://cc-prod.scene7.com/is/image/CCProdAuthor/fine-art-photography_P1_900x420?$pjpeg$&jpegSize=200&wid=900',
    titulo: 'Articulo 3',
    categoria: 'Deportes',
    fecha: '2024-07-01',
    descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam at congue. '
  },
  {
    id: 4,
    imagen: 'https://cc-prod.scene7.com/is/image/CCProdAuthor/fine-art-photography_P1_900x420?$pjpeg$&jpegSize=200&wid=900',
    titulo: 'Articulo 2',
    categoria: 'Moda',
    fecha: '2024-07-02',
    descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam at congue. '
  },
  {
    id: 5,
    imagen: 'https://cc-prod.scene7.com/is/image/CCProdAuthor/fine-art-photography_P1_900x420?$pjpeg$&jpegSize=200&wid=900',
    titulo: 'Articulo 3',
    categoria: 'Deportes',
    fecha: '2024-07-01',
    descripcion: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam at congue. '
  }
];

function ListadoArticulos() {
    return (
        <div className="contenedor_articulosRelacionados">
            <h1>ARTICUOS <a>RELACIONADOS</a></h1>
            <div className="tarjetas_articulosRelacionados">
                {lista_Articulos.map((articulo) => (
                    <div key={articulo.id} className="tarjeta_articuloRelacionado">
                        <img className="imagen_artRelacionado" src={articulo.imagen} alt={articulo.titulo} />
                        <div className="clasyFech_arcticuloRelacionado">
                            <div className="categoria_articuloRelacionado">{articulo.categoria}</div>
                            <p className="fecha_articuloRelacionado">{articulo.fecha}</p>
                        </div>
                        <h3 className="titulo_articuloRelacionado">{articulo.titulo}</h3>
                        <p className="descripcion_articuloRelacionado">{articulo.descripcion}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ListadoArticulos;


