import "../Pages/revistaDigital_style.css";
import Articulos_recientes from "../Componentes/RevistaDigital/articulos_recientes";
import MasVisto from "../Componentes/RevistaDigital/loMas_visto";
import NuevasNoticias from "../Componentes/RevistaDigital/noticias_nuevas";
import Descargar_revista from "../Componentes/RevistaDigital/descargar_revista";

function Revista_digital() {
  return (
    <div className="contenedorPrincipal_revistaDigital">
     <h1>REVISTA DIGITAL</h1>
     <hr></hr>
     <Articulos_recientes/>
     <div className="espacioPublicitario_paginaPrincipal1">
     <b>ESPACIO PÚBLICITARIO 1</b>
     </div>
     
     <div className="noticias_delMes">
     <h1>NOTICIAS DEL MES</h1>
     <hr></hr>
     <NuevasNoticias/>
     </div>
     <div className="espacioPublicitario_paginaPrincipal1">
     <b>ESPACIO PÚBLICITARIO 3</b>
     </div>
     <h2 className="titulo_lomasvisto">Lo más visto</h2>
     <hr></hr>
     <MasVisto/>
    </div>
  );
}

export default Revista_digital;
