import "../Eventos/formularioEventos_style.css";
import TextField from "@mui/material/TextField";

function FormularioEventos() {
  return (
    <div className="ContenedorFormulario_eventos">
      <div className="contenedor_img1formEv">
        <img
          className="img1formEv"
          src="../Images/bailarina.png"
          alt="Bailarina de ballet"
        ></img>
      </div>
      <div className="formularioEventos">
        <h2 className="titulo_formularioEventos">
          ENTERATE ANTES QUE NADIE DE LOS PRÓXIMOS EVENTOS
        </h2>
        <p className="parrafo_formularioEventos">
          Registrate y te haremos llegar una alerta cuando haya un nuevo evento
          en Valle de Bravo o cerca.
        </p>
        <form className="inputs_formularioEventos">
          <TextField
            id="outlined-basic"
            label="Correo Electrónico*"
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#EF8822",
                  borderWidth: "2px", // Color del borde
                },
                "&:hover fieldset": {
                  borderColor: "#EF8822", // Color del borde al pasar el mouse
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#EF8822", // Color del borde cuando está enfocado
                },
              },
              "& .MuiInputLabel-root": {
                color: "#EF8822", // Color del texto del label
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#EF8822", // Color del texto del label cuando está enfocado
              },
            }}
          />

          <TextField
            id="outlined-basic"
            label="Nombre*"
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#EF8822",
                  borderWidth: "2px", // Color del borde
                },
                "&:hover fieldset": {
                  borderColor: "#EF8822", // Color del borde al pasar el mouse
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#EF8822", // Color del borde cuando está enfocado
                },
              },
              "& .MuiInputLabel-root": {
                color: "#EF8822", // Color del texto del label
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#EF8822", // Color del texto del label cuando está enfocado
              },
            }}
          />
          <button className="botonEnviar_eventos">ENVIAR</button>
        </form>
      </div>
      <div className="contenedor_img2formEv">
        <img
          className="img2formEv"
          src="../Images/singer-red.png"
          alt="Cantante profesional"
        ></img>
      </div>
    </div>
  );
}

export default FormularioEventos;
