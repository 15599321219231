import './muest_propiedad_style.css';

function Diapositiva_RealEstate_Chica() {
  return (
    <div className="diapo_RE">
      <img src="https://images.adsttc.com/media/images/5d34/e507/284d/d109/5600/0240/newsletter/_FI.jpg?1563747560" className="foto_diapo_re" alt=""></img>
      <div className="titulo_dre">
        <h2>NOMBRE DEL HOTEL</h2>
        <h3>PRECIO POR NOCHE: $1,345</h3>
      </div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros
        mattis, eleifend enim vitae, malesuada nibh.{" "}
      </p>
      <div className="con_botones_dre">
        <button className='vermas_hospedaje'><b>+</b></button>
        <button className='btn_reservar_inicio'><b>RESERVAR</b></button>
      </div>
    </div>
  );
}

export default Diapositiva_RealEstate_Chica;
