import "./inicio_guia/sitiosImportantes_style.css";

function sitiosImportantes() {
  return (
    <div className="galeriaI_sitiosImportantes">
      <div className="division_sitiosImportantes">
        <a href="/guia_turistica_ValleDeBravo/la-pena">
          <img className="imgSimportantes1 imagen_SitiosImp" src="https://media.istockphoto.com/id/182670557/es/foto/valle-de-bravo.jpg?s=612x612&w=0&k=20&c=SK5cn6L_-QdDTUCSvx_AUElAizk8BffDs3Gg-LCXSkg=" alt=""></img>
          <button className="boton_sitiosImportantes">La Peña</button>
        </a>
        <a href="/guia_turistica_ValleDeBravo/cascada-velo-de-novia">
          <img className="imgSimportantes2 imagen_SitiosImp" src="https://i.pinimg.com/originals/d9/8c/eb/d98ceb2f2f52c26ce9d26db72abdb296.jpg" alt=""></img>
          <button className="boton_sitiosImportantes">Velo de novia</button>
        </a>
      </div>
      <div className="division_sitiosImportantes">
        <a href="/guia_turistica_ValleDeBravo/carmel-maranatha">
          <img className="imgSimportantes3 imagen_SitiosImp" src="https://www.mexicoenfotos.com/MX12322358656649.jpg" alt=""></img>
          <button className="boton_sitiosImportantes">Carmel Maranatha</button>
        </a>
        <a href="/guia_turistica_ValleDeBravo/sta-maria-ahuacatlan">
          <img className="imgSimportantes4 imagen_SitiosImp" src="https://scontent.fmex31-1.fna.fbcdn.net/v/t39.30808-6/452932227_122110069406398540_6854610351886720874_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=127cfc&_nc_ohc=FjhS45IeAe8Q7kNvgHow3ST&_nc_ht=scontent.fmex31-1.fna&oh=00_AYCnMdH_G_1LbUOyIq4outAH-H2Gn5aCzwxEsNLdXGLARg&oe=66ADB273" alt=""></img>
          <button className="boton_sitiosImportantes">Santa María</button>
        </a>
      </div>
      <div className="division_sitiosImportantes">
        <a href="/guia_turistica_ValleDeBravo/gran-stupa-bon">
          <img className="imgSimportantes5 imagen_SitiosImp" src="https://milyunamillas.com.mx/wp-content/uploads/2021/05/Stupa-5D.jpg" alt=""></img>
          <button className="boton_sitiosImportantes">Gran Stupa Bön</button>
        </a>
        <a href="/guia_turistica_ValleDeBravo/parroquia-sn-francisco">
          <img className="imgSimportantes6 imagen_SitiosImp" src="https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2020/10/arton24521.jpg" alt=""></img>
          <button className="boton_sitiosImportantes">Parroquia de San Francisco</button>
        </a>
      </div>
    </div>
  );
}
export default sitiosImportantes;
