import "./contenedorPropiedades.css";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";


const ContenedorPropiedades = ({ listings }) => {
  return (
    <div className="contenedor_propiedades">
      {listings.map((listing) => (
        <RealEstateListing key={listing.id} listing={listing} />
      ))}
    </div>
  );
};

const RealEstateListing = ({ listing }) => {
  return (
    <div className="propiedad_menu">
      <img src={listing.imageUrl} alt={listing.title} />
      <div className="informacion_propiedad">
        <Link  className="link_descPropiedad" to={listing.descripcionUrl}>
        <h2>{listing.title}</h2>
        </Link>
        
        <p>{listing.price}</p>
        <ul className="detalles_propiedad OcultoTemporalmente">
          <li>{listing.squareFootage}</li>
          <li>{listing.bedrooms} Camas</li>
          <li>{listing.bathrooms} </li>
        </ul>
        <div className="tags_propiedades">
        <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "17px",
                  marginRight:"7px",
                  fontSize:"0.5em",
                }}
              >
                {listing.tag1}
              </Button>
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "17px",
                  fontSize:"0.5em",
                }}
              >
                {listing.tag2}
              </Button>
        </div>
        <p>{listing.description}</p>
        <hr />
        <div className="botones_propiedadMenu">
          <a href={listing.contactUrl} className="boton_llamar">
            <span class="material-symbols-outlined">call</span>
          </a>
          <a href={listing.contactUrl} className="boton_contacto">
            Contactar
            <span class="material-symbols-outlined">mail</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContenedorPropiedades;
