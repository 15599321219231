import "../Articulos/articulo_revista.css";
import CompartirArticulo from "./componentes_articulos/boton_compartirArticulo";
import ArticulosRelacionados from "./componentes_articulos/articulos_similares";

function Articulo009() {
  return (
    <div className="contenedor_Articulo">
      <div className="encabezadoArticulo">
        <a>
          <h1 className="titulo_Articulo">
            Mexiquenses en París 2024: Un Sueño Olímpico Hecho Realidad
          </h1>
          <CompartirArticulo />
        </a>
        <hr />
      </div>
      <div className="portada_articulo">
        <div className="contenedor_imgPortada">
          <img
            className="portadaImg"
            src="https://cdn-3.expansion.mx/dims4/default/b261714/2147483647/strip/true/crop/5286x3524+0+0/resize/1200x800!/format/webp/quality/60/?url=https%3A%2F%2Fcdn-3.expansion.mx%2Fbd%2Fed%2F1acec3fd44beb6ce9906bda0e335%2Falexa-moreno-clasifica-juegos-olimpicos-paris-2024.JPG"
            alt="Ciclistas"
          ></img>
        </div>
        <div className="categoria_portadaArticulo">DEPORTES</div>
        <div className="fecha_portadaArticulo">Julio 2024</div>
      </div>
      <div className="contenedor_contenidoArticulo">
        <div className="seccion1_articulo">
          <a className="parrafo_secc1">
            <p>
              <b>L</b>os Juegos Olímpicos de París 2024 están a la vuelta de la
              esquina, y la emoción en el Estado de México es palpable. Los
              atletas mexiquenses se están preparando para dejar una huella
              imborrable en este prestigioso evento deportivo. Desde
              entrenamientos intensivos hasta competiciones previas, estos
              deportistas han trabajado incansablemente para representar a
              México en la escena mundial. En este artículo, exploramos las
              historias, los retos y las esperanzas de los atletas mexiquenses
              que buscan la gloria olímpica en París 2024.
            </p>
            <h2>Preparación y Dedicación: El Camino a París</h2>
            <p>
              El camino a los Juegos Olímpicos no es fácil, y los atletas
              mexiquenses lo saben bien. Detrás de cada éxito en la pista, en la
              cancha o en la piscina, hay años de esfuerzo, sacrificio y
              dedicación. Entrenadores, fisioterapeutas, nutricionistas y
              psicólogos deportivos forman un equipo crucial que apoya a los
              deportistas en cada paso del camino.
            </p>
          </a>
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://s.rfi.fr/media/display/fe8e4706-0fd9-11ea-b263-005056bf7c53/w:980/p:16x9/2012-07-28T094047Z_2065858924_LM2E87S0QVO1T_RTRMADP_3_OLY-SWIM-SWM4IM-SWM054905_0.JPG"
              alt="ciclista sonriendo"
            ></img>
          </div>
        </div>
        <div className="seccion2_articulo">
          <p>
            Uno de los atletas destacados es Jorge García, un talentoso nadador
            de Toluca. Jorge ha estado entrenando en la altura de Valle de
            Bravo, aprovechando las condiciones para mejorar su resistencia y
            técnica. "Cada mañana me levanto con el sueño de escuchar el himno
            nacional en el podio olímpico. Ese es mi mayor motivación", comenta
            Jorge.
          </p>
          <div className="Add1_articulo">
            <h2>ESPACIO PUBLICITARIO</h2>
          </div>
          <h2>Talento Mexiquense en Diversas Disciplinas</h2>
          <p>
            Los atletas del Estado de México competirán en diversas disciplinas,
            desde el atletismo hasta el tiro con arco. María López, una
            destacada atleta de Nezahualcóyotl, se ha clasificado para los 100
            metros planos. Su velocidad y determinación la han llevado a romper
            varios récords nacionales, y ahora tiene la mirada puesta en el oro
            olímpico.
          </p>
          <p>
            En el campo del tiro con arco, Carlos Sánchez de Naucalpan ha
            mostrado una precisión increíble. "El tiro con arco requiere una
            concentración y control mental excepcionales. Cada flecha lanzada es
            el resultado de años de práctica y dedicación", explica Carlos,
            quien ha sido campeón nacional en varias ocasiones.
          </p>
        </div>
        <div className="secc3_articulo">
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://www.futboltotal.com.mx/wp-content/uploads/2024/08/mexicanos-en-olimpicos-agenda-del-domingo-4-de-agosto-en-paris-2024.jpg"
              alt="ciclista sonriendo"
            ></img>
          </div>
          <a className="parrafo_secc3">
            <h2>Apoyo y Solidaridad</h2>
            <p>
              El apoyo de la comunidad y las instituciones es fundamental para
              estos atletas. El Gobierno del Estado de México ha implementado
              programas de becas y apoyos financieros para ayudar a los
              deportistas a concentrarse en sus entrenamientos y competiciones.
              Además, empresas locales y nacionales han patrocinado a varios
              atletas, proporcionándoles los recursos necesarios para alcanzar
              el nivel más alto de competencia.
            </p>
            <h2>La Cuenta Regresiva</h2>
            <p>
              Con la cuenta regresiva en marcha para París 2024, los atletas
              mexiquenses están más motivados que nunca. Sus historias de
              perseverancia, sacrificio y pasión son un testimonio del espíritu
              indomable de México. Cada uno de ellos lleva consigo no solo la
              esperanza de ganar medallas, sino también el orgullo de
              representar a su estado y a su país en el escenario más grande del
              deporte.
            </p>
            <h2>Conclusión</h2>
            <p>
              Los Juegos Olímpicos de París 2024 prometen ser un evento
              inolvidable, y los atletas mexiquenses están listos para brillar.
              Con sus habilidades, dedicación y espíritu de lucha, estos
              deportistas nos muestran que los sueños pueden convertirse en
              realidad. Desde el Estado de México, les enviamos nuestro apoyo y
              mejores deseos. ¡Adelante, campeones, y que París 2024 sea su
              momento de gloria!
            </p>
          </a>
        </div>
      </div>
      <ArticulosRelacionados />
      <div className="Add3_articulo">
        <h2>ESPACIO PUBLICITARIO 3</h2>
      </div>
    </div>
  );
}
export default Articulo009;
