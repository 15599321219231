import React, { useState } from "react";
import "./paginaPropiedad_style.css";
import "./caracteristicasPropiedad_style.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CompartirArticulo from "../../RevistaDigital/Articulos/componentes_articulos/boton_compartirArticulo";
import ListadoPropiedades_similares from "./propiedadesSimilares";

const Descripcion_propiedad = [
  {
    id: 1,
    tituloAnuncio: "Hotel Anuies",
    fotoPrincipal:
      "../Images/Fotos/Captura de Pantalla 2024-07-31 a la(s) 22.45.08.png",
    fotoSecundaria1:
      "../Images/Fotos/Captura de Pantalla 2024-08-08 a la(s) 9.24.31.png",
    fotoSecundaria2:
      "../Images/Fotos/Captura de Pantalla 2024-08-08 a la(s) 9.24.39.png",
    fotoSecundaria3:
      "../Images/Fotos/Captura de Pantalla 2024-08-08 a la(s) 9.24.55.png",
    fotoSecundaria4:
      "../Images/Fotos/Captura de Pantalla 2024-08-08 a la(s) 9.25.19.png",
    tipo: "Centrico",
    medida: "Empresarial",
    habitaciones: "Higiene destacada",
    negocio: "Renta",
    precio: "2,700.00",
    ubicacion: "Boulevard Juan Herrera y Piña S/N, El Calvario, 51200 Valle de Bravo",
    coordenadasGMaps: "19.168393, -100.120488",
    estadoConservacion: "Excelente",
    caracteristicaEspecial1: "Caseta de guardia",
    caracteristicaEspecial2: "Cocina integral",
    baños: "1",
    mediosBaños: "1/2",
    estacionamiento: "Para 3 automoviles",
    servicio1: "Hipico",
    servicio2: "Casa club",
    amenidad1: "Caballertiza",
    amenidad2: "Sauna",
    amenidad3: "Gimnasio",
    amenidad4: "Alberca",
    exterior1: "Jardín",
    exterior2: "Invernadero",
    exterior3: "Estanque",
    logoAnunciante:
      "https://scontent.fmex5-1.fna.fbcdn.net/v/t39.30808-6/259168716_1211714002658193_7538908300454343451_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=Eg_viqUvVTcQ7kNvgG9c6OG&_nc_ht=scontent.fmex5-1.fna&oh=00_AYDmbnVE97rKI9gbap5fHttUUv64-9rbbfc6U5FpKHBQSg&oe=66BAAB7D",
    nombreAnunciante: "ANUIES",
    codigoAnunciante: "5R9GDE",
    telefonoAnunciante: "7222559944",
    descripcion:
      "Hotel Anuies ofrece una experiencia de descanso y comodidad en un ambiente acogedor y elegante. Ubicado en una zona estratégica, el hotel combina instalaciones modernas con un servicio cálido y personalizado, asegurando que cada estancia sea memorable y placentera. Las habitaciones de Hotel Anuies están cuidadosamente diseñadas para brindar confort a sus huéspedes, complementadas con servicios adicionales como restaurante y gimnasio. Tanto si se visita por negocios como por placer, el hotel se compromete a ofrecer una estancia excepcional en un entorno que se siente como un segundo hogar.",
      coordenadasGMaps:'!1m18!1m12!1m3!1d235.48473564328438!2d-100.12720686075254!3d19.20586675277628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd638331b00177%3A0x99d18d4ba3efdb2d!2sAnuies%20Valle%20de%20Bravo%20Centro%20de%20Convenciones%20y%20Hospitalidad!5e0!3m2!1ses!2smx!4v1723132665870!5m2!1ses!2smx',
  },
];

const PaginaPropiedad = () => {
  const [activeTab, setActiveTab] = useState("generales");

  const renderContent = () => {
    const propiedad = Descripcion_propiedad[0];
    switch (activeTab) {
      case "generales":
        return (
          <div>
            <p>Estado de conservación: {propiedad.estadoConservacion}</p>
            <p>{propiedad.caracteristicaEspecial1}</p>
            <p>
              {propiedad.baños} Baños y {propiedad.mediosBaños}
            </p>
            <p>{propiedad.caracteristicaEspecial2}</p>
            <p>Estacionamiento {propiedad.estacionamiento}</p>
          </div>
        );
      case "servicios":
        return (
          <div>
            <p>{propiedad.servicio1}</p>
            <p>{propiedad.servicio2}</p>
          </div>
        );
      case "amenidades":
        return (
          <div>
            <p>{propiedad.amenidad1}</p>
            <p>{propiedad.amenidad2}</p>
            <p>{propiedad.amenidad3}</p>
            <p>{propiedad.amenidad4}</p>
          </div>
        );
      case "exteriores":
        return (
          <div>
            <p>{propiedad.exterior1}</p>
            <p>{propiedad.exterior2}</p>
            <p>{propiedad.exterior3}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="contenedor_paginaPropiedad">
      <div className="descripcionGeneral_propiedad">
        {Descripcion_propiedad.map((articulo) => (
          <div key={articulo.id}>
            <div className="encabezadoArticulo">
              <div className="contenedor_ecabezadoHospedaje">
                <h1 className="titulo_Articulo titulo_propiedad">
                  {articulo.tituloAnuncio}
                </h1>
                <CompartirArticulo />
              </div>
              <hr />
            </div>
            <div className="galeriaPropiedad">
              <img
                className="foto_principal"
                src={articulo.fotoPrincipal}
                alt="Principal"
              />
              <div className="fotos_secundarias">
                <img
                  className="fotoSecundaria1"
                  src={articulo.fotoSecundaria1}
                  alt="Secundaria 1"
                />
                <img
                  className="fotoSecundaria2"
                  src={articulo.fotoSecundaria2}
                  alt="Secundaria 2"
                />
                <img
                  className="fotoSecundaria3"
                  src={articulo.fotoSecundaria3}
                  alt="Secundaria 3"
                />
                <img
                  className="fotoSecundaria4"
                  src={articulo.fotoSecundaria4}
                  alt="Secundaria 4"
                />
              </div>
            </div>
            <div className="infoproPiedades">
              <article className="detallesPropiedades">
                <h3 className="preinfo_propiedad">
                  {articulo.tipo} | {articulo.medida} |{" "}
                  {articulo.habitaciones}
                </h3>
                <h2 className="precio_propiedad">
                  Desde MXN ${articulo.precio}
                </h2>
                <hr />
                <a className="contenedor_ubicacion">
                  <span className="material-symbols-outlined">pin_drop</span>
                  <h5>{articulo.ubicacion}</h5>
                </a>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "260px",
                  }}
                >
                  <iframe
                    src={`https://www.google.com/maps/embed?pb=${articulo.coordenadasGMaps}`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: 0,
                      borderRadius: "10px",
                    }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Google Maps"
                  ></iframe>
                </div>
                <hr />
                <h1 className="tituloInfoPropiedad">
                  {articulo.tituloAnuncio}
                </h1>
                <p>{articulo.descripcion}</p>
                <hr />
                <h2 className="titulo_conoceMas">
                  Conoce más sobre esta opción de hospedaje
                </h2>
                <div className="caracteristicas">
                  <div className="tabs">
                    <button
                      className={
                        activeTab === "generales" ? "active" : "inactive"
                      }
                      onClick={() => setActiveTab("generales")}
                    >
                      <span class="material-symbols-outlined">summarize</span>
                      GENERALIDADES
                    </button>
                    <button
                      className={
                        activeTab === "servicios" ? "active" : "inactive"
                      }
                      onClick={() => setActiveTab("servicios")}
                    >
                      <span class="material-symbols-outlined">
                        digital_wellbeing
                      </span>
                      SERVICIOS
                    </button>
                    <button
                      className={
                        activeTab === "amenidades" ? "active" : "inactive"
                      }
                      onClick={() => setActiveTab("amenidades")}
                    >
                      <span class="material-symbols-outlined">diamond</span>
                      AMENIDADES
                    </button>
                    <button
                      className={
                        activeTab === "exteriores" ? "active" : "inactive"
                      }
                      onClick={() => setActiveTab("exteriores")}
                    >
                      <span class="material-symbols-outlined">forest</span>
                      CERCANIAS
                    </button>
                  </div>
                  <div className="content">{renderContent()}</div>
                </div>
                <hr />
                <h2 className="titulo_anunciante">
                  Información del anunciante
                </h2>
                <div className="información_anunciante">
                  <img
                    className="logo_anunciante"
                    src={articulo.logoAnunciante}
                  />
                  <a>
                    <h2>{articulo.nombreAnunciante}</h2>
                    <p>Código del anunciante:{articulo.codigoAnunciante}</p>
                  </a>
                </div>
              </article>
              <aside className="contactoPropiedades">
                <div>
                <div className="contacta_contenedor">
                  <h2 className="titulo_contactoPropiedades">
                    Contacta a {articulo.tituloAnuncio}
                  </h2>
                  <TextField
                    id="outlined-basic"
                    label="Correo electrónico"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}

                  />
                  <a>
                    <TextField
                      id="outlined-basic"
                      label="Nombre"
                      variant="outlined"
                      style={{ marginBottom: "10px", flex: "1", marginRight:"15px" }}

                    />
                    <TextField
                      id="outlined-basic"
                      label="Teléfono"
                      variant="outlined"
                      style={{ marginBottom: "10px", flex: "1" }}

                    />
                  </a>
                  <TextField
                    id="outlined-multiline-static"
                    label="Mensaje:"
                    multiline
                    rows={4}
                    defaultValue="¡Hola! Quiero que se comuniquen conmigo para realizar una reservación en Hotel Anuies."
                    style={{ marginBottom: "10px", width: "100%" }}
                  />
                  <Button variant="contained" disableElevation
                  style={{ backgroundColor: "#EF8822", color: "#fff" }}
                  >
                    Enviar
                  </Button>
                  <h5 className="boton_terminosCondiciones">
                    Al enviar estás aceptando los Términos y condiciones de Uso
                    y la Política de Privacidad
                  </h5>
                </div>
                <div className="contenedor_telefonoFormulario">
                 <div className="contenedor_logoTelefono">
                 <img className="logo_telefono" src={articulo.logoAnunciante}></img>
                 </div>
                 <div className="informacion_telefono">
                    <h4>{articulo.nombreAnunciante}</h4>
                    <div>
                     <h3>72242</h3>
                     <p>VER TELÉFONO COMPLETO</p>
                    </div>
                 </div>
                </div>
                </div>
                
                <div className="addPropiedad1">
                  <h1>ESPACIO PUBLICITARIO</h1>
                </div>
              </aside>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaginaPropiedad;
