import React, { useState } from "react";

const RestaurantCard = ({ restaurant }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleContactClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleMapsClick = () => {
    window.open(restaurant.mapsLink, "_blank");
  };

  return (
    <div className="restaurant-card">
      <div className="image-container">
        <img src={restaurant.image} alt={restaurant.name} />
      </div>
      <div className="informacionRestaurante">
        <div className="nombreUbicacion_restaurante">
          <a href={restaurant.paginaSite}>
            <h3>{restaurant.name}</h3>
          </a>
          <h4>{restaurant.colonia}</h4>
        </div>

        <p className="horarioRestaurante">Horario: {restaurant.hours}</p>
        <div className="division_comidaPrecioMenu">
          <p>{restaurant.cuisine}</p>
          <p className="puntoSeparador">•</p>
          <p>{restaurant.price}</p>
          <p className="puntoSeparador">•</p>
          <p>Ver menú</p>
        </div>
        <hr />
        <p className="descripcionRestaurante">{restaurant.description}</p>
        <hr />
        <div className="buttons">
          <button className="botonReservar" onClick={handleContactClick}>
            <b>Contactar</b>
          </button>
          <button className="botonUbicacion_restaurantes" onClick={handleMapsClick}>
            <b>Ver en Maps</b>
          </button>
        </div>
      </div>

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h2>Contacto</h2>
            <p>Teléfono: {restaurant.phone}</p>
            <p>Correo: {restaurant.email}</p>
            <button onClick={handleClosePopup}>Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestaurantCard;
