import "../Articulos/articulo_revista.css";
import CompartirArticulo from "./componentes_articulos/boton_compartirArticulo";
import ArticulosRelacionados from "./componentes_articulos/articulos_similares";

function Articulo005() {
  return (
    <div className="contenedor_Articulo">
      <div className="encabezadoArticulo">
        <a>
          <h1 className="titulo_Articulo">
            Descubre ANUIES: El Nuevo Refugio en el Corazón de Valle de Bravo
          </h1>
          <CompartirArticulo />
        </a>
        <hr />
      </div>
      <div className="portada_articulo">
        <div className="contenedor_imgPortada">
          <img
            className="portadaImg"
            src="https://valledebravo.anuies.mx/wp-content/uploads/2023/03/17015805_1397325217006980_4509903221359477335_o.jpg"
            alt="Ciclistas"
          ></img>
        </div>
        <div className="categoria_portadaArticulo">EXPERIENCIAS</div>
        <div className="fecha_portadaArticulo">Julio 2024</div>
      </div>
      <div className="contenedor_contenidoArticulo">
        <div className="seccion1_articulo">
          <a className="parrafo_secc1">
            <p>
              <b>V</b>alle de Bravo, conocido por su encanto pintoresco y su
              vibrante vida cultural, da la bienvenida a su nuevo tesoro
              hotelero: Anuies Valle de Bravo. Este hotel recién abierto promete
              convertirse en el destino favorito tanto para viajeros frecuentes
              como para aquellos que buscan una escapada tranquila en una
              ubicación privilegiada.
              <br />
              Situado estratégicamente cerca del centro de Valle de Bravo,
              ANUIES ofrece una experiencia de hospedaje inigualable. Imagina
              despertar a pocos pasos de las principales atracciones del pueblo,
              con la comodidad de tener todo al alcance de tu mano. Desde las
              tiendas locales llenas de artesanías y recuerdos únicos hasta una
              vida nocturna vibrante, ANUIES te coloca en el corazón de toda la
              acción.
            </p>
            <h2>Conveniencia y Comodidad al Alcance</h2>
            <p>
              La proximidad de ANUIES a los supermercados es otro de sus grandes
              atractivos. Olvídate de las preocupaciones por las compras de
              última hora o los suministros necesarios durante tu estancia. Todo
              lo que necesitas está a una corta distancia, haciendo tu
              experiencia de hospedaje aún más cómoda y libre de estrés.
              <br /> Para aquellos viajeros que valoran la eficiencia, Anuies
              Valle de Bravo se encuentra cerca de la salida a la autopista.Esto
              no solo facilita una llegada rápida y sin complicaciones, sino que
              también te permite aprovechar al máximo tu tiempo, ya sea que
              estés en un viaje de negocios o de placer.
            </p>
          </a>
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="../Images/Fotos/Captura de Pantalla 2024-07-31 a la(s) 22.45.08.png"
              alt="ciclista sonriendo"
            ></img>
          </div>
        </div>
        <div className="seccion2_articulo">
          <h2>Un Refugio de Confort y Tranquilidad</h2>
          <p>
            ANUIES no solo ofrece una ubicación excepcional, sino que también
            garantiza una estancia placentera con servicios de alta calidad y un
            ambiente acogedor. Cada rincón del hotel está diseñado para
            brindarte confort y lujo, asegurando que tu visita a Valle de Bravo
            sea memorable.
          </p>
          <div className="Add1_articulo">
            <h2>ESPACIO PUBLICITARIO</h2>
          </div>
          <h2>Reserva tu Estancia Hoy</h2>
          <p>
            No esperes más para descubrir ANUIES, el nuevo refugio en Valle de
            Bravo que lo tiene todo: conveniencia, comodidad y una ubicación
            envidiable. Reserva tu estancia hoy y vive una experiencia que
            seguramente querrás repetir.
            <br/>
            <br/>
            Teléfono: 726 972 3409
          </p>
        </div>
    
      </div>
      <ArticulosRelacionados />
      <div className="Add3_articulo">
        <h2>ESPACIO PUBLICITARIO 3</h2>
      </div>
    </div>
  );
}
export default Articulo005;
