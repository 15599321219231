import "./comentariosVisitantes_style.css";
import React, { useState } from "react";

const Diapositivas = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="diapos_comentariosVisitantes">
      <button onClick={prevSlide} className="botonDiaposVisitante">
        <span class="material-symbols-outlined">arrow_back_ios</span>
      </button>
      <div>{slides[currentSlide]}</div>
      <button onClick={nextSlide} className="botonDiaposVisitante">
        <span class="material-symbols-outlined">arrow_forward_ios</span>
      </button>
    </div>
  );
};

// Ejemplo de uso
const comentariosVisitantes = () => {
  const slides = [
    <div key="1" className="diapositiva_Visitantes">
      <a>
        <h2>"</h2>
      </a>
      <div className="descripcion_visitantes">
        <p>
          <a className="letraPrincipal">V</a>alle de Bravo es simplemente hermoso. Las vistas del lago desde el Mirador La Peña son impresionantes. Disfruté mucho del ambiente tranquilo y de las actividades acuáticas. Sin duda, es un lugar al que quiero volver.
        </p>
        <div className="nombreVisitante">
          <img
            src="https://pbs.twimg.com/profile_images/1054171964112289794/KW_CYhfn_400x400.jpg"
            alt=""
          />
          <h3>Laura Gutiérrez</h3>
        </div>
      </div>
      <a>
        <h2>"</h2>
      </a>
    </div>,
    <div key="2" className="diapositiva_Visitantes">
      <a>
        <h2>"</h2>
      </a>
      <div className="descripcion_visitantes">
        <p>
          <a className="letraPrincipal">P</a>asé un fin de semana increíble en Valle de Bravo. La caminata hasta las Cascadas Velo de Novia fue desafiante pero valió la pena cada paso. Además, la comida local es deliciosa, especialmente en los pequeños restaurantes del centro.
        </p>
        <div className="nombreVisitante">
          <img
            src="https://www.medimundo.com.mx/wp-content/uploads/2020/09/Dr.-Carlos-Mendoza-Segura.jpg"
            alt=""
          />
          <h3>Carlos Mendoza</h3>
        </div>
      </div>
      <a>
        <h2>"</h2>
      </a>
    </div>,
    <div key="3" className="diapositiva_Visitantes">
      {" "}
      <a>
        <h2>"</h2>
      </a>
      <div className="descripcion_visitantes">
        <p>
          <a className="letraPrincipal">V</a>alle de Bravo es el destino perfecto para desconectar de la rutina. Me encantó la combinación de naturaleza y cultura. La visita al Santuario de la Mariposa Monarca en Piedra Herrada fue una experiencia mágica. Lo recomiendo al 100%
        </p>
        <div className="nombreVisitante">
          <img
            src="https://pbs.twimg.com/profile_images/879265216097333249/Qcr9hGBg_400x400.jpg"
            alt=""
          />
          <h3>Ana López</h3>
        </div>
      </div>
      <a>
        <h2>"</h2>
      </a>
    </div>,
    <div key="4" className="diapositiva_Visitantes">
    <a>
      <h2>"</h2>
    </a>
    <div className="descripcion_visitantes">
      <p>
        <a className="letraPrincipal">E</a>ste lugar es un paraíso para los amantes del deporte y la naturaleza. Practicar parapente sobre el lago fue una experiencia inolvidable. Además, el ambiente del pueblo es muy acogedor y la gente es muy amable.
      </p>
      <div className="nombreVisitante">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQrZbGdG1n0wXrlJhots4ETASABW9tlgGntA&s"
          alt=""
        />
        <h3>Jorge Ramírez</h3>
      </div>
    </div>
    <a>
      <h2>"</h2>
    </a>
  </div>,
  ];

  return <Diapositivas slides={slides} />;
};

export default comentariosVisitantes;
