import "../Pages/inicio_guiaTuristica_style.css";
import CompartirGuia from "../Componentes/GuiaTuristica/inicio_guia/compartir_guia";
import SitiosImportantes from "../Componentes/GuiaTuristica/sitiosImportantes";
import BotonesMenu_guia from "../Componentes/GuiaTuristica/inicio_guia/botones_guia";
import ComentariosVisitantes from "../Componentes/GuiaTuristica/inicio_guia/comentariosVisitantes";

function GuiaInicio() {
  return (
    <div className="inicio_guia">
      <div className="contenedor_bannerGuia">
        <img
          className="banner_superiorGuia"
          src="./Images/portada_guia.png"
          alt="montañas_valledeBravo"
        />
      </div>
      <div className="contenedor_compartirGuia">
        <CompartirGuia />
      </div>
      <div className="tituloGuia">
        <h3>EXPLORA</h3>
        <hr />
        <h2>LA CULTURA DE</h2>
        <h1>VALLE DE BRAVO</h1>
      </div>
      <div className="contenedor_logoGuia">
        <img
          className="logoGuia"
          src="./Images/logo_guiaTuristica.png"
          alt="Guia turistica logo"
        />
      </div>
      <div className="textoPrincipal_guia">
        <h2 className="tituloBienvenidosGuia">Bienvenidos a Valle de Bravo</h2>
        <p className="texto1_guia">
          Ubicado en el corazón del Estado de México, Valle de Bravo es un
          destino turístico de ensueño que combina naturaleza, aventura y
          cultura en un solo lugar. Con su pintoresco lago, exuberantes montañas
          y encantadoras calles empedradas, Valle de Bravo ofrece una
          experiencia inolvidable para todos los visitantes.
        </p>
        <h2>Historia y Cultura</h2>
        <p>
          Valle de Bravo, conocido por su rica historia y vibrante cultura, es
          un Pueblo Mágico que cautiva a sus visitantes con su arquitectura
          colonial y sus tradiciones arraigadas. Desde su fundación en el siglo
          XVI, el pueblo ha mantenido su encanto auténtico, atrayendo tanto a
          turistas como a residentes que buscan una escapada tranquila y llena
          de cultura.
        </p>
      </div>

      <div className="titulo_sitiosImperdibles">
        <h2>SITIOS IMPERDIBLES</h2>
        <div className="barra_titulos"></div>
      </div>
      <SitiosImportantes />
      <hr className="lineaDivisora1_guia" />
      <div className="titulo_sitiosImperdibles">
        <h2>TODO LO QUE NECESITAS PARA TU VISITA</h2>
        <div className="barra_titulos"></div>
      </div>
      <BotonesMenu_guia />
      <div className="titulo_sitiosImperdibles">
        <h2>ACERCA DE VALLE DE BRAVO </h2>
        <div className="barra_titulos"></div>
      </div>
      <div className="acercade_Valle">
        <div className="acercadeValle_contenedor">
          <div className="acercadeValle_texto">
            <h3>Actividades y aventura</h3>
            <p>
              <a className="letraInicial">V</a>alle de Bravo es un paraíso para
              los amantes de la aventura. Además de las actividades acuáticas en
              el lago, puedes disfrutar de parapente, ciclismo de montaña,
              senderismo y equitación. Los aventureros encontrarán en Valle de
              Bravo un lugar lleno de emociones y paisajes asombrosos.
            </p>
          </div>
          <div className="acercadeValle_texto">
            <h3>Gastronomía Local</h3>
            <p>
              <a className="letraInicial">L</a>a gastronomía de Valle de Bravo
              es una delicia para el paladar. Desde los tradicionales tacos y
              sopes hasta platillos más elaborados como el mole y el pozole,
              cada comida es una experiencia única. No olvides visitar el
              restaurante "Chile Mole Pozole" para disfrutar de auténtica comida
              mexicana preparada a la perfección.
            </p>
          </div>
          <div className="acercadeValle_texto">
            <h3>Hospedaje y Comodidades</h3>
            <p>
              <a className="letraInicial">V</a>alle de Bravo ofrece una amplia
              gama de opciones de alojamiento que se adaptan a todos los
              presupuestos. Desde lujosos hoteles boutique hasta acogedoras
              cabañas en el bosque, hay un lugar perfecto para cada visitante.
            </p>
          </div>
        </div>

        <iframe
          width="820"
          height="320"
          src="https://www.youtube.com/embed/UveVZ7KmVGw"
          title="VALLE DE BRAVO, Que hacer en el PUEBLO MÁGICO del EDOMEX | MÉXICO"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="contenedor_comentariosVisitanes">
        <h2>Testimonios de visitantes</h2>
        <p>
          ¿Qué están diciendo los últimos turistas que visitaron Valle de Bravo?
        </p>
        <ComentariosVisitantes />
      </div>
      <div className="espacioPublicitario1_guia">
        <h2>Espacio publicitario</h2>
      </div>
      <img
        className="logo_guiaTur_inferior"
        src="./Images/logo_guiaTuristica.png"
      ></img>
      <img
        className="imagenInferior"
        src="./Images/FOTO_INFERIORCASCADA.png"
      ></img>
    </div>
  );
}

export default GuiaInicio;
