import "../Articulos/articulo_revista.css";
import CompartirArticulo from "./componentes_articulos/boton_compartirArticulo";
import ArticulosRelacionados from "./componentes_articulos/articulos_similares";

function Articulo012() {
  return (
    <div className="contenedor_Articulo">
      <div className="encabezadoArticulo">
        <a>
          <h1 className="titulo_Articulo">
            La historia del parapente en México: Un viaje de altura{" "}
          </h1>
          <CompartirArticulo />
        </a>
        <hr />
      </div>
      <div className="portada_articulo">
        <div className="contenedor_imgPortada">
          <img
            className="portadaImg"
            src="https://www.mexicodestinos.com/blog/wp-content/uploads/2017/07/tapalpa-jalisco-parapente-635x336.jpg"
            alt="Ciclistas"
          ></img>
        </div>
        <div className="categoria_portadaArticulo">DEPORTES</div>
        <div className="fecha_portadaArticulo">Julio 2024</div>
      </div>
      <div className="contenedor_contenidoArticulo">
        <div className="seccion1_articulo">
          <a className="parrafo_secc1">
            <p>
              <b>E</b>l parapente, una de las disciplinas más emocionantes del
              vuelo libre, tiene una historia fascinante y rica en México. Desde
              sus humildes comienzos hasta convertirse en una actividad
              reconocida y apreciada en todo el país, el parapente ha
              evolucionado gracias al espíritu aventurero y la pasión de quienes
              se atrevieron a surcar los cielos. En este artículo, exploramos la
              historia del parapente en México, destacando los momentos clave y
              las personas que han contribuido a su desarrollo.
            </p>
            <h2>Orígenes del Parapente en México</h2>
            <p>
              El parapente, como deporte, surgió en los años 70 en Europa, pero
              no fue sino hasta la década de los 80 que empezó a ganar
              popularidad en México. Los primeros pilotos mexicanos fueron
              pioneros apasionados que, inspirados por el auge del parapente en
              Francia y Suiza, decidieron llevar esta emocionante actividad a
              las montañas y valles mexicanos. Uno de los primeros lugares en
              México donde se practicó el parapente fue Valle de Bravo, un sitio
              conocido por sus condiciones climáticas ideales y paisajes
              impresionantes. Valle de Bravo se convirtió rápidamente en un
              punto de encuentro para los amantes del vuelo libre, atrayendo a
              pilotos nacionales e internacionales.
            </p>
          </a>
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://livinglavida.com/cdn/shop/products/vuelo_en_parapente_en_valle_de_bravo_alas_del_hombre_1_1200x1200.jpg?v=1635951030"
              alt="ciclista sonriendo"
            ></img>
          </div>
        </div>
        <div className="seccion2_articulo">
          <h2>El Auge del Parapente en los 90's</h2>
          <p>
            La década de los 90 fue crucial para el desarrollo del parapente en
            México. Durante estos años, se fundaron varias escuelas y clubes de
            parapente, lo que facilitó la enseñanza y promoción de la
            disciplina. Además, se comenzaron a organizar los primeros
            campeonatos y competencias nacionales, lo que incentivó a más
            personas a interesarse y practicar este deporte. Uno de los eventos
            más destacados de la época fue el "Abierto Mexicano de Parapente",
            que se celebró por primera vez en Valle de Bravo. Este evento atrajo
            a pilotos de todo el mundo y ayudó a posicionar a México como un
            destino de parapente de clase mundial.
          </p>
          <div className="Add1_articulo">
            <h2>ESPACIO PUBLICITARIO</h2>
          </div>
          <h2>Innovación y Seguridad</h2>
          <p>
            Con el crecimiento del parapente en México, también vino la
            necesidad de mejorar la seguridad y la tecnología utilizada en el
            deporte. Durante los años 2000, se introdujeron equipos más
            avanzados y se establecieron normativas de seguridad más estrictas.
            Las mejoras en la fabricación de las alas y los sistemas de arneses
            han hecho que el parapente sea más seguro y accesible para un
            público más amplio. Las organizaciones como la Federación Mexicana
            de Vuelo Libre (FMVL) han jugado un papel crucial en la promoción de
            prácticas seguras y en la capacitación de pilotos a lo largo del
            país. Gracias a sus esfuerzos, el parapente en México ha alcanzado
            estándares internacionales de seguridad y calidad.
          </p>
        </div>
        <div className="secc3_articulo">
        <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://vivevalle.mx/wp-content/uploads/2018/10/ParapenteD.jpg"
              alt="ciclista sonriendo"
            ></img>
          </div>
          <a className="parrafo_secc3">
            <p>
              Hoy en día, el parapente en México es una actividad bien
              establecida y respetada. Hay numerosos sitios de vuelo repartidos
              por todo el país, desde las playas de Baja California hasta las
              montañas de Puebla y Chiapas. Cada año, México acoge a cientos de
              pilotos que vienen a disfrutar de las condiciones de vuelo
              excepcionales y los paisajes espectaculares. Valle de Bravo sigue
              siendo uno de los destinos más populares, no solo por sus
              condiciones de vuelo sino también por su vibrante comunidad de
              parapentistas y la organización de eventos internacionales como la
              Copa Mundial de Parapente.
            </p>
            <p>
              La historia del parapente en México es una historia de pasión,
              aventura y constante evolución. Desde sus inicios en los años 80
              hasta su consolidación como un deporte de alto nivel, el parapente
              ha capturado la imaginación y el corazón de muchos. Con una
              comunidad dedicada y un paisaje diverso y hermoso, México seguirá
              siendo un lugar especial para el parapente en los años venideros.
              Para aquellos que buscan experimentar la emoción de volar y ver el
              mundo desde una nueva perspectiva, el parapente en México ofrece
              una oportunidad única. Ya sea que seas un piloto experimentado o
              un principiante curioso, los cielos mexicanos te esperan para una
              aventura inolvidable.
            </p>
          </a>
       
        </div>
      </div>
      <ArticulosRelacionados />
      <div className="Add3_articulo">
        <h2>ESPACIO PUBLICITARIO 3</h2>
      </div>
    </div>
  );
}
export default Articulo012;
