import React from 'react';

const AlphabetFilter = ({ selectedLetter, onLetterSelect }) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  return (
    <div  className="alphabet-filter">
       <div>
      {alphabet.map(letter => (
        <button
          key={letter}
          className={letter === selectedLetter ? 'selected' : ''}
          onClick={() => onLetterSelect(letter)}
        >
          {letter}
        </button>
      ))}
      <button className="emergency-button">Números de Emergencia</button>
    </div>
    
    </div>
   
  );
};

export default AlphabetFilter;
