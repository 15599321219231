import "../RevistaDigital/articulos_recientes_style.css";
import { useNavigate } from "react-router-dom";

function Articulos_recientes() {
  const navigate = useNavigate();

  const handleDivClick = (url) => {
    navigate(url);
  };

  return (
    <div className="articulos_recientes">
      <div className="articulos_principales">
        <div
          className="contenedor_artPrinc"
          onClick={() =>
            handleDivClick("/revista-digital-julio2024/articulo001-Historia-del-parapente_en-Valle-de-Bravo")
          }
        >
          <img
            className="ArticulosPrincipales_img"
            src="https://www.paraglidingmexico.com/templates/yootheme/cache/penon0032-73bb51f4.jpeg"
            alt="Volando en parapente en Valle de Bravo"
          ></img>
          <div className="categoria_fecha">
            <a className="categoria_cont">DEPORTES</a>
            <p className="Fecha">Julio 2024</p>
          </div>
          <h3 className="titulo_artPrinc">
            La historia del parapente en México
          </h3>
          <p className="descripcion_artPrinc">
            El parapente, una de las disciplinas más emocionantes del vuelo
            libre, tiene una historia fascinante y rica en México. Desde sus
            humildes comienzos hasta convertirse en una actividad reconocida y
            apreciada en todo el país...
          </p>
        </div>
        <div className="contenedor_artPrinc"
        onClick={() =>
          handleDivClick("/revista-digital-julio2024/articulo002-que-comprar-en-Valle-deBravo")
        }
        >
          <img
            className="ArticulosPrincipales_img"
            src="https://alephmultimedios.com/wp-content/uploads/WhatsApp-Image-2022-01-15-at-8.51.50-AM-3.jpeg"
            alt=""
          ></img>
          <div className="categoria_fecha">
            <a className="categoria_cont">TURISMO</a>
            <p className="Fecha">Julio 2024</p>
          </div>
          <h3 className="titulo_artPrinc">¿Qué comprar en Valle de Bravo?</h3>
          <p className="descripcion_artPrinc">
            Valle de Bravo también es el lugar de las compras, pues existen
            creaciones artesanales que imprimen la cultura e historia de los
            pueblos indígenas. Con muchos artículos decorativos y personales,
            así como también ...
          </p>
        </div>
      </div>
      <div className="articulos_secundarios">
        <div className="contenedor_artSec"
        onClick={() =>
          handleDivClick("/revista-digital-julio2024/articulo003-joya-escondida-en-el-estado-de-mexico")
        }
        >
          <img
            className=""
            src="https://media.staticontent.com/media/pictures/65a37fb6-fa4b-4c0b-9344-a0d4e9f62599"
            alt=""
          ></img>
          <div className="categoria_fecha">
            <a className="categoria_cont">TURISMO</a>
            <p className="Fecha">Julio 2024</p>
          </div>
          <h3 className="titulo_artSec">Joya Escondida en el Estado de México</h3>
        </div>
        <div className="contenedor_artSec"
        onClick={() =>
          handleDivClick("/revista-digital-julio2024/articulo004-cómo-invertir-en-bienes-raices-en-Valle-deBravo")
        }>
          <img
            className=""
            src="https://images.adsttc.com/media/images/5f3b/f59c/b357/6527/3300/01c7/large_jpg/20-Casa-Vista-al-lago-14.jpg?1597765013"
            alt=""
          ></img>
          <div className="categoria_fecha">
            <a className="categoria_cont">NEGOCIOS</a>
            <p className="Fecha">Julio 2024</p>
          </div>
          <h3 className="titulo_artSec">Cómo Invertir en Bienes Raíces en Valle de Bravo</h3>
        </div>
        <div className="contenedor_artSec" 
        onClick={() =>
          handleDivClick("/revista-digital-julio2024/articulo005-anuies-nuevo-hotel-valle-de-bravo")
        }
        >
          <img
            className=""
            src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/558911616.jpg?k=4e1b3212d9985eda3288800d502f598fb2f5946bc515f9e67cdc30e255b0da02&o=&hp=1"
            alt=""
          ></img>
          <div className="categoria_fecha">
            <a className="categoria_cont">EXPERIENCIAS</a>
            <p className="Fecha">Julio 2024</p>
          </div>
          <h3 className="titulo_artSec">Descubre ANUIES: El Nuevo Refugio en el Corazón de Valle de Bravo</h3>
        </div>
        <div className="contenedor_artSec"
        onClick={() =>
          handleDivClick("/revista-digital-julio2024/articulo006-los-mejores-lugares-para-descansar-cerca-de-cdmx")
        }
        >
          <img
            className=""
            src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/70022079.jpg?k=78390f4040bdd0698a56ad0949883a55e49894a179510809eacf3d77f143e4b6&o=&hp=1"
            alt=""
          ></img>
          <div className="categoria_fecha">
            <a className="categoria_cont">LIFESTYLE</a>
            <p className="Fecha">Julio 2024</p>
          </div>
          <h3 className="titulo_artSec">Los mejores lugares para descansar cerca de CDMX</h3>
        </div>
        <div className="contenedor_artSec"
        onClick={() =>
          handleDivClick("/revista-digital-julio2024/articulo007-historia-de-valle_de_bravo-sigloXX-sigloXXI")
        }
        >
          <img
            className=""
            src="https://scontent.fmex26-1.fna.fbcdn.net/v/t39.30808-6/451362835_122107306550398540_5918672740090080813_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=127cfc&_nc_ohc=hmEEFfso7IoQ7kNvgHKLyFY&_nc_ht=scontent.fmex26-1.fna&gid=AHgGcY1aLQLtkeHjxQKH7th&oh=00_AYBsWGxfiDZQ9bXKK69jwBFzczOmznFJNEx4iHiwiwQs2g&oe=66AE0580"
            alt=""
          ></img>
          <div className="categoria_fecha">
            <a className="categoria_cont">CULTURA</a>
            <p className="Fecha">Julio 2024</p>
          </div>
          <h3 className="titulo_artSec">Historia de Valle de Bravo: Siglos XIX y XX</h3>
        </div>
        <div className="contenedor_artSec"
        onClick={() =>
          handleDivClick("/revista-digital-julio2024/articulo008-hongos-temporada_2024-valle-de-bravo")
        }
        >
          <img
            className=""
            src="https://media.es.wired.com/photos/6465743c88218b01bf4a1a7c/4:3/w_892,h_669,c_limit/WhatsApp%20Image%202023-05-11%20at%2016.44.40.jpeg"
            alt=""
          ></img>
          <div className="categoria_fecha">
            <a className="categoria_cont">CULTURA</a>
            <p className="Fecha">Julio 2024</p>
          </div>
          <h3 className="titulo_artSec">Hongos en Valle de Bravo: Temporada 2024</h3>
        </div>
        
      </div>
    </div>
  );
}

export default Articulos_recientes;
