import React, { useState } from "react";
import "./botonesGuia_style.css";

function BotonesMenu_guia() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="contenedor_botonesGuia">
      <div className="botonesSeccionesGuia">
        <a href="/hospedaje-en-valle-de-bravo" className="boton_seccionGuia">
          <img
            className="icono_GuiaBoton"
            src="../Images/iconos/iconoHotel.png"
            alt="Icono hospedaje"
          ></img>
          <h3>Hospedaje</h3>
        </a>
        <a href="#" className="boton_seccionGuia">
          <img
            className="icono_GuiaBoton"
            src="../Images/iconos/parapenteIcono.png"
            alt="Icono parapente"
          ></img>
          <h3>Actividades</h3>
        </a>
        <a href="/restaurantes-recomendados" className="boton_seccionGuia">
          <img
            className="icono_GuiaBoton"
            src="../Images/iconos/alimentosIcono.png"
            alt="Icono alimentos"
          ></img>
          <h3>Alimentos</h3>
        </a>
        <a href="#" className="boton_seccionGuia OcultoTemporalmente">
          <img
            className="icono_GuiaBoton"
            src="../Images/iconos/transporteIcono.png"
            alt="Icono transporte"
          ></img>
          <h3>Transporte</h3>
        </a>
      </div>
      <div className="contenedor_botonEmergencia">
        <button className="boton_Emergencia" onClick={togglePopup}>
          <p>Números de Emergencia</p>
        </button>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h3>Números de Emergencia</h3>
            <ul>
              <li>Policía: 911</li>
              <li>Bomberos: 911</li>
              <li>Ambulancia: 911</li>
              <li>Protección Civil: (726) 262-1661</li>
              <li>Emergencias Viales: (726) 262-1661</li>
            </ul>
            <button className="close-popup" onClick={togglePopup}>Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BotonesMenu_guia;
