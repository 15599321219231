import React, { useState } from 'react';
import './StarRating_style.css';

const StarRating = ({ totalStars = 5, rating = 0 }) => {
  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((star, index) => {
        index += 1;
        return (
          <span key={index} className={index <= rating ? "star on" : "star off"}>
            &#9733;
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
