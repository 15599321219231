import "../Articulos/articulo_revista.css";
import CompartirArticulo from "./componentes_articulos/boton_compartirArticulo";
import ArticulosRelacionados from "./componentes_articulos/articulos_similares";

function Articulo002() {
  return (
    <div className="contenedor_Articulo">
      <div className="encabezadoArticulo">
        <a>
          <h1 className="titulo_Articulo">
            Comercio en Valle de Bravo: ¿Qué puedo comprar en este pueblo
            mágico?
          </h1>
          <CompartirArticulo />
        </a>
        <hr />
      </div>
      <div className="portada_articulo">
        <div className="contenedor_imgPortada">
          <img
            className="portadaImg"
            src="https://alephmultimedios.com/wp-content/uploads/WhatsApp-Image-2022-01-15-at-8.51.50-AM-3.jpeg"
            alt="Ciclistas"
          ></img>
        </div>
        <div className="categoria_portadaArticulo">TURISMO</div>
        <div className="fecha_portadaArticulo">Julio 2024</div>
      </div>
      <div className="contenedor_contenidoArticulo">
        <div className="seccion1_articulo">
          <a className="parrafo_secc1">
            <p>
              <b>V</b>alle de Bravo también es el lugar de las compras, pues
              existen creaciones artesanales que imprimen la cultura e historia
              de los pueblos indígenas. Con muchos artículos decorativos y
              personales, así como también alimentos, el destino mexiquense da
              muchas oportunidades de adquirir objetos artísticos. Checa los
              lugares para comprar:
            </p>
            <h3>Mercado de Artesanías</h3>
            <p>
              Aquí se exponen y venden las artesanías de la región, tales como
              vidrio soplado, cerámica de alta temperatura y de barro, bordados
              mazahuas, tejidos a mano, deshilados, hierro forjado, cestería de
              ocoxal, productos en madera y variedad de artículos más. El
              mercado se encuentra en la avenida Benito Juárez a tres cuadras
              del centro.
            </p>
            <h3>Avenida Joaquín Arcadio Pagaza</h3>
            <p>
              Importante calle donde se han establecido tiendas boutique con
              productos de alta calidad resaltando siempre el estilo mexicano.
              Darse un paseo por esta calle dará muchas opciones para comprar
              regalos y decoraciones del hogar.
            </p>
          </a>
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://s3.amazonaws.com/fathom_media/uploads/valle-de-bravo-market2.jpg.1200x800_q85.jpg"
              alt="ciclista sonriendo"
            ></img>
          </div>
        </div>
        <div className="seccion2_articulo">
          <h3>Conservas Naturales y Cestería</h3>
          <p>
            En el centro, cerca de los mercados y lugares específicos verás la
            venta de miel, ate o mermeladas hechas por manos de artesanos
            mazahuas. Es una tradición de Valle de Bravo que a los turistas
            atrae. La cestería son artículos como canastos, manteles y cajas
            hechos con ramas de palma que también son del gusto de la gente por
            su excelente calidad. En cuanto a cerámica de alta temperatura y
            alfarería de Valle de Bravo puedes encontrar las clásicas ollas y
            tazas, pero también atractivas vajillas y hasta sofisticados diseños
            contemporáneos. La región también es conocida por sus atractivos
            trabajos de cestería.
          </p>
          <div className="Add1_articulo">
            <h2>ESPACIO PUBLICITARIO</h2>
          </div>
          <h3>Gastronomía de Valle de Bravo</h3>
          <p>
            Dentro de los platillos que no hay que perderse al visitar Valle de
            Bravo, se encuentran: la exquisita trucha arcoíris, preparada al
            horno, en el comal o al vapor; las alcachofas, que son cosechadas
            ahí mismo; barbacoa y consomé de borrego; o bien los ricos tacos en
            el “callejón del taco” como ahora llaman a la calle…
          </p>
        </div>
        <div className="secc3_articulo">
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://www.elsoldemexico.com.mx/lomerezco/dos-horas/1e0x6o-calllejon-del-hambre_-foto_-arturo-aguilar-1.jpg/ALTERNATES/FREE_768/calllej%C3%B3n%20del%20hambre_%20foto_%20Arturo%20Aguilar%20(1).JPG"
              alt="ciclista sonriendo"
            ></img>
          </div>
          <a className="parrafo_secc3">
            <h3>
              Para los amantes de la adrenalina hay deportes todo terreno en
              Valle de Bravo
            </h3>
            <p>
              Los amantes a la adrenalina y las emociones extremas encuentran en
              Valle de Bravo las condiciones aptas para realizar recorridos por
              caminos ásperos de montaña, bosque o campo a través.
            </p>
            <p>
              Las actividades más populares y con mayor demanda son sin duda la
              Bicicleta de Montaña, el Motocross, Enduro y Cuatrimoto. Existe
              una gran variedad de prestadores de servicio de aventura y
              entretenimiento; estos mismos ofrecen la renta de equipo y
              realizan tours por la ciudad o lugares cercanos donde se pone a
              prueba la agilidad y temple de principiantes y expertos.
            </p>
          </a>
        </div>
      </div>
      <ArticulosRelacionados />
      <div className="Add3_articulo">
        <h2>ESPACIO PUBLICITARIO 3</h2>
      </div>
    </div>
  );
}
export default Articulo002;
