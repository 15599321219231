import RealEstateCatalog from './contenedor_propiedadMenu';

const listings = [
  {
    id: 1,
    imageUrl: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/558911616.jpg?k=4e1b3212d9985eda3288800d502f598fb2f5946bc515f9e67cdc30e255b0da02&o=&hp=1',
    title: 'Hotel ANUIES',
    price: 'Desde $2,900.00 por noche',
    squareFootage: 215,
    bedrooms: 2,
    bathrooms: 2,
    description: 'el Hotel Anuies ofrece una experiencia de hospedaje que combina confort y elegancia en un entorno natural impresionante. Con una arquitectura que refleja el estilo clásico de la región y un servicio ...',
    contactUrl: 'https://www.remax.com/',
    tag1:'CENTRICO',
    tag2: 'EMPRESARIAL',
    descripcionUrl: '/catalogo-de-propiedades_renta/propiedad-000',
  },
];

const listadoPropiedades = () => {
  return (
    <div className="App">
      <RealEstateCatalog listings={listings} />
    </div>
  );
};

export default listadoPropiedades;
