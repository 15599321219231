import "../Inicio/diapos_spotsStyle.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "@mui/material/Button";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "NONE",
        right: "2%",
        fontSize: "60px",
      }} // Puedes personalizar el estilo aquí
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "none", left: "2%" }} // Puedes personalizar el estilo aquí
      onClick={onClick}
    />
  );
};

const Diapos_principales = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="slideshow">
      <Slider {...settings}>
        <div className="slide">
          <div className="slide-content">
            <div className="tags">
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                PAISAJES
              </Button>
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                AVENTURA
              </Button>
            </div>
            <div className="separador_ti">
              <div className="texto_diapositiva">
                <h2>MIRADOR "LA PEÑA"</h2>
                <p>
                  Ubicado en lo alto de una colina, el Mirador la Peña ofrece
                  vistas espectaculares del lago de Valle de Bravo y las
                  montañas que lo rodean. Subir hasta aquí es una experiencia en
                  sí misma, ya que el sendero atraviesa un bosque encantador,
                  proporcionando momentos de paz y reflexión. Una vez en la
                  cima, el paisaje se despliega en toda su majestuosidad,
                  haciendo de este mirador un lugar perfecto para fotógrafos y
                  amantes de la naturaleza.
                </p>
                <p>
                  Ideal para disfrutar de un picnic o simplemente contemplar el
                  atardecer, el Mirador la Peña es un destino imprescindible
                  para quienes visitan Valle de Bravo.
                </p>
              </div>
              <div className="slide-images">
                <img
                  src="https://turismoi.mx/uploads/mx/photo/photo_file/18296/pen_a5.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://www.escapadah.com/u/fotografias/m/2022/5/17/f768x1-4811_4938_142.jpeg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://live.staticflickr.com/2525/5760748212_90972d4b15_b.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://t3.ftcdn.net/jpg/03/91/90/74/360_F_391907464_qjcuQGGqDe7kR6m2oAwSWKBmwZ1vzbif.jpg"
                  alt="Velo de Novia"
                />
              </div>
            </div>
            <div className="botones_diapositiva">
              <a className="btn" href="/guia_turistica_ValleDeBravo/la-pena">VER EN LA GUÍA TURISTICA</a>
              <a className="btn2" href="https://maps.app.goo.gl/t6AZanDUPsP3o2X28" target="blank">VER EN GOOGLE MAPS</a>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="slide-content">
            <div className="tags">
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                AVENTURA
              </Button>
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                NATURALEZA
              </Button>
            </div>
            <div className="separador_ti">
              <div className="texto_diapositiva">
                <h2>CASCADA VELO DE NOVIA</h2>
                <p>
                  La Cascada Velo de Novia es una de las maravillas naturales
                  más emblemáticas de Valle de Bravo. Con una caída de agua de
                  más de 35 metros, esta cascada se asemeja al delicado velo de
                  una novia, de ahí su nombre. El entorno es simplemente
                  espectacular, rodeado de frondosos bosques y senderos que
                  invitan a la exploración.
                </p>
                <p>
                  Los visitantes pueden disfrutar de un paseo a pie o a caballo,
                  y el sonido constante del agua cayendo proporciona una
                  atmósfera relajante y rejuvenecedora. Es un lugar perfecto
                  para quienes buscan escapar del bullicio y conectar con la
                  naturaleza en su estado más puro.
                </p>
              </div>
              <div className="slide-images">
                <img
                  src="https://experiencia.edomex.gob.mx/sites/default/files/2020-06/RAPEL.JPG"
                  alt="Velo de Novia"
                />
                <img
                  src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/VELO%20DE%20NOVIA_3.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://i.pinimg.com/originals/d9/8c/eb/d98ceb2f2f52c26ce9d26db72abdb296.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2020/10/cascada-velo-de-novia.jpg"
                  alt="Velo de Novia"
                />
              </div>
            </div>
            <div className="botones_diapositiva">
              <a className="btn" href="/guia_turistica_ValleDeBravo/cascada-velo-de-novia">VER EN LA GUÍA TURISTICA</a>
              <a className="btn2" href="https://maps.app.goo.gl/XfkV7oSbGn83xz3SA" target="blank">VER EN GOOGLE MAPS</a>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="slide-content">
            <div className="tags">
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                  marginRight: "20px",
                }}
              >
                ESPIRITUAL
              </Button>
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                RELAJACIÓN
              </Button>
            </div>
            <div className="separador_ti">
              <div className="texto_diapositiva">
                <h2>CARMEL MARANATHA</h2>
                <p>
                  Situado en un entorno natural apacible, el Carmel Maranatha es
                  un centro de retiro espiritual que ofrece un refugio de
                  tranquilidad y contemplación. Este lugar es ideal para quienes
                  buscan desconectar del ajetreo diario y encontrar paz
                  interior. Los hermosos jardines, las capillas silenciosas y
                  los senderos arbolados proporcionan el escenario perfecto para
                  la meditación y la oración.
                </p>
                <p>
                  Carmel Maranatha también organiza retiros espirituales y
                  talleres, ofreciendo a los visitantes la oportunidad de
                  profundizar en su fe y bienestar espiritual en un entorno
                  sereno y acogedor.
                </p>
              </div>
              <div className="slide-images">
                <img
                  src="https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2020/10/Carmel-Maranatha-Valle-de-Bravo-Estado-de-Mexico-Paisaje.jpg"
                  alt="Carmel maranatha"
                />
                <img
                  src="https://www.mexicoenfotos.com/MX12322358656649.jpg"
                  alt="Carmel maranatha"
                />
                <img
                  src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/MARANATHA_3.jpg"
                  alt="Carmel maranatha"
                />
                <img
                  src="https://cdn.mexicodestinos.com/lugares/centro-de-espiritualidad-carmel-marantha-valle-de-bravo-galeria.jpg"
                  alt="Carmel maranatha"
                />
              </div>
            </div>
            <div className="botones_diapositiva">
              <a className="btn" href="/guia_turistica_ValleDeBravo/carmel-maranatha">VER EN LA GUÍA TURISTICA</a>
              <a className="btn2" href="https://maps.app.goo.gl/GGxuR7NHWNo5nRxS9" target="blank">VER EN GOOGLE MAPS</a>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="slide-content">
            <div className="tags">
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                  marginRight: "20px",
                }}
              >
                PASEO
              </Button>
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                PAISAJE
              </Button>
  
            </div>
            <div className="separador_ti">
              <div className="texto_diapositiva">
                <h2>SANTA MARÍA</h2>
                <p>
                  Santa María es un lugar encantador que destaca por su
                  pintoresco muelle en el lago de Valle de Bravo. Este muelle es
                  un punto de encuentro tanto para locales como visitantes,
                  ofreciendo una atmósfera vibrante y relajante a la vez. Aquí,
                  puedes disfrutar de paseos en bote, practicar deportes
                  acuáticos o simplemente relajarte y contemplar las vistas
                  impresionantes del lago y las montañas circundantes.
                </p>
                <p>
                  La zona del muelle está rodeada de restaurantes y cafés que
                  ofrecen deliciosa gastronomía local, creando un ambiente
                  perfecto para pasar el día. Santa María es el lugar ideal para
                  disfrutar de la tranquilidad del agua y la belleza natural de
                  Valle de Bravo.
                </p>
              </div>
              <div className="slide-images">
                <img
                  src="https://media.staticontent.com/media/pictures/65a37fb6-fa4b-4c0b-9344-a0d4e9f62599"
                  alt="Velo de Novia"
                />
                <img
                  src="https://www.turimexico.com/wp-content/uploads/2015/05/valledebravo1.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://scontent.fmex31-1.fna.fbcdn.net/v/t39.30808-6/452932227_122110069406398540_6854610351886720874_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=127cfc&_nc_ohc=FjhS45IeAe8Q7kNvgHow3ST&_nc_ht=scontent.fmex31-1.fna&oh=00_AYCnMdH_G_1LbUOyIq4outAH-H2Gn5aCzwxEsNLdXGLARg&oe=66ADB273"
                  alt="Velo de Novia"
                />
                <img
                  src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/289493440.jpg?k=e13bae34edd4863f60176b09429bf1061ebaacf2412b61f3aac3fc68307960cd&o=&hp=1"
                  alt="Velo de Novia"
                />
              </div>
            </div>
            <div className="botones_diapositiva">
              <a className="btn" href="/guia_turistica_ValleDeBravo/sta-maria-ahuacatlan">VER EN LA GUÍA TURISTICA</a>
              <a className="btn2" href="https://maps.app.goo.gl/jmBdZmjinkV4w4iM7" target="blank">VER EN GOOGLE MAPS</a>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="slide-content">
            <div className="tags">
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                  marginRight: "20px",
                }}
              >
                FAMILIAR
              </Button>
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                AVENTURA
              </Button>
            </div>
            <div className="separador_ti">
              <div className="texto_diapositiva">
                <h2>GRAN STUPA BÖN</h2>
                <p>
                  La Gran Stüpa Bön es un monumento único en México, dedicado a
                  la tradición espiritual tibetana. Este majestuoso stüpa,
                  construido con la bendición de lamas tibetanos, es un lugar de
                  meditación y sanación espiritual. Rodeado de un paisaje
                  natural impresionante, el Gran Stüpa Bön ofrece un espacio de
                  tranquilidad y reflexión.
                </p>
                <p>
                  Los visitantes pueden participar en sesiones de meditación
                  guiada, aprender sobre la filosofía Bön y disfrutar de la
                  atmósfera de paz y serenidad que envuelve este lugar sagrado.
                  Es una visita obligada para quienes buscan una experiencia
                  espiritual profunda y enriquecedora.
                </p>
              </div>
              <div className="slide-images">
                <img
                  src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/12/86/ff/c3/vista-desde-afuera.jpg?w=1200&h=1200&s=1"
                  alt="Velo de Novia"
                />
                <img
                  src="https://ligmincha.org/wp-content/uploads/2019/01/valle5-1.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://milyunamillas.com.mx/wp-content/uploads/2021/05/Stupa-5D.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://i0.wp.com/inversion-turistica.com/wp-content/uploads/2021/02/Stupa-Bon-2.jpg?fit=1920%2C1080&ssl=1"
                  alt="Velo de Novia"
                />
              </div>
            </div>
            <div className="botones_diapositiva">
              <a className="btn" href="/guia_turistica_ValleDeBravo/gran-stupa-bon">VER EN LA GUÍA TURISTICA</a>
              <a className="btn2" href="https://maps.app.goo.gl/5fDZcMTKpXNovyGz5" target="blank">VER EN GOOGLE MAPS</a>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="slide-content">
            <div className="tags">
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                  marginRight: "20px",
                }}
              >
                FAMILIAR
              </Button>
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                COMERCIAL
              </Button>
            </div>
            <div className="separador_ti">
              <div className="texto_diapositiva">
                <h2>AVÁNDARO</h2>
                <p>
                  Avándaro es sinónimo de lujo y exclusividad en Valle de Bravo.
                  Este enclave sofisticado ofrece una variedad de resorts de
                  alta gama, spas relajantes y campos de golf de clase mundial.
                  Los visitantes pueden disfrutar de tratamientos de spa
                  rejuvenecedores, cenas gourmet y actividades al aire libre
                  como paseos en bote y caminatas por los paisajes circundantes.
                </p>
                <p>
                  Avándaro combina el confort moderno con la belleza natural,
                  proporcionando una experiencia de descanso y recreación en un
                  entorno de lujo. Es el lugar ideal para quienes buscan escapar
                  del estrés diario y sumergirse en un mundo de confort y
                  serenidad.
                </p>
              </div>
              <div className="slide-images">
                <img
                  src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/23/f8/6c/6e/hotel-avandaro.jpg?w=700&h=-1&s=1"
                  alt="Velo de Novia"
                />
                <img
                  src="https://i0.wp.com/foodandpleasure.com/wp-content/uploads/2022/02/cabanas-valle-de-bravo-wandercabins1.jpg?resize=600%2C600&ssl=1"
                  alt="Velo de Novia"
                />
                <img
                  src="https://imgcy.trivago.com/c_limit,d_dummy.jpeg,f_auto,h_600,q_auto,w_600//hotelier-images/c2/12/9dd7c772663c83e8524a1988932bc6dbb6e96080e88e113f02b7efe7bc42.jpeg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://images.adsttc.com/media/images/5e5f/1256/6ee6/7e7b/b200/0273/large_jpg/_fi.jpg?1583288868"
                  alt="Velo de Novia"
                />
              </div>
            </div>
            <div className="botones_diapositiva">
              <a className="btn" href="/guia_turistica_ValleDeBravo/avandaro">VER EN LA GUÍA TURISTICA</a>
              <a className="btn2" href="https://maps.app.goo.gl/cvUWXFuSCtQ8xPDf6" target="blank">VER EN GOOGLE MAPS</a>
            </div>
          </div>
        </div>

        <div className="slide">
          <div className="slide-content">
            <div className="tags">
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                  marginRight: "20px",
                }}
              >
                FAMILIAR
              </Button>
              <Button
                variant="outlined"
                className="tags_diapositiva"
                style={{
                  height: "30px",
                }}
              >
                AVENTURA
              </Button>
            </div>
            <div className="separador_ti">
              <div className="texto_diapositiva">
                <h2>PARROQUIA DE SAN FRANCISCO</h2>
                <p>
                  La Parroquia de San Francisco es una joya arquitectónica
                  situada en el corazón de Valle de Bravo. Construida en el
                  siglo XVI, esta iglesia de estilo colonial refleja la rica
                  historia y herencia cultural de la región. Sus imponentes
                  torres y su fachada de cantera invitan a los visitantes a
                  adentrarse en un mundo de espiritualidad y reflexión. El
                  interior es igualmente impresionante, con altares elaborados y
                  vitrales coloridos que crean un ambiente de solemnidad y
                  devoción.
                </p>
                <p>
                  Además de ser un lugar de culto, la parroquia es un punto de
                  encuentro para la comunidad local, donde se celebran numerosas
                  festividades religiosas a lo largo del año.
                </p>
              </div>
              <div className="slide-images">
                <img
                  src="https://www.mexicodesconocido.com.mx/assets/images/destinos/valle_de_bravo/actividades/valle-de-bravo-edomex-parroquia-de-san-francisco-de-asis.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/parroquia%20de%20san%20francisco%20de%20asis%20%281%29.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://s3-us-west-1.amazonaws.com/tipsparatuviaje/wp-content/uploads/2017/01/9.-%C2%BFC%C3%B3mo-es-la-Iglesia-de-San-Francisco-de-As%C3%ADs.jpg"
                  alt="Velo de Novia"
                />
                <img
                  src="https://escapadas.mexicodesconocido.com.mx/wp-content/uploads/2020/10/arton24521.jpg"
                  alt="Velo de Novia"
                />
              </div>
            </div>
            <div className="botones_diapositiva">
              <a className="btn" href="/guia_turistica_ValleDeBravo/parroquia-sn-francisco">VER EN LA GUÍA TURISTICA</a>
              <a className="btn2" href="https://maps.app.goo.gl/VvD5eg99n8NYJoE48" target="blank">VER EN GOOGLE MAPS</a>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Diapos_principales;
