// src/PaginaRestaurante_X.js
import React, { useState } from "react";
import StarRating from "../../Componentes/directorioNegocios/StarRating";
import "./paginaRestaurante_style.css";

function TorteriaTragoncito() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="pagina-restaurante">
      <div className="seccion1_p-r">
        <h1 className="tituloPag-Restaurante">
          RESTAURANTES, CAFETERIAS, FONDAS, COMIDA RAPIDA Y MÁS.
        </h1>
        <hr />
        <div className="tituloyCompartir">
          <h1 className="nombreRestaurante">Tortas El Tragoncito</h1>
        </div>
        <div className="rankingSeccion1">
          <div className="estrellasRestaurante">
            <StarRating totalStars={5} rating={4} />{" "}
          </div>
          <p className="cantidadOpiniones">
            <b>2 opiniones</b>
          </p>
          <p className="separadorUno">|</p>
          <p className="tipodeComida">Comida Rápida • $</p>
        </div>
        <div className="ubicacionContacto">
          <div className="ubicacion">
            <span class="material-symbols-outlined">pin_drop</span>
            <p className="direccionNegocio">
              <b>
                Calle Rcda. San Antonio 30, San Antonio, 51200 Valle de Bravo
              </b>
              |
            </p>
          </div>
          <div className="contenedorTelefono">
            <span class="material-symbols-outlined">phone_in_talk</span>
            <p className="telefonoNegocio">(722) 682 1751</p>
            <p> |</p>
          </div>
          <a
            className="contenedorWeb OcultoTemporalmente"
            href="https://chilemolepozole.org/"
            target="blank"
          >
            <span class="material-symbols-outlined">language</span>
            <p>
              <b>Página web </b>|
            </p>
          </a>
          <div className="contenedorMenu" onClick={togglePopup}>
            <span class="material-symbols-outlined">menu_book</span>
            <p>
              <b>Ver menú </b>
            </p>
          </div>
          {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h3>No disponible por el momento</h3>
            <button className="close-popup" onClick={togglePopup}>Cerrar</button>
          </div>
        </div>
      )}
        </div>
        <div className="contenedorAbierto">
          <span class="material-symbols-outlined">schedule</span>
          <p>
            <b className="textoHorario">Abierto Ahora</b>MIE - LUN 8:00 a 18:00
            hrs
          </p>
        </div>
      </div>
      <section className="seccion2_p-r">
        <div className="contenedorPresentación">
          <h3>Presentación</h3>
          <div className="subraya_tituloRestaurante"></div>
          <p>
            El Tragoncito es el lugar perfecto para disfrutar de auténticas
            tortas mexicanas, preparadas con ingredientes frescos y pan
            crujiente. Cada bocado te transportará a los sabores tradicionales
            que tanto amas, ofreciéndote una experiencia que destaca
            por su calidad y sabor.
            <br />
            <br/>
            Además de nuestras irresistibles tortas, en El Tragoncito también
            encontrarás flautas doradas, crujientes y llenas de sabor,
            acompañadas de refrescos bien fríos para complementar tu comida. Ya
            sea que busques saciar un antojo o disfrutar de una comida rápida,
            aquí siempre te recibirán con los mejores sabores. ¡Te esperamos!
          </p>
        </div>
        <div className="galeria_Restaurante">
          <img
            className="imagen1Restaurante"
            alt=""
            src="https://i.pinimg.com/736x/7d/46/80/7d4680e9dc24e4e31b9ed8e1877d432b.jpg"
          ></img>
          <div className="galeria_RestauranteAureo">
            <img
              className="imagen2Restaurante"
              alt=""
              src="https://i.ytimg.com/vi/RTCP5v3Coj0/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCJo7VEH1M25tGZ48odh6bxxIlwSw"
            ></img>
            <img
              className="imagen3Restaurante"
              alt=""
              src="https://www.mexicodesconocido.com.mx/wp-content/uploads/2019/11/torta-2.jpg"
            ></img>
          </div>
        </div>
      </section>
      <section className="seccion3_p-r">
        <div className="division_seccion3_p-r">
          <h3>Calificaciones y opiniones</h3>
          <div className="calificacionRestaurante">
            <h3 className="numero_califRestaurante">4.0</h3>
            <StarRating totalStars={5} rating={4} />{" "}
            <h5 className="cantidad_califRestaurante">20 opiniones</h5>
          </div>
          <p className="posicionRestaurante1">
            No.1 de 8 en Comida Rápida en Valle de Bravo
          </p>
          <p className="posicionRestaurante2">
            #35 de 58 Restaurantes en Valle de Bravo
          </p>
          <hr />
          <h3 className="tituloCalificaciones">Calificaciones</h3>
          <div className="calificaciones">
            <h5>Comida</h5>
            <StarRating totalStars={5} rating={4} />{" "}
          </div>
          <div className="calificaciones">
            <h5>Servicio</h5>
            <StarRating totalStars={5} rating={3} />{" "}
          </div>
          <div className="calificaciones">
            <h5>Calidad precio</h5>
            <StarRating totalStars={5} rating={5} />{" "}
          </div>
          <div className="calificaciones">
            <h5>Ambiente</h5>
            <StarRating totalStars={5} rating={3} />{" "}
          </div>
        </div>
        <div className="division_seccion3_p-r">
          <h3>Detalles</h3>
          <hr />
          <h4 className="primer_detalles">Rango de precios</h4>
          <p>$45-$150 por persona</p>
          <h4>Tipo de comida</h4>
          <p>Tortas, Flautas, Refrescos</p>
          <h4>Comidas</h4>
          <p>Almuerzo, Comida</p>
        </div>
        <div className="division_seccion3_p-r">
          <h3>Ubicación</h3>
          <hr />
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.810904239121!2d-100.14015142072196!3d19.20345980164022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd638535f197c7%3A0x5c20dcc545716375!2sCalle%20Rcda.%20San%20Antonio%2030%2C%20San%20Antonio%2C%2051200%20Valle%20de%20Bravo%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1723125240148!5m2!1ses!2smx"
            width="100%"
            height="180"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Mapa de Valle de Bravo"
            className="mapaEvento"
          ></iframe>
        </div>
      </section>
    </div>
  );
}

export default TorteriaTragoncito;
