import"../infoEvento/infoEvento_styel.css";

function DescripcionEvento() {
  return (
    <div className="descripcionEventos">
      <div className="seecionPrincipalEvento">
        <h1>CONCIERO FILARMONICO</h1>
        <p className="introduccionEvento">
          Encuentra todos los bienes y servicios que necesites en Valle de Bravo
        </p>
        <iframe
          width="850"
          height="526"
          src="https://www.youtube.com/embed/IyJ5BoxIDKg"
          title="LOS AGUAS AGUAS - EL CHICO TEMIDO (DESCONECTADOS POR NECESIDAD)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <div className="fechaEvento">
          <h1>13</h1>
          <a>
            <p className="mesEvento">Septiembre</p>
            <p className="horaEvento">16:00 hrs (GM-5 CDMX)</p>
          </a>
        </div>
        <hr />
        <h2>Sobre el evento</h2>
        <p className="descripcionEvento">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel
          eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam
          at congue. Vestibulum ante ipsum primis in faucibus orci luctus et
          ultrices posuere cubilia curae.Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Nullam vel eros mattis, eleifend enim vitae,
          malesuada nibh. Morbi cursus in diam at congue. Vestibulum ante ipsum
          primis in faucibus orci luctus et ultrices posuere cubilia curae.
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel
          eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam
          at congue. Vestibulum ante ipsum primis in faucibus orci luctus et
          ultrices posuere cubilia curae.
        </p>
        <hr />
        <h3 className="direccionEvento">Av. Vega del Llano 215, Avandaro, Valle de Bravo</h3>
        <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60292.800366212796!2d-100.16958289201573!3d19.182093903156314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd63813218f41f%3A0xb687c3a1fb52897c!2sValle%20de%20Bravo%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1720554218321!5m2!1ses!2smx"
      width="850"
      height="250"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Mapa de Valle de Bravo"
      className="mapaEvento"
    ></iframe>
      </div>
      <aside className="lateralEvento">
        <div className="comprarenStripe">
          <h3>COMPRA TUS BOLETOS<br/> AQUÍ</h3>
          <img className="ticketImg" src="../Images/iconoTicket.png" alt="ticket"></img>
          <p>Pago intermediado por</p>
          <img className="stripeLogo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/1280px-Stripe_Logo%2C_revised_2016.svg.png" alt="Stripe"></img>
          <h3 className="precioEvento">$300 MXN</h3>
          <button className="boton_comprarBoleto" href="">Comprar</button>
        </div>
        <div className="espacioPublicitario1_evento">
          <h1>ESPACIO<br/> PUBLICITARIO</h1>
        </div>
        <div className="espacioPublicitario2_evento">
          <h1>ESPACIO<br/> PUBLICITARIO</h1>
        </div>
      </aside>
    </div>
  );
}
export default DescripcionEvento;
