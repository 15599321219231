const CategoryList = ({ categories, onSelectCategory }) => {
  return (
    <div className="category-list">
      <h2>Selecciona una categoría</h2>
      {categories.map(category => (
        <button key={category} onClick={() => onSelectCategory(category)}>
          {category}
        </button>
      ))}
    </div>
  );
};

export default CategoryList;