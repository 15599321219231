// src/PaginaRestaurante_X.js
import React, { useState } from "react";
import StarRating from "../../Componentes/directorioNegocios/StarRating";
import "./paginaRestaurante_style.css";

function ElSazondeMary() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="pagina-restaurante">
      <div className="seccion1_p-r">
        <h1 className="tituloPag-Restaurante">
          RESTAURANTES, CAFETERIAS, FONDAS, COMIDA RAPIDA Y MÁS.
        </h1>
        <hr />
        <div className="tituloyCompartir">
          <h1 className="nombreRestaurante">El Sazón de Mary</h1>
        </div>
        <div className="rankingSeccion1">
          <div className="estrellasRestaurante">
            <StarRating totalStars={5} rating={5} />{" "}
          </div>
          <p className="cantidadOpiniones">
            <b>10 opiniones</b>
          </p>
          <p className="separadorUno">|</p>
          <p className="tipodeComida">Marisquería • $$</p>
        </div>
        <div className="ubicacionContacto">
          <div className="ubicacion">
            <span class="material-symbols-outlined">pin_drop</span>
            <p className="direccionNegocio">
              <b>Carretera Colorines-El arco, 51200 Valle de Bravo</b>|
            </p>
          </div>
          <div className="contenedorTelefono">
            <span class="material-symbols-outlined">phone_in_talk</span>
            <p className="telefonoNegocio">(722) 389 0083</p>
            <p> |</p>
          </div>
          <a
            className="contenedorWeb OcultoTemporalmente"
            href="https://chilemolepozole.org/"
            target="blank"
          >
            <span class="material-symbols-outlined">language</span>
            <p>
              <b>Página web </b>|
            </p>
          </a>
          <div className="contenedorMenu" onClick={togglePopup}>
            <span class="material-symbols-outlined">menu_book</span>
            <p>
              <b>Ver menú </b>
            </p>
          </div>
          {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h3>No disponible por el momento</h3>
            <button className="close-popup" onClick={togglePopup}>Cerrar</button>
          </div>
        </div>
      )}
        </div>
        <div className="contenedorAbierto">
          <span class="material-symbols-outlined">schedule</span>
          <p>
            <b className="textoHorario">Abierto Ahora</b>JUE - DOM 10:00 a 21:00 hrs
          </p>
        </div>
      </div>
      <section className="seccion2_p-r">
        <div className="contenedorPresentación">
          <h3>Presentación</h3>
          <div className="subraya_tituloRestaurante"></div>
          <p>
            En Chile Mole Pozole, cada platillo es preparado con dedicación e
            higiene, garantizando una experiencia culinaria inolvidable. Aquí,
            podrás saborear deliciosos sopes, enchiladas, mole y, por supuesto,
            el famoso pozole, todo cocinado a la perfección. <br />
            <br />
            El ambiente acogedor y la atención amable complementan una
            experiencia gastronómica que te hará sentir como en casa. No te
            pierdas la oportunidad de visitar Chile Mole Pozole y disfrutar de
            lo mejor de la cocina mexicana en un entorno encantador.
          </p>
        </div>
        <div className="galeria_Restaurante">
          <img
            className="imagen1Restaurante"
            alt=""
            src="../Images/Fotos/IMG_6861.jpg"
          ></img>
          <div className="galeria_RestauranteAureo">
            <img
              className="imagen2Restaurante"
              alt=""
              src="https://mexicanissimo.com/wp-content/uploads/2023/12/caldo-de-camaron.webp"
            ></img>
            <img
              className="imagen3Restaurante"
              alt=""
              src="https://inmamamaggieskitchen.com/wp-content/uploads/2021/03/Coctel-de-Camarones.jpg"
            ></img>
          </div>
        </div>
      </section>
      <section className="seccion3_p-r">
        <div className="division_seccion3_p-r">
          <h3>Calificaciones y opiniones</h3>
          <div className="calificacionRestaurante">
            <h3 className="numero_califRestaurante">5</h3>
            <StarRating totalStars={5} rating={5} />{" "}
            <h5 className="cantidad_califRestaurante">100 opiniones</h5>
          </div>
          <p className="posicionRestaurante1">
            No.1 de 13 en Marisquerias en Valle de Bravo
          </p>
          <p className="posicionRestaurante2">
            #3 de 58 Restaurantes en Valle de Bravo
          </p>
          <hr />
          <h3 className="tituloCalificaciones">Calificaciones</h3>
          <div className="calificaciones">
            <h5>Comida</h5>
            <StarRating totalStars={5} rating={5} />{" "}
          </div>
          <div className="calificaciones">
            <h5>Servicio</h5>
            <StarRating totalStars={5} rating={4} />{" "}
          </div>
          <div className="calificaciones">
            <h5>Calidad precio</h5>
            <StarRating totalStars={5} rating={4} />{" "}
          </div>
          <div className="calificaciones">
            <h5>Ambiente</h5>
            <StarRating totalStars={5} rating={4} />{" "}
          </div>
        </div>
        <div className="division_seccion3_p-r">
          <h3>Detalles</h3>
          <hr />
          <h4 className="primer_detalles">Rango de precios</h4>
          <p>$220-$350 por persona</p>
          <h4>Tipo de comida</h4>
          <p>Mariscos, Restaurantes cerveceros</p>
          <h4>Comidas</h4>
          <p>Almuerzo, Comida, Cena, Abierto hasta tarde, Bebidas</p>
        </div>
        <div className="division_seccion3_p-r">
          <h3>Ubicación</h3>
          <hr />
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d941.8440184721229!2d-100.13539543045512!3d19.222435065210988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd63a491a8dd5f%3A0x3152f4777058b8a1!2s51217%20El%20Arco%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1723126367403!5m2!1ses!2smx"
            width="100%"
            height="180"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Mapa de Valle de Bravo"
            className="mapaEvento"
          ></iframe>
        </div>
      </section>
    </div>
  );
}

export default ElSazondeMary;
