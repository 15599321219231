import React from "react";
import "./paginaNegocios_style.css";
import "./caracteristicasNegocios_style.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CompartirArticulo from "../../RevistaDigital/Articulos/componentes_articulos/boton_compartirArticulo";

const DescripcionPropiedad = [
  {
    id: 1,
    nombreNegocio: "Gil Mallas",
    giro: "Instalación de cercados y mallas",
    fotoPrincipal:
      "https://gilmallas.com.mx/wp-content/uploads/2018/12/gil-gal-4.jpg",
    fotoSecundaria1:
      "https://st2.depositphotos.com/1041088/11595/i/450/depositphotos_115954550-stock-photo-home-exterior-with-garage-and.jpg",
    fotoSecundaria2:
      "https://st2.depositphotos.com/1041088/11595/i/450/depositphotos_115954550-stock-photo-home-exterior-with-garage-and.jpg",
    fotoSecundaria3:
      "https://st2.depositphotos.com/1041088/11595/i/450/depositphotos_115954550-stock-photo-home-exterior-with-garage-and.jpg",
    fotoSecundaria4:
      "https://st2.depositphotos.com/1041088/11595/i/450/depositphotos_115954550-stock-photo-home-exterior-with-garage-and.jpg",
    logoAnunciante:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxb1RdBghzBJ1h9AWHZocSqzUI_wu29Cnzpw&s",
    codigoAnunciante: "5R9GDE",
    telefonoAnunciante: "7222559944",
    descripcion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam at congue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam at congue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel eros mattis, eleifend enim vitae, malesuada nibh. Morbi cursus in diam at congue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.",
    ubicacion: "Av Toluca Manzana 004, Agua Fria, 51200 Valle de Bravo, Méx.",
    coordenadasGMaps: "19.1980496,-100.129874",
  },
];

const PaginaNegocio = () => {
  return (
    <div className="contenedor_paginaPropiedad">
      <div className="descripcionGeneral_propiedad">
        {DescripcionPropiedad.map((articulo) => (
          <div key={articulo.id}>
            <div className="encabezadoArticulo">
              <div className="contenedor_ecabezadoHospedaje">
                <h1 className="titulo_Articulo titulo_propiedad">
                  {articulo.nombreNegocio}
                </h1>
              </div>
              <hr />
            </div>
            <div className="infoproPiedades">
              <article className="detallesPropiedades">
                <h2 className="subtitulo_Negocio">{articulo.giro}</h2>
                <img
                  className="logoNegocio_pagina"
                  src={articulo.logoAnunciante}
                  alt={articulo.nombreNegocio}
                />
                <hr />
                <h2>Ubicación</h2>
                <div className="contenedor_ubicacion">
                  <span className="material-symbols-outlined">pin_drop</span>
                  <h5>{articulo.ubicacion}</h5>
                </div>
                <iframe
                  src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15071.739227101321!2d${articulo.coordenadasGMaps.split(",")[1]}!3d${articulo.coordenadasGMaps.split(",")[0]}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd63815f73f959%3A0xbe599d828ef963f9!2sGil%20Mallas%20Cercados%20Y%20Alambrados!5e0!3m2!1ses!2smx!4v1723082246814!5m2!1ses!2smx`}
                  width="100%"
                  height="250"
                  style={{ border: 0, borderRadius:"10px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="productosServicios_contenedor">
                  <ul className="lista_productosServicios">
                    <h3>Productos</h3>
                    <li>Malla ciclónica</li>
                    <li>Malla sombra</li>
                    <li>Malla plástica</li>
                    <li>Malla hexagonal</li>
                    <li>Malla triple torsión</li>
                    <li>Alambre de púas</li>
                    <li>Alambre galvanizado</li>
                    <li>Rejas de acero</li>
                    <li>Postes de metal</li>
                  </ul>
                  <ul className="lista_productosServicios">
                    <h3>Servicios</h3>
                    <li>Instalación de mallas y cercas</li>
                    <li>Asesoría técnica</li>
                    <li>Medición y cotización en sitio</li>
                    <li>Mantenimiento de cercas y mallas</li>
                  </ul>
                </div>
                <hr />
                <h2>Acerca de {articulo.nombreNegocio}</h2>
                <p>
                  Gil Mallas es una empresa reconocida en Valle de Bravo,
                  especializada en la venta y distribución de mallas y productos
                  relacionados. Con un enfoque en la calidad y la durabilidad,
                  Gil Mallas ofrece soluciones prácticas y eficientes para
                  diversas necesidades, desde proyectos residenciales hasta
                  industriales. Su compromiso con la satisfacción del cliente y
                  su experiencia en el sector la convierten en una opción
                  confiable para quienes buscan productos de primera categoría y
                  un servicio excepcional.
                </p>
                
              </article>
              <aside className="contactoPropiedades">
                <div>
                  <div className="contacta_contenedor">
                    <h2 className="titulo_contactoPropiedades">
                      Contacta al anunciante
                    </h2>
                    <TextField
                      id="outlined-basic"
                      label="Correo electrónico"
                      variant="outlined"
                      style={{ marginBottom: "10px", width: "100%" }}
                    />
                    <a>
                      <TextField
                        id="outlined-basic"
                        label="Nombre"
                        variant="outlined"
                        style={{
                          marginBottom: "10px",
                          flex: "1",
                          marginRight: "15px",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Teléfono"
                        variant="outlined"
                        style={{ marginBottom: "10px", flex: "1" }}
                      />
                    </a>
                    <TextField
                      id="outlined-multiline-static"
                      label="Mensaje:"
                      multiline
                      rows={4}
                      defaultValue="¡Hola! Quiero que se comuniquen conmigo por este inmueble en renta que vi en Vida & Estilo Valle de Bravo con ID 001."
                      style={{ marginBottom: "10px", width: "100%" }}
                    />
                    <Button
                      variant="contained"
                      disableElevation
                      style={{ backgroundColor: "#EF8822", color: "#fff" }}
                    >
                      Enviar
                    </Button>
                    <h5 className="boton_terminosCondiciones">
                      Al enviar estás aceptando los Términos y condiciones de
                      Uso y la Política de Privacidad
                    </h5>
                  </div>
                  <div className="contenedor_telefonoFormulario">
                    <div className="contenedor_logoTelefono">
                      <img
                        className="logo_telefono"
                        src={articulo.logoAnunciante}
                      ></img>
                    </div>
                    <div className="informacion_telefono">
                      <h4>{articulo.nombreNegocio}</h4>
                      <div>
                        <h3>72242</h3>
                        <p>VER TELÉFONO COMPLETO</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="addPropiedad1">
                  <h1>ESPACIO PUBLICITARIO</h1>
                </div>
                <div className="addPropiedad1">
                  <h1>ESPACIO PUBLICITARIO</h1>
                </div>
              </aside>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaginaNegocio;
