import "./banner_guia_style.css";
import { Link } from "react-router-dom";


function Banner_guiaturistica() {
  return (
    <div className="contenedor_cta_guia">
      <img
        src="../Images/mapa_turistico.svg"
        className="imagen1_guiaturistica"
      ></img>
      <div className="texto_guiaturistica">
        <h3 className="pretitulo_guia">
          CONOCE TODO LO QUE VALLE TIENE PARA OFRECERTE NAVEGANDO EN NUESTRA
        </h3>
        <h2 className="titulo_guia">GUÍA TURISTICA</h2>
        <p>
          Preparada por vallesanos para que vivas la experiencia de Valle desde
          nuestra perspectiva{" "}
        </p>
        <Link to="/guia_turistica_ValleDeBravo" style={{ textDecoration: "none" }}>
        <button  className="boton_vermas_membresia boton_guia">INICIAR</button>
        </Link>
      </div>
      <img src="../Images/ruta.svg" className="imagen2_guiaturistica"></img>
    </div>
  );
}

export default Banner_guiaturistica;
