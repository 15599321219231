import "./restaurantes_style.css";
import React, { useState } from "react";
import RestaurantCard from "../Componentes/Restaurantes/RestaurantCard";
import Filter from "../Componentes/Restaurantes/filtroRestaurantes";

const RestaurantList = () => {
  const [filters, setFilters] = useState({
    type: [],
    meal: [],
    price: [],
  });

  const restaurants = [
    {
      name: "Chile Mole Pozole",
      hours: "Jueves - Lunes 9:00 a 22:00 hrs",
      cuisine: "Comida mexicana",
      price: "$$",
      description:
        "Ubicado a un costado del emblemático embarcadero de Valle de Bravo, Chile Mole Pozole es el lugar perfecto para disfrutar de la auténtica comida mexicana durante tu estancia.",
      image: "../Images/Fotos/chilemole_carne.jpg",
      type: ["restaurant"],
      meal: ["lunch", "dinner"],
      paginaSite:
        "/restaurantes-recomendados/comida-mexicana-chile-mole-pozole",
      colonia: "Santa María",
      mapsLink:
        "https://www.google.com/maps/place/Chile+Mole+Pozole+Valle+de+Bravo/@19.1886894,-100.1296663,15z/data=!4m2!3m1!1s0x0:0x3c5117b24dbedacd?sa=X&ved=1t:2428&ictx=111",
      phone: "123-456-7890",
      email: "contacto@chilemolepozole.com",
    },
    {
      name: "El Sazón de Mary",
      hours: "Jueves - Domingo 10:00 a 21:00 hrs",
      cuisine: "Mariscos",
      price: "$$",
      description:
        "El Sazón de Mary es una joya culinaria en Valle de Bravo, reconocida por ofrecer las mejores delicias del mar en la región. Este establecimiento, dirigido exclusivamente por mujeres, te cautivará con una experiencia de sabores únicos y frescos.",
      image: "../Images/Fotos/eslsaszondem_pescado.jpg",
      type: ["restaurant"],
      meal: ["lunch", "dinner"],
      paginaSite:
        "/restaurantes-recomendados/marisqueria_el-Sazon-de-Mary",
      colonia: "San Gaspar",
      mapsLink:
        "https://www.google.com/maps/place/Caf%C3%A9+Man%C3%A9/@19.2224989,-100.1353826,19z/data=!3m1!4b1!4m14!1m7!3m6!1s0x85cd632d0d0a4c4d:0xe3d3dbfc4ab45a42!2sTacos+y+flautas+el+sammy!8m2!3d19.2223611!4d-100.1350767!16s%2Fg%2F11pcrh4p3v!3m5!1s0x85cd63bfc7148891:0x7beed8e291fe81df!8m2!3d19.2224989!4d-100.1347389!16s%2Fg%2F11s8_qn35q?entry=ttu",
      phone: "123-456-7890",
      email: "contacto@chilemolepozole.com",
    },
    {
      name: "El tragoncito",
      hours: "Miercoles - Lunes 8:00 a 18:00 hrs",
      cuisine: "Comida rápida",
      price: "$",
      description:
        "Desde el momento en que cruzas la puerta, te envuelven los aromas de platillos tradicionales preparados con ingredientes frescos y locales.",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSINmSqAF24VDPhtgJP6bSFcjBUXbgUUfBVUg&s",
      type: ["restaurant", "cafe"],
      meal: ["breakfast", "lunch"],
      paginaSite:
        "/restaurantes-recomendados/tortas_el-tragoncito",
      colonia: "San Antonio",
      mapsLink:
        "https://www.google.com/maps/place/S.+Antonio,+San+Antonio,+51200+Valle+de+Bravo,+M%C3%A9x./@19.2033324,-100.1399007,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x85cd638548153421:0x526facf3ef231cc7!2sH.G.+Valle+de+Bravo!8m2!3d19.2031524!4d-100.1356471!16s%2Fg%2F11b6j4zh7w!3m5!1s0x85cd638536961911:0x410233652dc24ea2!8m2!3d19.2033325!4d-100.1350298!16s%2Fg%2F11g61kmknd?entry=ttu",
      phone: "123-456-7890",
      email: "contacto@chilemolepozole.com",
    },
    // Añade más restaurantes según sea necesario
  ];

  const filteredRestaurants = restaurants.filter((restaurant) => {
    return (
      (filters.type.length === 0 ||
        filters.type.some((filter) => restaurant.type.includes(filter))) &&
      (filters.meal.length === 0 ||
        filters.meal.some((filter) => restaurant.meal.includes(filter))) &&
      (filters.price.length === 0 || filters.price.includes(restaurant.price))
    );
  });

  return (
    <body className="Listado_restaurantes">
      <div className="encabezado_Restaurantes">
        <div className="titulos_encRes">
          <img
            className="logo_guiaRestaurantes"
            src="../Images/logo_guiaTuristica.png"
            alt="Guía Turistica"
          ></img>
          <h1 className="tituloRestaurantes">
            RESTAURANTES, CAFETERIAS, FONDAS, COMIDA RAPIDA Y MÁS.
          </h1>
        </div>
        <hr />
        <p>Conoce las mejores opciones para comer en Valle de Bravo</p>
      </div>
      <div className="restaurant-list">
        <Filter filters={filters} setFilters={setFilters} />
        <div className="cards">
          {filteredRestaurants.map((restaurant, index) => (
            <RestaurantCard key={index} restaurant={restaurant} />
          ))}
        </div>
      </div>
    </body>
  );
};

export default RestaurantList;
