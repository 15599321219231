import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

const CustomButton = styled(Button)(({ theme }) => ({
  border: '1px solid #2196f3',
  borderRadius: '10px',
  margin: '0 5px',
  padding: '5px 15px',
  color: '#2196f3',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(33, 150, 243, 0.1)',
  },
}));

const AlertButton = styled(Button)(({ theme }) => ({
  border: '1px solid #ff9800',
  borderRadius: '10px',
  margin: '0 5px',
  color: '#ff9800',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(255, 152, 0, 0.1)',
  },
}));

const menuOptions = {
  Renta: ['Renta', 'Hospedaje', 'Venta'],
  Departamento: ['Departamento', 'Casa', 'Terreno'],
  Recamaras: ['1 Recamara', '2 Recamaras', '3 Recamaras'],
  Precio: ['Menos de $100,000', 'Entre $100,000 y $500,000', 'Más de $500,000'],
};

function FiltrosPropiedades() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({
    Renta: 'Renta',
    Departamento: 'Departamento',
    Recamaras: 'Recamaras',
    Precio: 'Precio',
  });

  const handleClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenu('');
  };

  const handleMenuItemClick = (option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [menu]: option,
    }));
    handleClose();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} className='filtros_propiedades'>
      {Object.keys(menuOptions).map((label) => (
        <div key={label}>
          <CustomButton
            onClick={(event) => handleClick(event, label)}
            endIcon={<span style={{ fontSize: '13px' }}>▼</span>}
          >
            {selectedOptions[label]}
          </CustomButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && menu === label}
            onClose={handleClose}
          >
            {menuOptions[label].map((option) => (
              <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      ))}
      <AlertButton>
        Crear Alerta
      </AlertButton>
    </div>
  );
}

export default FiltrosPropiedades;
