import "../Eventos/eventosTop_style.css";

const EventosProximos = () => {
  const eventosListado = [
    {
      Titulo: "CONCIERTO FILARMONICO",
      Fecha_dia: "13",
      Fecha_mes: "Dic",
      Direccion: "Av. Vega del Llano 215, Avandaro,Valle de Bravo",
      Precio: "300",
      detallesURL: "https://www.facebook.com/",
      Imagen: "https://media.istockphoto.com/id/1461816749/es/foto/una-multitud-de-personas-con-los-brazos-levantados-durante-un-concierto-de-m%C3%BAsica-con-un.jpg?s=612x612&w=0&k=20&c=8zIllYwMgFarR_O1wUbTbcmqKG5bI0dMI_b1InyVKW4=", // Replace with actual image URL
    },
    {
        Titulo: "CONCIERTO FILARMONICO",
        Fecha_dia: "13",
        Fecha_mes: "Dic",
        Direccion: "Av. Vega del Llano 215, Avandaro,Valle de Bravo",
        Precio: "300",
        detallesURL: "https://www.facebook.com/",
        Imagen: "https://media.istockphoto.com/id/1461816749/es/foto/una-multitud-de-personas-con-los-brazos-levantados-durante-un-concierto-de-m%C3%BAsica-con-un.jpg?s=612x612&w=0&k=20&c=8zIllYwMgFarR_O1wUbTbcmqKG5bI0dMI_b1InyVKW4=", // Replace with actual image URL
      },
      {
        Titulo: "CONCIERTO FILARMONICO",
        Fecha_dia: "13",
        Fecha_mes: "Dic",
        Direccion: "Av. Vega del Llano 215, Avandaro,Valle de Bravo",
        Precio: "300",
        detallesURL: "https://www.facebook.com/",
        Imagen: "https://media.istockphoto.com/id/1461816749/es/foto/una-multitud-de-personas-con-los-brazos-levantados-durante-un-concierto-de-m%C3%BAsica-con-un.jpg?s=612x612&w=0&k=20&c=8zIllYwMgFarR_O1wUbTbcmqKG5bI0dMI_b1InyVKW4=", // Replace with actual image URL
      },
    
  ];

  return (
    <div className="contenedor_eventosTop">
      {eventosListado.map((evento) => (
        <div className="box_evento">
          <img
            className="imagen_evento"
            src={evento.Imagen} // Display the image
            alt={evento.Titulo}
          />
          <div className="informacion_evento" key={evento.Titulo}>
            <div className="fecha_evento">
              <div className="numero_fecha">{evento.Fecha_dia}</div>
              <div className="mes_fecha">{evento.Fecha_mes}</div>
            </div>
            <div className="titulosDireccion_evento">
              <div className="cont_titulo_eventoBox"><h2 className="titulo_eventoBox">{evento.Titulo}</h2></div>
              <div className="dirección_eventoBox">{evento.Direccion}</div>
            </div>
            <div className="btnyPrecio_evento">
              <div className="precio_evento">${evento.Precio} MXN</div>
              <button
                className="boton_info_eventoBox"
                onClick={() => {
                  // Add button functionality here
                  // (e.g., open modal, navigate to details page)
                  console.log("Button clicked for:", evento.Titulo);
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventosProximos;
