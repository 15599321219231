import React from "react";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";


const CompartirGuia = () => {
  const shareUrl = window.location.href;
  const shareText = "¡Echa un vistazo a esta página!";

  const handleShare = (platform) => {
    let url = "";
    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareText)}`;
        break;
    }
    window.open(url, "_blank");
  };

  return (
    <div>
      <Button
      className="boton_compartirGuia"
        variant="contained"
        color="primary"
        endIcon={<FacebookIcon />}
        onClick={() => handleShare("facebook")}
        style={{
            background: "none", // Replace with your preferred color
            color: "white", // Adjust text color if needed
            boxShadow:"none",
            position:"absolut",
            top:"-20%",
          }}
      >
        <p>Compartir en Facebook</p>
      </Button>
    </div>
  );
};

export default CompartirGuia;
