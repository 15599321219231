import React, { useState, useEffect } from 'react';
import AlphabetFilter from '../Componentes/directorioNegocios/filtroAlfabetico';
import CategoryList from '../Componentes/directorioNegocios/listadeCategorias';
import BusinessList from '../Componentes/directorioNegocios/listadeNegocios';
import "./directorioNegocios_style.css"

const App = () => {
  const [selectedLetter, setSelectedLetter] = useState('A');
  const [selectedCategory, setSelectedCategory] = useState('Abarrotes');
  const [categories, setCategories] = useState([]);
  const [businesses, setBusinesses] = useState([]);

  const Categories = {
    A: ['Abarrotes', 'Abogados', 'Aceros', 'Artesanías'],
    B: ['Bancos', 'Bares', 'Bibliotecas', 'Bienes Raíces', 'Boutiques'],
    C: ['Cafeterías', 'Carnicerías', 'Casas de Cambio', 'Clínicas', 'Consultorios', 'Construcción'],
    D: ['Dentistas', 'Deportes', 'Diseño Gráfico', 'Distribuidoras', 'Desechables'],
    E: ['Educación', 'Electrónica', 'Estéticas', 'Eventos'],
    F: ['Farmacias', 'Ferreterías', 'Floristerías', 'Fotografía', 'Fruterías'],
    G: ['Gasolineras', 'Gimnasios', 'Gobierno', 'Guarderías'],
    H: ['Hoteles', 'Herrerías', 'Hogar'],
    I: ['Imprentas', 'Inmobiliarias', 'Ingenierías', 'Internet'],
    J: ['Jardinería', 'Joyerías',],
    K: ['Kioskos', 'Kinesiología'],
    L: ['Lavanderías', 'Librerías'],
    M: ['Maderas', 'Mantenimiento', 'Marketing', 'Mascotas', 'Mecánicos', 'Medicina'],
    N: ['Nutrición'],
    O: ['Ópticas', 'Organización de Eventos'],
    P: ['Papelerías', 'Pastelerías', 'Películas', 'Perfumerías', 'Pescaderías', 'Pinturas', 'Publicidad'],
    Q: ['Químicos'],
    R: ['Restaurantes', 'Ropa', 'Reparaciones', 'Renta de Autos'],
    S: ['Salud', 'Seguridad', 'Seguros', 'Servicios Públicos', 'Supermercados'],
    T: ['Talleres', 'Tecnología', 'Telecomunicaciones', 'Transporte', 'Turismo'],
    U: ['Universidades'],
    V: ['Veterinarias', 'Viajes'],
    W: ['Wellness'],
    X: ['Xerografía'],
    Y: ['Yoga'],
    Z: ['Zapaterías'],
  };

  const Businesses = {
    Abarrotes: [
      { name: 'Abarrotes Santa Teresa', address: 'Rincón de la Estrella Manzana 011, El Arco', phone: '726 262 1828', logo: 'https://queretaro.tecnm.mx/wp-content/uploads/2021/11/placeholder.png' },
      { name: 'Neto El Arco', address: 'El Arco Manzana 011, Centro', phone: '', logo: 'https://tiendasneto.com.mx/media/logo/stores/1/Logo_TiendasNeto.png' },
      { name: 'Abarrotes Mi Tiendita', address: 'Av Toluca 311, Otumba', phone: '7224187391', logo: 'https://queretaro.tecnm.mx/wp-content/uploads/2021/11/placeholder.png' },
      { name: 'Distribuidora Abarrotera Fonseca', address: 'Calle Independencia 211, Centro', phone: '', logo: 'https://logopond.com/logos/d596edd05fbbf87545b562c3ac0fc631.png' },
      { name: 'Abarrotes Grupo Hermanos Flores', address: 'Avenida Benito Juarez 501, San Antonio', phone: '726 262 1613', logo:'https://scontent.fmex5-1.fna.fbcdn.net/v/t1.6435-9/168506506_130846439047536_6289723349394042835_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=1d70fc&_nc_ohc=KFmfx5Js-wEQ7kNvgFrVut8&_nc_ht=scontent.fmex5-1.fna&oh=00_AYAgWx8uhFzdwARJi4KQjM7gEVFYVwtqTBuk-D-FCnPlLw&oe=66DD9E5C' },
      { name: 'Abarrotes La Única', address: 'Villagrán Manzana 004, Centro', phone: '726 262 0267', logo: 'https://scontent.fmex28-1.fna.fbcdn.net/v/t39.30808-6/277588381_642082147142261_5905931101305328038_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=VEH_BRkyW4sQ7kNvgFVb1dO&_nc_ht=scontent.fmex28-1.fna&oh=00_AYByvnjQ0QDQpOmW0ZLINAvkbgV2o4FwhlnCarEtW7UIkQ&oe=66BC2303' },

    ],
    Abogados: [
      { name: 'Consultoria Legal Avila & Asociados', address: 'Manzana 028, Barranca Seca', phone: '722 576 3446', logo: 'https://queretaro.tecnm.mx/wp-content/uploads/2021/11/placeholder.png' },
      { name: 'Carrillo y Asociados', address: 'Calle, Luis Donaldo Colosio s/n, Otumba', phone: '722 498 7141', logo: 'https://queretaro.tecnm.mx/wp-content/uploads/2021/11/placeholder.png' },
      { name: 'Abogados Nuñez Reyes', address: 'Calle, Luis Donaldo Colosio s/n, Otumba', phone: '726 269 7985', logo: 'https://queretaro.tecnm.mx/wp-content/uploads/2021/11/placeholder.png' },
    ],
    Aceros: [
      { name: 'FECMETAL Aceros', address: 'Avenida Benito Juarez 733, San Antonio', phone: '726 269 7985', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXIKHWMMhPjeFGOtZwMnUnsX-vU3Y3ZqIZVA&s' },
      { name: 'Megacero', address: 'Fray Gregorio Jiménez de La Cuenca Manzana 013, San Antonio', phone: '726 262 7064', logo: 'https://static.wixstatic.com/media/888ae6_5ae4c50f346444ed92a86122ac5576e9~mv2.jpg/v1/fill/w_494,h_312,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Div_Alu.jpg' },
      { name: 'Aceros El Arbol', address: 'S. Antonio 238, San Antonio', phone: '726 262 4077', logo: 'https://aceroselarbol.com/wp-content/uploads/2018/03/Logo-transparencia-fondo-blanco.png' },
      { name: 'Construrama Aceros y Cementos Arriaga', address: 'Desviación a Colorines S/N, El Arco', phone: '726 688 2768', logo: 'https://ferremart.mx/loginwp/wp-content/uploads/2020/08/Logo-Construrama-VRTCL.png' },
      { name: 'Construrama "La Cascada"', address: 'Entrada a las carmelitas, Santa María Pipioltepec', phone: '722 183 7283', logo: 'https://ferremart.mx/loginwp/wp-content/uploads/2020/08/Logo-Construrama-VRTCL.png' },
    ],
    Artesanías:[
      { name: 'Mercado de Artesanías', address: 'Avenida Benito Juarez Manzana 032, San Antonio, Centro', phone: '', logo: 'https://queretaro.tecnm.mx/wp-content/uploads/2021/11/placeholder.png' },
      { name: 'Yola', address: 'Mercado de Artesanías, Centro', phone: '722 571 9632', logo: 'https://queretaro.tecnm.mx/wp-content/uploads/2021/11/placeholder.png' },
      { name: 'Centro Ceramista Honorio Jiménez', address: 'Miguel Alemán Valdez S/N, El Calvario', phone: '+722 409 8955', logo: 'https://queretaro.tecnm.mx/wp-content/uploads/2021/11/placeholder.png' },
    ],
    Construcción: [
      { name: 'Gil Mallas', address: 'Fco. Gonzalez Bocanegra 504-a Col. Centro', phone: '7224932569', logo: 'https://gilmallas.com.mx/wp-content/uploads/2018/12/Logo.png' , pagina:'/directorio-valle-de-bravo/negocio-0001'},
    ],
    Desechables: [
      { name: 'Eco Products', address: 'Plaza Valle, Fray Gregorio Jimenez de la Cuenca', phone: '7222922355', logo: 'https://scontent.fmex23-1.fna.fbcdn.net/v/t1.6435-9/119936420_101025838430909_2882272404188766759_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=dw-bMvBPd3MQ7kNvgEQ6UUK&_nc_ht=scontent.fmex23-1.fna&oh=00_AYBU-QitGaDHvZUDJ1euz63cPIGxjYYm8VSovgK4__rQzw&oe=66D68001' },
    ],
    Eventos: [
      { name: 'El Guardian', address: 'Acatitlan ', phone: '-', logo: 'https://scontent.fmex23-1.fna.fbcdn.net/v/t39.30808-1/257571985_122497326883676_6250914826586698217_n.jpg?stp=dst-jpg_p480x480&_nc_cat=101&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=3beL3PWZfrkQ7kNvgGqDzlu&_nc_ht=scontent.fmex23-1.fna&oh=00_AYAN6JKmgjn29kcnIFcZPqwWH6wtbI3QKpM8vYTGVlQN2A&oe=66B4F40F' },
    ],
    Turismo: [
      { name: 'Gofly', address: 'Joaquin Arcadio Pagaza 401 esq. Salitre', phone: '7222642818', logo: '../Images/Captura de Pantalla 2024-08-03 a la(s) 22.58.09.png' },
    ],
    Restaurantes: [
      { name: 'Chile Mole Pozole ', address: 'Plaza Valle, Fray Gregorio Jimenez de la Cuenca', phone: '7222922355', logo: 'https://scontent.fmex26-1.fna.fbcdn.net/v/t39.30808-6/453969144_1934948183641878_5750909340036372239_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=a5f93a&_nc_ohc=65qxThHQvgIQ7kNvgHgTgpn&_nc_ht=scontent.fmex26-1.fna&oh=00_AYARu9hTinv_-zeJwUU1k-yP9GGV3xb4hniku5PdwPHQ3Q&oe=66B4DAB7' },
    ],
    Hoteles: [
      { name: 'ANUIES', address: 'Blv Juan Herrera y Piña s/n, El Calvario', phone: '7221112602', logo: 'https://scontent.fmex28-1.fna.fbcdn.net/v/t39.30808-6/440982413_913122860818845_5095374825866025798_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=z_nJUpWzmOYQ7kNvgGzB7Rn&_nc_ht=scontent.fmex28-1.fna&oh=00_AYDY1i_PvclRczDpnxij4ybOSzVzdFnZWCa5GiXNJTdxgw&oe=66B4F809' },
    ],
  };

  useEffect(() => {
    setCategories(Categories['A']);
    setBusinesses(Businesses['Abarrotes']);
  }, []);

  const handleLetterSelect = (letter) => {
    setSelectedLetter(letter);
    setCategories(Categories[letter] || []);
    setSelectedCategory('');
    setBusinesses([]);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setBusinesses(Businesses[category] || []);
  };

  return (
    <div className="directorioNegocios">
      <AlphabetFilter selectedLetter={selectedLetter} onLetterSelect={handleLetterSelect} />
      <div className="content">
        <CategoryList categories={categories} onSelectCategory={handleCategorySelect} />
        <div className="business-container">
          <h2>Categoría: {selectedCategory}</h2>
          <div className='box_Negocios'>
          <BusinessList businesses={businesses}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
