import React from "react";

const Filter = ({ filters, setFilters }) => {
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: checked
        ? [...prev[name], value]
        : prev[name].filter((v) => v !== value),
    }));
  };

  return (
    <div className="filter">
      <div className="conceptoFiltro">
        <h4>Tipo de establecimiento</h4>
        <div>
          <input
            type="checkbox"
            id="restaurant"
            name="type"
            value="restaurant"
            onChange={handleChange}
          />
          <label htmlFor="restaurant">Restaurantes</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="cafe"
            name="type"
            value="cafe"
            onChange={handleChange}
          />
          <label htmlFor="cafe">Café y té</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="desserts"
            name="type"
            value="desserts"
            onChange={handleChange}
          />
          <label htmlFor="desserts">Postres</label>
        </div>
      </div>

      <div className="conceptoFiltro">
        <h4>Comidas</h4>
        <div>
          <input
            type="checkbox"
            id="breakfast"
            name="meal"
            value="breakfast"
            onChange={handleChange}
          />
          <label htmlFor="breakfast">Desayuno</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="brunch"
            name="meal"
            value="brunch"
            onChange={handleChange}
          />
          <label htmlFor="brunch">Brunch</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="lunch"
            name="meal"
            value="lunch"
            onChange={handleChange}
          />
          <label htmlFor="lunch">Almuerzo</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="dinner"
            name="meal"
            value="dinner"
            onChange={handleChange}
          />
          <label htmlFor="dinner">Cena</label>
        </div>
      </div>

      <div className="conceptoFiltro">
        <h4>Precio</h4>
        <div>
          <input
            type="checkbox"
            id="cheap"
            name="price"
            value="$"
            onChange={handleChange}
          />
          <label htmlFor="cheap">Comer barato</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="medium"
            name="price"
            value="$$"
            onChange={handleChange}
          />
          <label htmlFor="medium">Gama media</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="gourmet"
            name="price"
            value="$$$"
            onChange={handleChange}
          />
          <label htmlFor="gourmet">Comida Gourmet</label>
        </div>
      </div>
    </div>
  );
};

export default Filter;
