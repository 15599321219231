import "../Articulos/articulo_revista.css";
import CompartirArticulo from "./componentes_articulos/boton_compartirArticulo";
import ArticulosRelacionados from "./componentes_articulos/articulos_similares";

function Articulo010() {
  return (
    <div className="contenedor_Articulo">
      <div className="encabezadoArticulo">
        <a>
          <h1 className="titulo_Articulo">
            Celebración del Día del Abogado en Valle de Bravo
          </h1>
          <CompartirArticulo />
        </a>
        <hr />
      </div>
      <div className="portada_articulo">
        <div className="contenedor_imgPortada">
          <img
            className="portadaImg"
            src="../Images/Fotos/IMG_3306.jpg"
            alt="Ciclistas"
          ></img>
        </div>
        <div className="categoria_portadaArticulo">SOCIALES</div>
        <div className="fecha_portadaArticulo">Julio 2024</div>
      </div>
      <div className="contenedor_contenidoArticulo">
        <div className="seccion1_articulo">
          <a className="parrafo_secc1">
            <h2>Una Noche de Reconocimiento y Camaradería</h2>
            <p>
              <b>E</b>l día de ayer, en el Salón Los Girasoles, celebramos con
              gran entusiasmo a los abogados Vallesanos en su día. Este evento,
              organizado por el H. Ayuntamiento, fue una maravillosa oportunidad
              para reconocer y homenajear su arduo trabajo y dedicación. El
              salón, bellamente decorado para la ocasión, se llenó de una
              atmósfera festiva y elegante. Los asistentes, vestidos con sus
              mejores galas, fueron recibidos con una copa de bienvenida y una
              cálida sonrisa por parte del equipo organizador. La velada comenzó
              con un emotivo discurso del presidente municipal, quien destacó la
              importancia del papel de los abogados en la sociedad y su
              compromiso con la justicia y el estado de derecho.
            </p>
            <p>
              El exquisito banquete, preparado por chefs locales, incluyó una
              variedad de platillos que deleitaron a los invitados. Desde
              entradas gourmet hasta postres finamente elaborados, cada detalle
              del menú fue cuidadosamente seleccionado para brindar una
              experiencia culinaria memorable. Durante la cena, los asistentes
              disfrutaron de la actuación de una banda en vivo, que interpretó
              una selección de música que iba desde clásicos hasta
              contemporáneos, creando un ambiente ameno y relajado.
            </p>
          </a>
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="../Images/Fotos/IMG_3325 2.jpg"
              alt="ciclista sonriendo"
            ></img>
          </div>
        </div>
        <div className="seccion2_articulo">
          <p>
            Uno de los momentos más emocionantes de la noche fue la entrega de
            reconocimientos a los abogados más destacados del año. Estos
            premios, otorgados en diferentes categorías como "Mejor Abogado
            Penalista" y "Abogado del Año", fueron recibidos con aplausos y
            ovaciones por parte de todos los presentes. Cada galardonado tuvo la
            oportunidad de dar un breve discurso de agradecimiento, lo que
            añadió un toque personal y emotivo a la celebración.
          </p>
          <p>
            Además de los premios, se realizaron emocionantes rifas con
            atractivos premios, incluyendo viajes, cenas en restaurantes de lujo
            y artículos de tecnología. Los ganadores no pudieron ocultar su
            alegría y agradecimiento, y la emoción de las rifas añadió un toque
            de diversión a la noche. Fue una noche inolvidable, llena de
            camaradería, donde los abogados compartieron momentos especiales con
            colegas y seres queridos. ¡Felicitamos a todos los abogados
            Vallesanos y agradecemos su incansable labor por la justicia!
          </p>
          <h2>Un Orgullo Vallesano: Miguel Ángel Carrillo Rojas</h2>
          <p>
            En el marco de la conmemoración del Día del Abogado hoy 12 de julio,
            es una buena oportunidad para presentar a un destacado abogado
            orgullosamente Vallesano.
          </p>

          <div className="Add1_articulo">
            <h2>ESPACIO PUBLICITARIO</h2>
          </div>
          <p>
            Miguel Ángel Carrillo Rojas es un abogado con una trayectoria
            profesional impresionante como abogado, académico y servidor
            público, un eterno estudioso del derecho. Defensor de las causas
            justas, entregado siempre a la labor que le toca desempeñar, siempre
            inconmensurable al compartir sus vastos conocimientos con todos los
            que le rodean.
          </p>
        </div>
        <div className="secc3_articulo">
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="../Images/Fotos/IMG_3324 2.jpg"
              alt="ciclista sonriendo"
            ></img>
          </div>
          <a className="parrafo_secc3">
            <p>
              Cuenta con un gran currículum académico y laboral que resumimos a
              continuación:
            </p>
            <ul>
              <li>
                <b>Licenciado en Derecho.</b>Universidad Isidro Fabela de Toluca
                S.C.
              </li>
              <li>
                <b>Maestro en Derecho.</b>Universidad de Estudios de Posgrado en
                Derecho.
              </li>
              <li>
                <b>
                  Maestro en Penal Derecho Procesal Penal, Procedimiento Penal
                  Acusatorio Juicios Orales
                </b>
                Por el Instituto Nacional de Estudios Superiores en Derecho
                Penal (INDEPAC).
              </li>
              <li>
                <b>Doctor en Derecho.</b>Universidad de Estudios de Posgrado en
                Derecho.
              </li>
              <li>
                <b>
                  Abogado Postulante, asesor jurídico y titular del Despacho.
                </b>
                Servicios Jurídicos Profesionales.
              </li>
            </ul>
            <p>
              Más allá de los títulos y cargos públicos, su calidad como persona
              es lo más sobresaliente. En una sociedad que necesita abogados
              comprometidos con la defensa del Estado de Derecho, los Derechos
              Humanos y la división de Poderes, contar con un abogado como el
              Dr. en Derecho Miguel Ángel Carrillo Rojas es garantía de caminar
              por el sendero de la Ley y la Justicia.
            </p>
            <h2>¿Necesitas una Consulta Jurídica?</h2>
            <p>¡Acude con los expertos, Dr. en Derecho Miguel Ángel Carrillo Rojas! Agenda tu cita al: 7224987141 o acude personalmente a Calle Luis Donaldo Colosio sin número, Barrio de Otumba, Valle de Bravo.</p>
          </a>
        </div>
      </div>
      <ArticulosRelacionados />
      <div className="Add3_articulo">
        <h2>ESPACIO PUBLICITARIO 3</h2>
      </div>
    </div>
  );
}
export default Articulo010;
