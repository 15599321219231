import "../Articulos/articulo_revista.css";
import CompartirArticulo from "./componentes_articulos/boton_compartirArticulo";
import ArticulosRelacionados from "./componentes_articulos/articulos_similares";

function Articulo007() {
  return (
    <div className="contenedor_Articulo">
      <div className="encabezadoArticulo">
        <a>
          <h1 className="titulo_Articulo">
            Historia de Valle de Bravo
            <br />
            Siglos XIX y XX
          </h1>
          <CompartirArticulo />
        </a>
        <hr />
      </div>
      <div className="portada_articulo">
        <div className="contenedor_imgPortada">
          <img
            className="portadaImg"
            src="https://scontent.fmex26-1.fna.fbcdn.net/v/t39.30808-6/451362835_122107306550398540_5918672740090080813_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=127cfc&_nc_ohc=hmEEFfso7IoQ7kNvgHKLyFY&_nc_ht=scontent.fmex26-1.fna&gid=AHgGcY1aLQLtkeHjxQKH7th&oh=00_AYBsWGxfiDZQ9bXKK69jwBFzczOmznFJNEx4iHiwiwQs2g&oe=66AE0580"
            alt="Ciclistas"
          ></img>
        </div>
        <div className="categoria_portadaArticulo">CULTURA</div>
        <div className="fecha_portadaArticulo">Julio 2024</div>
      </div>
      <div className="contenedor_contenidoArticulo">
        <div className="seccion1_articulo">
          <a className="parrafo_secc1">
            <h2>Siglo XIX</h2>
            <p>
              <b>D</b>espués de estallar la Guerra de Independencia en 1810, los
              indios de San Francisco del Valle de Temascaltepec se sumaron al
              movimiento. Entre los revoltosos hubo uno muy célebre que hizo su
              aparición hacia 1834. Se trataba de Carlos Guadalupe Tepixtoco
              Abad, quien proclamó un plan para restablecer el imperio de
              Moctezuma, que el pueblo consideró ridículo y la empresa
              temeraria.
            </p>
            <p>
              El 7 de mayo de 1842, por el valor que los habitantes demostraron
              en la lucha contra la invasión francesa, durante la llamada
              “Guerra de los Pasteles”, la población recibió el título oficial
              de Villa, cambiando su anterior nombre por el de “Villa del
              Valle”.
            </p>
            <p>
              En 1847, un pequeño grupo de habitantes de la Villa del Valle
              entre los que figuraron José Rebollar, Ramón Uriarte y Francisco
              Ortiz Luque, se aliaron al ejército del general Nicolás Bravo para
              participar en la heroica defensa del Castillo de Chapultepec con
              motivo de la invasión norteamericana.
              <br />
              <br />
              Con el lema “religión” y “fueros”, el general conservador José
              María Cobos tomó la que fuera entonces Villa del Valle y saqueó el
              vecino poblado de Amanalco en 1857.
            </p>
          </a>
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://www.mexicoenfotos.com/MX15044560185501.jpg"
              alt="ciclista sonriendo"
            ></img>
          </div>
        </div>
        <div className="seccion2_articulo">
          <p>
            Se sabe que la Villa del Valle era famosa en la comarca por los
            arreos para bestias de carga que aquí se elaboraban; también eran
            bien conocidos sus finos rebozos cuya industria trascendió en la
            época de Maximiliano que según Castillo y Piña “fue tan grande la
            cantidad de dinero que entró a la Villa con motivo de la venta de
            los rebozos, que se podían haber empedrado las calles… con plata”.
            <br />
            Años más tarde, durante las guerras de reforma, el general lerdista
            Felipe Berriozábal atacó Valle de Bravo que se encontraba en poder
            de los conservadores. Los liberales se apostaron en el cerro cercano
            del Calvario desde donde le disparaban al poblado.
          </p>
          <p>
            En 1889, en el pleno porfirismo, el Valle aparece como un distrito
            formado por cinco municipalidades y tres municipios que comprenden
            una ciudad, tres villas, 33 pueblos, cuatro barrios, 23 haciendas,
            un rancho y 24 rancherías. Antes de que finalizara el siglo ya se
            contaba en Valle de Bravo con una planta para generar energía
            eléctrica, con una oficina de telégrafos y un teatro.
          </p>
          <p>
            Por el año de 1879, existía en Valle de Bravo nueve minas, las
            cuales al parecer no eran explotadas debido a la situación
            económica; sólo una de ellas destacó, la de El Salto, en la que se
            obtenía y fundía hierro para la elaboración de hornillas, parrillas
            y otros objetos. Fue destruida años más tarde por los zapatistas.
          </p>
          <div className="Add1_articulo">
            <h2>ESPACIO PUBLICITARIO</h2>
          </div>
          <h2>Siglo XX</h2>
          <p>
            A raíz de su independencia, México vivió por más de un siglo una
            gran confusión política y social. Liberales y conservadores se
            arrebataban el poder sumiendo al país en el caos y el atraso. Valle
            de Bravo no se sustrajo a estos conflictos e inestabilidades.
          </p>
        </div>
        <div className="secc3_articulo">
          <div className="contenedor_imgSecArticulo">
            <img
              className="imgSecArticulo"
              src="https://mediateca.inah.gob.mx/repositorio/islandora/object/fotografia%3A111837/datastream/TN/view"
              alt="ciclista sonriendo"
            ></img>
          </div>
          <a className="parrafo_secc3">
            <p>
              Para 1910 Valle de Bravo era la municipalidad más importante del
              distrito en esta época y debido a la estratégica situación que
              tenía se convirtió en presa de las huestes revolucionarias.
            </p>
            <p>
              En 1937 un pequeño grupo de ingenieros de la naciente Comisión
              Federal de Electricidad había llegado a iniciar los estudios para
              la posible construcción de la planta eléctrica de Ixtapantongo y
              que más tarde se llamaría Sistema Hidroeléctrico “Miguel Alemán”.
              Los trabajos se iniciaron el cinco de mayo de 1938 y fueron
              concluidos el 17 de julio de 1947; se inundaron 2,900 hectáreas de
              las mejores tierras que tenía Valle de Bravo, a las que se
              denominaba El Plan; el sistema se iniciaba en la Presa Villa
              Victoria y terminaba en el estado de Michoacán; incluía 18
              unidades de operación, que en conjunto generaban 458,775 kilowatts
              hora; actualmente esta presa forma parte del Sistema Cutzamala,
              que abastece de agua potable a la zona metropolitana de la Ciudad
              de México.
            </p>
            <p>
              En 1968 Valle de Bravo fue subsede para las competencias de
              equitación de los Juegos Olímpicos.
            </p>
            <p>
              En 1971 se le otorga el título de Ciudad Típica de Valle de Bravo,
              bajo la Ley de Protección y Conservación de la Ciudad”.
            </p>
            <p>
              En 1972 la ciudad fue la cede de las competencias de vela y
              canotaje de los juegos Centroamericanos y en 2005 fue designado
              Pueblo Mágico.
            </p>
          </a>
        </div>
      </div>
      <ArticulosRelacionados />
      <div className="Add3_articulo">
        <h2>ESPACIO PUBLICITARIO 3</h2>
      </div>
    </div>
  );
}
export default Articulo007;
