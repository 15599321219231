import "./laPena_style.css";
import CompartirGuia from "../inicio_guia/compartir_guia";
import BotonesMenu_guia from "../inicio_guia/botones_guia";

function VelodeNovia() {
  return (
    <div className="inicio_guia guia_laPena">
      <div className="contenedor_logoGuia">
        <img
          className="logoGuia logoGuiaSeccion"
          src="../Images/logo_guiaTuristica.png"
          alt="Guia turistica logo"
        />
      </div>
      <div className="informacionPena">
        <h2 className="tituloGuiaSeccion">Cascada Velo de Novia</h2>
        <h2 className="subtituloBienvenidosGuia">
          Un Espectáculo Natural en Valle de Bravo
        </h2>
        <div className="informacionPena_secc1">
          <div className="informacionPena_secc1texto">
            <p>
              Ubicada en el encantador Valle de Bravo, Estado de México, la
              Cascada Velo de Novia es una de las joyas naturales más
              impresionantes de la región. Con su espectacular caída de agua de
              35 metros, esta cascada ofrece un paisaje de ensueño que combina
              la majestuosidad de la naturaleza con la serenidad de sus
              alrededores. Es el lugar perfecto para los amantes de la
              naturaleza y aquellos que buscan una experiencia tranquila y
              revitalizante.
            </p>
            <h2>Historia y Significado</h2>
            <p>
              La Cascada Velo de Novia debe su nombre a la forma que adquiere el
              agua al caer, semejante al delicado velo de una novia. Este sitio
              ha sido, durante años, un destino favorito tanto para turistas
              como para locales, quienes se sienten atraídos por su belleza
              escénica y su ambiente relajante. Rodeada de bosques y senderos,
              la cascada es un testimonio del esplendor natural de Valle de
              Bravo.
            </p>
          </div>

          <iframe
            width="680"
            height="326"
            src="https://www.youtube.com/embed/_uS651B7gIY"
            title='🔴 😍LA CASCADA MAS BONITA DE MEXICO! "Cascada Velo de Novia" en Valle de Bravo'
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="informacionPena_secc2">
          <img
            src="../Images/lapeña.png"
            className="galeria1_detallesTuristicos"
            alt="galeria detalles turisticos"
          />
          <div>
            <h2>Atracciones y Actividades</h2>
            <ul>
              <li><p><b>Miradores Naturales</b>La cascada cuenta con varios miradores que ofrecen vistas panorámicas impresionantes. Estos puntos de observación son perfectos para capturar fotografías inolvidables y disfrutar del sonido relajante del agua.</p></li>
              <li><p><b>Senderismo</b>Los alrededores de la Cascada Velo de Novia están repletos de senderos bien señalizados que permiten a los visitantes explorar la flora y fauna locales. Es una excelente actividad para quienes disfrutan del contacto directo con la naturaleza.</p></li>
              <li><p><b>Picnic y Relajación</b>Las áreas cercanas a la cascada son ideales para disfrutar de un picnic en familia o con amigos. Trae tu manta y tus bocadillos, y relájate mientras disfrutas del paisaje y el aire fresco.</p></li>
            </ul>
            <h2>¿Cómo Llegar?</h2>
            <p>
            La Cascada Velo de Novia se encuentra a unos 20 minutos en automóvil desde el centro de Valle de Bravo. Puedes llegar en taxi, transporte público local o vehículo particular. Si vienes desde la Ciudad de México, el viaje en automóvil dura aproximadamente 2 horas.
            </p>
          </div>
        </div>
        <div className="informacionPena_secc3">
          <h2>Consejos para tu Visita</h2>
          <p>La Cascada Velo de Novia es un destino imperdible en Valle de Bravo que promete una experiencia natural inolvidable. Planifica tu visita con antelación, lleva todo lo necesario para tu comodidad y seguridad, y prepárate para disfrutar de uno de los paisajes más hermosos del Estado de México. ¡Te esperamos para que descubras la magia de la Cascada Velo de Novia!</p>
        </div>
      </div>
      <div className="contenedor_galeriaInfinita">
        <a>
          <h2>Galería</h2>
        </a>
        <div className="galeriaInfinita">
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-06/RAPEL.JPG"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/CASCADA%20VELO%20DE%20NOVIA.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-08/VELO%20DE%20NOVIA_3.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://hotelixtlan.com/wp-content/uploads/2022/07/VN1.png"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://a.travel-assets.com/findyours-php/viewfinder/images/res70/201000/201649-Velo-De-Novia-Waterfalls.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://a.travel-assets.com/findyours-php/viewfinder/images/res70/201000/201652-Velo-De-Novia-Waterfalls.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.civitatis.com/f/mexico/valle-de-bravo/galeria/paseo-caballo-cascada.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://live.staticflickr.com/7032/6790962077_e996bf38e9_b.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.soymexiquense.com/component/joomgallery/?task=download&id=172"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9l47B6cjtJmEDSgVDx66bdLj3QXFz0cNGXwLhBroMRdA16uyj9hkySBjPfvlNvoDrX-4&usqp=CAU"
            alt=""
          />
        </div>
      </div>
      <div className="indicaciones_turistas">
        <div className="flexbox_Necesarios">
          <div>
            <h3>PRENDAS Y EQUIPO NECESARIO</h3>
            <ul>
              <li>Ropa cómoda y ligera</li>
              <li>Calzado Adecuado</li>
              <li>Gorra o Sombrero</li>
              <li>Botella de Agua</li>
              <li>Protector solar y repelente de insectos</li>
            </ul>
          </div>
          <div>
            <h3>PRECAUCIONES</h3>
            <ul>
              <li>Respeto al medio ambiente</li>
              <li>Precaución en los senderos</li>
              <li>Ten Precaución en las Rocas</li>
              <li>Verifica el clima</li>
              <li>No aventurarse solo</li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <BotonesMenu_guia />
      <div className="mapa_turista">
        <h2 className="comollegar">¿Cómo llegar?</h2>
        <p>Ve la dirección en google maps</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.7176135494315!2d-100.14451652479246!3d19.16383438205887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd64410accf57b%3A0x2caaba11846de1cb!2sCascada%20Velo%20de%20Novia!5e0!3m2!1ses!2smx!4v1722904681828!5m2!1ses!2smx"
          width="850"
          height="250"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa de Valle de Bravo"
          className="mapaEvento"
        ></iframe>
      </div>
      <div className="espacioPublicitario1_guia">
        <h2>Espacio publicitario</h2>
      </div>
      <img
        className="logo_guiaTur_inferior"
        src="../Images/logo_guiaTuristica.png"
        alt="Logo inferior"
      />
      <img
        className="imagenInferior"
        src="../Images/FOTO_INFERIORCASCADA.png"
        alt="Imagen inferior"
      />
    </div>
  );
}

export default VelodeNovia;
