import React from "react";
import Navbar from "./Componentes/Encabezado/encabezado";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Pagina_principal from "./Pages/Inicio";
import "./App.css";
import PoliticasdePrivacidad from "./Pages/politicadePrivacidad.js";
import RevistaDigital from "./Pages/revista_digital";
import Catalogo_realEstate from "./Pages/hospedajeTurismo.js";
import PaginaEventos from "./Pages/eventos";
import PaginaPropiedad from "./Componentes/RealEstate/Pagina_propiedad/paginaPropiedad";
import GuiaInicio from "./Pages/inicio_guiaTuristica";
import DescripcionEvento from "./Componentes/Eventos/infoEvento/infoevento";
import PiedePagina from "./Componentes/PieDePagina/pieDePagina";
import OpcionesdeHospedaje from "./Pages/hospedaje";
import PrincipalRestaurantes from "./Pages/restaurantes";
import SitioEnConstuccion from "./Pages/enConstruccion";
import ScrollToTop from './Componentes/ScrollToTop.jsx';
import Catalogo_Hospedaje from "./Pages/hospedajeTurismo.js";

import DirectorioNegocios from "./Pages/directorioNegocios"
import PaginaNegocio from "./Componentes/directorioNegocios/paginasNegocios/paginaNegocio.js";

import ChileMolePozole from "./Pages/paginas_restaurantes/chileMolePozole.js";
import ElSazondeMary from "./Pages/paginas_restaurantes/elSazondeMary.js"
import TortasTragoncito from "./Pages/paginas_restaurantes/elTragoncito.js"

import LaPena from "./Componentes/GuiaTuristica/sitiosTuristicos/laPena";
import VeloDeNovia from "./Componentes/GuiaTuristica/sitiosTuristicos/velodeNovia.js"
import ParroquiaSanFrancisco from "./Componentes/GuiaTuristica/sitiosTuristicos/parroquiaSanFrancisco.js"
import CarmelMaranatha from "./Componentes/GuiaTuristica/sitiosTuristicos/calmelMaranatha.js"
import SantaMaria from "./Componentes/GuiaTuristica/sitiosTuristicos/SantaMaria.js"
import GranStupaBon from "./Componentes/GuiaTuristica/sitiosTuristicos/granStupaBon.js"
import MonteAlto from "./Componentes/GuiaTuristica/sitiosTuristicos/MonteAlto.js"
import Avandaro from "./Componentes/GuiaTuristica/sitiosTuristicos/Avandaro.js"
import CascadaElMolino from "./Componentes/GuiaTuristica/sitiosTuristicos/CascadaElMolino.js"


import Articulo001 from "./Componentes/RevistaDigital/Articulos/articulo_revista001";
import Articulo002 from "./Componentes/RevistaDigital/Articulos/articulo_revista002.jsx";
import Articulo003 from "./Componentes/RevistaDigital/Articulos/articulo_revista003";
import Articulo004 from "./Componentes/RevistaDigital/Articulos/articulo_revista004";
import Articulo005 from "./Componentes/RevistaDigital/Articulos/articulo_revista005";
import Articulo006 from "./Componentes/RevistaDigital/Articulos/articulo_revista006";
import Articulo007 from "./Componentes/RevistaDigital/Articulos/articulo_revista007";
import Articulo008 from "./Componentes/RevistaDigital/Articulos/articulo_revista008";
import Articulo009 from "./Componentes/RevistaDigital/Articulos/articulo_revista009";
import Articulo010 from "./Componentes/RevistaDigital/Articulos/articulo_revista010";
import Articulo011 from "./Componentes/RevistaDigital/Articulos/articulo_revista011";
import Articulo012 from "./Componentes/RevistaDigital/Articulos/articulo_revista012";



function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Pagina_principal />} />
        
        <Route path="/revista-digital-julio2024">
          <Route index element={<RevistaDigital />} />
          <Route path="articulo001-Historia-del-parapente_en-Valle-de-Bravo" element={<Articulo001 />} />
          <Route path="articulo002-que-comprar-en-Valle-deBravo" element={<Articulo002 />} />
          <Route path="articulo003-joya-escondida-en-el-estado-de-mexico" element={<Articulo003 />} />
          <Route path="articulo004-cómo-invertir-en-bienes-raices-en-Valle-deBravo" element={<Articulo004 />} />
          <Route path="articulo005-anuies-nuevo-hotel-valle-de-bravo" element={<Articulo005 />} />
          <Route path="articulo006-los-mejores-lugares-para-descansar-cerca-de-cdmx" element={<Articulo006 />} />
          <Route path="articulo007-historia-de-valle_de_bravo-sigloXX-sigloXXI" element={<Articulo007 />} />
          <Route path="articulo008-hongos-temporada_2024-valle-de-bravo" element={<Articulo008 />} />
          <Route path="articulo009-mexiquenses-en-olimpiadas-paris-2024" element={<Articulo009 />} />
          <Route path="articulo010-celebracion-dia-de-abogado" element={<Articulo010 />} />
          <Route path="articulo011-priscila-awiti-ganadora-de-plata" element={<Articulo011 />} />
          <Route path="articulo012" element={<Articulo012 />} />
        </Route>

        <Route path="/catalogo-de-propiedades_renta">
          <Route index element={<SitioEnConstuccion />} />
          <Route path="propiedad-000" element={<PaginaPropiedad />} />
        </Route>

        <Route path="/hospedaje-en-valle-de-bravo">
          <Route index element={<Catalogo_Hospedaje />} />
          <Route path="propiedad-000" element={<PaginaPropiedad />} />
        </Route>

        <Route path="/eventos-proximos-cerca-de-ti">
          <Route index element={<PaginaEventos />} />
          <Route path="evento-000" element={<DescripcionEvento />} />
        </Route>

        <Route path="/guia_turistica_ValleDeBravo">
          <Route index element={<GuiaInicio />} />
          <Route path="hospedaje-en-valle-de-bravo" element={<OpcionesdeHospedaje />} />
          <Route path="la-pena" element={<LaPena />} />
          <Route path="cascada-velo-de-novia" element={<VeloDeNovia />} />
          <Route path="parroquia-sn-francisco" element={<ParroquiaSanFrancisco />} />
          <Route path="carmel-maranatha" element={<CarmelMaranatha />} />
          <Route path="sta-maria-ahuacatlan" element={<SantaMaria />} />
          <Route path="gran-stupa-bon" element={<GranStupaBon />} />
          <Route path="reserva-estatal-monte-alto" element={<MonteAlto />} />
          <Route path="avandaro" element={<Avandaro />} />
          <Route path="cascada-el-molino" element={<CascadaElMolino />} />

        </Route>

        <Route path="/directorio-valle-de-bravo">
         <Route index element={<DirectorioNegocios />} />
         <Route path="negocio-0001" element={<PaginaNegocio/>}/>
        </Route>

        <Route path="/restaurantes-recomendados">
          <Route index element={<PrincipalRestaurantes />} />
          <Route path="comida-mexicana-chile-mole-pozole" element={<ChileMolePozole/>}/>
          <Route path="marisqueria_el-Sazon-de-Mary" element={<ElSazondeMary/>}/>
          <Route path="tortas_el-tragoncito" element={<TortasTragoncito/>}/>
        </Route>

        <Route path="/politicas_de-privacidad">
         <Route index element={<PoliticasdePrivacidad />} />
        </Route>
      </Routes>
      <PiedePagina />
    </Router>
  );
}

export default App;
