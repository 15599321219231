import "./nuevosEventos_style.css";

function Proximos_eventos() {
  return (
    <div className="proxeve_contenedor">
      <div className="evento_proximo">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF6mbHJ6pRq7DDwDK0wh3IITdIX0HHK4AYiew713nWRSl8nTYMe3pwqhWJAJNrYjGhAS4&usqp=CAU"
          className="foto_eventoproximo"
        ></img>
        <h3 className="titulo_eventoproximo">KTM TRACKSIDE SUPPORT</h3>
        <p className="info_eventoproximo">
          LUGAR: Valle de Bravo
          <br />
          FECHA: 3 y 4 de Agosto
        </p>
      </div>
      <div className="evento_proximo">
        <img
          src="https://i.ebayimg.com/images/g/yrYAAOSwivBiSrTi/s-l1200.webp"
          className="foto_eventoproximo"
        ></img>
        <h3 className="titulo_eventoproximo">CANTANDO A LUIS MIGUEL</h3>
        <p className="info_eventoproximo">
          LUGAR: Casa Musas, Toluca
          <br />
          FECHA: Mié, 31 Julio 20:30 
        </p>
      </div>
      <div className="evento_proximo">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Vivaldi.jpg/1200px-Vivaldi.jpg"
          className="foto_eventoproximo"
        ></img>
        <h3 className="titulo_eventoproximo">NOCHE VIVALDI </h3>
        <p className="info_eventoproximo">
          LUGAR: Sala Felipe Villanueva, Toluca
          <br />
          FECHA: Sáb, 10 Agosto 21:30
        </p>
      </div>
      <div className="evento_proximo">
        <img
          src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F809881119%2F334083336063%2F1%2Foriginal.20240718-000540?h=2000&w=720&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C500%2C714&s=f8b310b5e51c58dec38cab07c9d41f76"
          className="foto_eventoproximo"
        ></img>
        <h3 className="titulo_eventoproximo">THE BILL CUNLIFFE EXPERIENCE</h3>
        <p className="info_eventoproximo">
          LUGAR: Sala Felipe Villanueva,Toluca
          <br />
          FECHA: 27 Octubre
        </p>
      </div>
      <div className="evento_proximo">
        <img
          src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F809616269%2F898702435743%2F1%2Foriginal.20240717-174705?w=940&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C3500%2C1750&s=c280f4ff6b0ee816af6060df6d5cf3cf"
          className="foto_eventoproximo"
        ></img>
        <h3 className="titulo_eventoproximo">SUSSIE 4 | Secret Garden</h3>
        <p className="info_eventoproximo">
          LUGAR: Av Nuevo León 107, Ciudad de México
          <br />
          FECHA: 17 Agosto
        </p>
      </div>
    </div>
  );
}

export default Proximos_eventos;
