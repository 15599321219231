import "./laPena_style.css";
import CompartirGuia from "../inicio_guia/compartir_guia";
import BotonesMenu_guia from "../inicio_guia/botones_guia";

function GranStupaBon() {
  return (
    <div className="inicio_guia guia_laPena">
      <div className="contenedor_logoGuia">
        <img
          className="logoGuia logoGuiaSeccion"
          src="../Images/logo_guiaTuristica.png"
          alt="Guia turistica logo"
        />
      </div>
      <div className="informacionPena">
        <h2 className="tituloGuiaSeccion">La Gran Stupa Bön</h2>
        <h2 className="subtituloBienvenidosGuia">
          Un Refugio de Paz y Sabiduría en Valle de Bravo
        </h2>
        <div className="informacionPena_secc1">
          <div className="informacionPena_secc1texto">
            <p>
              Ubicada en las tranquilas montañas de Valle de Bravo, Estado de
              México, la Gran Stupa Bon es un majestuoso monumento que simboliza
              la paz, la armonía y la sabiduría. Este sitio sagrado,
              perteneciente a la tradición Bon del Tíbet, ofrece a sus
              visitantes un espacio único para la meditación y la contemplación
              espiritual. La stupa es un lugar de encuentro para aquellos que
              buscan un refugio de serenidad y una conexión más profunda con su
              ser interior.
            </p>
            <h2>Historia y Significado</h2>
            <p>
              La Gran Stupa Bon fue construida con el propósito de promover la
              paz y la compasión en el mundo. Este imponente monumento
              representa la mente iluminada del Buda y es un centro de energía
              espiritual que atrae a practicantes y visitantes de todas partes
              del mundo. La tradición Bon, una de las más antiguas del Tíbet, se
              refleja en la arquitectura y las enseñanzas que se imparten en
              este lugar, haciendo de la stupa un símbolo viviente de la
              sabiduría ancestral.
            </p>
          </div>

          <iframe
            width="680"
            height="326"
            src="https://www.youtube.com/embed/wdfMtRuvl2Q"
            title="STUPA BÖN VALLE DE BRAVO"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div className="informacionPena_secc2">
          <img
            src="../Images/lapeña.png"
            className="galeria1_detallesTuristicos"
            alt="galeria detalles turisticos"
          />
          <div>
            <h2>Atracciones y Actividades</h2>
            <ul>
              <li>
                <p>
                  <b>Stupa Principal</b>La Gran Stupa Bon es una estructura
                  imponente que invita a la reflexión y la meditación. Los
                  visitantes pueden caminar alrededor de la stupa, siguiendo el
                  sentido de las agujas del reloj, mientras recitan mantras o
                  simplemente disfrutan del ambiente de tranquilidad.
                </p>
              </li>
              <li>
                <p>
                  <b>Jardines y Senderos</b>Los jardines que rodean la stupa
                  están meticulosamente cuidados y ofrecen un espacio ideal para
                  la meditación al aire libre. Los senderos permiten a los
                  visitantes explorar el entorno natural y disfrutar de la
                  belleza del paisaje.
                </p>
              </li>
              <li>
                <p>
                  <b>Programas de Meditación y Enseñanzas</b>La Gran Stupa Bon
                  ofrece programas regulares de meditación, retiros espirituales
                  y enseñanzas sobre la filosofía Bon. Estos programas están
                  abiertos a todos y proporcionan una oportunidad para
                  profundizar en la práctica espiritual y aprender de maestros
                  experimentados.
                </p>
              </li>
            </ul>
            <h2>Cómo Llegar</h2>
            <p>
              La Gran Stupa Bon se encuentra a aproximadamente 25 minutos en
              automóvil desde el centro de Valle de Bravo. Puedes llegar
              fácilmente en taxi o transporte privado. Si vienes desde la Ciudad
              de México, el viaje en automóvil dura alrededor de 2 horas.
            </p>
          </div>
        </div>
        <div className="informacionPena_secc3">
          <h2>Consejos para tu Visita</h2>
          <p>
            La Gran Stupa Bon es un lugar de profunda espiritualidad y paz que
            ofrece una oportunidad única para desconectar del mundo exterior y
            reconectar con tu esencia interior. Planifica tu visita con
            antelación, sigue las recomendaciones del lugar y aprovecha al
            máximo este refugio de serenidad y sabiduría. ¡Te esperamos para que
            descubras la magia y la tranquilidad de la Gran Stupa Bon!
          </p>
        </div>
      </div>
      <div className="contenedor_galeriaInfinita">
        <a>
          <h2>Galería</h2>
        </a>
        <div className="galeriaInfinita">
          <img
            className="img_galeriaInfinita"
            src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/12/86/ff/c3/vista-desde-afuera.jpg?w=1200&h=1200&s=1"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-06/la-gran-stupa-bon%20%281%29.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRe8Qsy4tY1U7gjk_V3-d8E-qUVIsddyaydDcYVwhXhUVZ5jmvBHWC75V__8w8ChAN6-u8&usqp=CAU"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://ligmincha.org/wp-content/uploads/2019/01/valle6.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://images.inmexico.com/2017/03/35.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://www.mexicodesconocido.com.mx/sites/default/files/fichas-destino/stupa-paz-valle-bravo.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://experiencia.edomex.gob.mx/sites/default/files/2020-06/la-gran-stupa-bon-para.jpg"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://scontent.fmex26-1.fna.fbcdn.net/v/t39.30808-6/428622251_846170444188846_5064067865927133664_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=833d8c&_nc_ohc=lecUGqLvD0YQ7kNvgHh7JnE&_nc_ht=scontent.fmex26-1.fna&oh=00_AYCiLvZ28NGvId8nJ0o6XGTV-oF0AIeT4Hpbr-y1h9HIrg&oe=66B750E1"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://scontent.fmex28-1.fna.fbcdn.net/v/t39.30808-6/385068336_759765206162704_6117509389115327145_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=127cfc&_nc_ohc=4lWM82cRfzMQ7kNvgEMQJ-_&_nc_ht=scontent.fmex28-1.fna&oh=00_AYBXqdawMBPDDtUGlTWfsEJ4h8-2wOydolnVzFCIKPeFUg&oe=66B74839"
            alt=""
          />
          <img
            className="img_galeriaInfinita"
            src="https://scontent.fmex27-1.fna.fbcdn.net/v/t39.30808-6/332466290_585637836755334_2724448815723239212_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=833d8c&_nc_ohc=GvxlWTYhj1EQ7kNvgEp-jd1&_nc_ht=scontent.fmex27-1.fna&oh=00_AYDWswnAKEx9mV-oHHNkgR74tZg02CQl40GVzO54SBF7hg&oe=66B74307"
            alt=""
          />
        </div>
      </div>
      <div className="indicaciones_turistas">
        <div className="flexbox_Necesarios">
          <div>
            <h3>PRENDAS Y EQUIPO NECESARIO</h3>
            <ul>
              <li>Ropa cómoda y modesta</li>
              <li>Calzado Adecuado</li>
              <li>Gorra o Sombrero</li>
              <li>Protector Solar</li>
              <li>Botella de Agua</li>
              <li>Cuaderno y bolígrafo</li>
            </ul>
          </div>
          <div>
            <h3>PRECAUCIONES</h3>
            <ul>
              <li>Respeto y silencio</li>
              <li>Seguir las normas del lugar</li>
              <li>Condiciones de salud</li>
              <li>Clima</li>
              <li>Reserva previa</li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <BotonesMenu_guia />
      <div className="mapa_turista">
        <h2 className="comollegar">¿Cómo llegar?</h2>
        <p>Ve la dirección en google maps</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.0538491310617!2d-100.06392872061512!3d19.192850235716755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd7b5e512e554f%3A0xbc5ad7830ccea2!2sGran%20Stupa%20B%C3%B6n%20Valle%20de%20Bravo!5e0!3m2!1ses!2smx!4v1722905784689!5m2!1ses!2smx"
          width="850"
          height="250"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa de Valle de Bravo"
          className="mapaEvento"
        ></iframe>
      </div>
      <div className="espacioPublicitario1_guia">
        <h2>Espacio publicitario</h2>
      </div>
      <img
        className="logo_guiaTur_inferior"
        src="../Images/logo_guiaTuristica.png"
        alt="Logo inferior"
      />
      <img
        className="imagenInferior"
        src="../Images/FOTO_INFERIORCASCADA.png"
        alt="Imagen inferior"
      />
    </div>
  );
}

export default GranStupaBon;
