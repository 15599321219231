import './enConstruccion_style.css'

function SitioEnConstuccion(){
    return(
    <body className='cont_sitioConstruccion '>
       <img className='constructor' src='https://img.freepik.com/foto-gratis/hombre-casco-rojo-mirando-planos_1187-797.jpg?w=996&t=st=1722475125~exp=1722475725~hmac=d8dc59ccf4298d91a1314a3c12454e2261cba661792c26690c368422a8d94051' alt=''></img>
       <div className='texto_enConstruccion'>
       <h1>EN CONSTRUCCIÓN</h1>
       <p>Estamos desarrolando la mejor plataforma de bienes raices para ti, esperala pronto.</p>
       </div>       
    </body>
    );
}

export default SitioEnConstuccion;